import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';

@Injectable({
  providedIn: 'root',
})
export class WishlistService {
  constructor(private http: HttpClient) { }
  public getWishlistItems(body): Observable<any> {
    const url: string = ApiRoutes.getWishlistItems;
    return this.http.post(url, body);
  }

  public deleteProduct(payload: any): Observable<any> {
    const url: string = ApiRoutes.deleteProduct;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public showroomItems(payload: any): Observable<any> {
    const url: string = ApiRoutes.showroomItems;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public additemsinromms(payload: any): Observable<any> {
    const url: string = ApiRoutes.additemsinromms;
    return this.http.post(url, payload, { observe: 'response' });
  }
  public completeProject(payload: any): Observable<any> {
    const url: string = ApiRoutes.completeProject;
    return this.http.post(url, payload, { observe: 'response' });
  }
  public exportPdf(payload: any): Observable<any> {
    const url: string = ApiRoutes.exportCartPdf;
    return this.http.post(url, payload, { observe: 'response' });
  }
}

import { environment } from '../../../environments/environment';

export class ApiRoutes {
  // api base url
  static apiV1: string = 'api/v1/';
  public static apiBaseUrl: string = environment.apiBaseUrl + ApiRoutes.apiV1;

  public static get login(): string {
    return this.apiBaseUrl + 'client_login';
  }
  public static get register(): string {
    return this.apiBaseUrl + 'user/register';
  }
  public static get forgotPassword(): string {
    return this.apiBaseUrl + 'client_forgot_password';
  }
  public static get checkResetToken(): string {
    return this.apiBaseUrl + 'client_token_verify';
  }
  public static get changePassword(): string {
    return this.apiBaseUrl + 'client_reset_password';
  }
  public static get resetPassword(): string {
    return this.apiBaseUrl + 'client_change_password';
  }

  // Profile Section
  public static get getProfile(): string {
    return this.apiBaseUrl + 'client_view';
  }
  public static get updateProfile(): string {
    return this.apiBaseUrl + 'client_updateProfile';
  }

  public static get updateProfileNew(): string {
    return this.apiBaseUrl + 'client_update';
  }
  // Faq
  public static get getFaq(): string {
    return this.apiBaseUrl + 'faqs';
  }

  // Timeline
  public static get sendMessage(): string {
    return this.apiBaseUrl + 'add_message';
  }
  public static get getMessageHistory(): string {
    return this.apiBaseUrl + 'message_thread';
  }
  public static get uploadDoc(): string {
    return this.apiBaseUrl + 'uploadImage';
  }
  public static get getUploadedDocList(): string {
    return this.apiBaseUrl + 'message_thread';
  }
  public static get isMessageRead(): string {
    return this.apiBaseUrl + 'client_message_review';
  }
  
  // Dashboard
  public static get getClientProjectList(): string {
    return this.apiBaseUrl + 'dashboard';
  }
  public static get getTemplateAndUnitsList(): string {
    return this.apiBaseUrl + 'dashboard_template';
  }
  public static get getProductList(): string {
    return this.apiBaseUrl + 'template_product_list';
  }
  public static get getRooms(): string {
    return this.apiBaseUrl + 'template_detail';
  }
  public static get getProductDetails(): string {
    return this.apiBaseUrl + 'edit_product';
  }
  public static get deleteClient(): string {
    return this.apiBaseUrl + 'client_delete';
  }
  public static get addProductToWishlist(): string {
    return this.apiBaseUrl + 'add_wishlist';
  }
  public static get templateDetails(): string {
    return this.apiBaseUrl + 'template_detail';
  }
  public static get getAllClientProjects(): string {
    return this.apiBaseUrl + 'client_projects';
  }
  public static get getUnReadMessages(): string {
    return this.apiBaseUrl + 'dashboard_notification';
  }
  // Wishlist
  public static get getWishlistItems(): string {
    return this.apiBaseUrl + 'show_wishlist';
  }
  public static get deleteProduct(): string {
    return this.apiBaseUrl + 'delete_wishlist_product';
  }
  public static get getwishlistitembytempId(): string {
    return this.apiBaseUrl + 'show_wishlistFromTemplateId';
  }

  public static get showroomItems(): string {
    return this.apiBaseUrl + 'roomItems';
  }

  public static get additemsinromms(): string {
    return this.apiBaseUrl + 'addIteminRooms';
  }
  public static get completeProject(): string {
    return this.apiBaseUrl + 'complete_project';
  }
  public static get exportCartPdf(): string {
    return this.apiBaseUrl + 'download_wishlistFromTemplateId';
  }

  //documents
  public static get uplaodDocPdf(): string {
    return this.apiBaseUrl + 'uploadDocPdf';
  }

  public static get templateWiseDocs(): string {
    return this.apiBaseUrl + 'get_TemplateWiseDocs';
  }

  public static get addProjectDoc(): string {
    return this.apiBaseUrl + 'add_ProjectDoc';
  }

  public static get DeleteDoc(): string {
    return this.apiBaseUrl + 'delete_TemplateDocsbyid';
  }

  public static get getTempDocByID(): string {
    return this.apiBaseUrl + 'get_TemplateDocsbyid';
  }

  public static get updateDocByID(): string {
    return this.apiBaseUrl + 'update_TemplateDocsbyid';
  }

  public static get listDocCategory(): string {
    return this.apiBaseUrl + 'list_docCategory';
  }

  public static get getPrivacy(): string {
    return this.apiBaseUrl + 'get_page_content';
  }

  // admin-login-loader-page
  public static get validateKey(): string {
    return this.apiBaseUrl + 'admin_client_token';
  }
 
}

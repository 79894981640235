<div class="pt-3">
    <div class="px-4">
        <div class="modal-body">
            <h3 class="text-center">
              {{ "shoppingcart.configurationclosedtitle" | translate
              }}<span class="titlebar mx-auto d-block"></span>
            </h3>
            <p class="body-text text-center m-0">
             <span>{{ "shoppingcart.Vielen" | translate }}</span><br>
             <span>{{ "shoppingcart.configurationcloseddesc1" | translate }}</span> 
            </p>
            <p class="body-text text-center m-0">
              {{ "shoppingcart.configurationcloseddesc2" | translate }}
            </p>
            <div class="button-row d-flex align-items-center justify-content-between mt-3">
              <button type="button" class="btn btn-main w-100"
                (click)="completeSuccessHome()">
                {{ "shoppingcart.configurationclosedok" | translate }}
              </button>
            </div>
          </div>
    </div>
</div>

<ng-container *ngIf="preauthHeader">
  <!-- header Html -->
  <header>
    <nav class="navbar navbar-expand-lg primary-bg-color navbar-dark fixed-top">
      <div class="container">
        <a class="navbar-brand h-50" [routerLink]="['../' + APP_ROUTES.login]"
          ><img class="navbar-brand-height" src="/assets/img/logo.svg"
        /></a>
      </div>
    </nav>
  </header>
  <!-- header Html -->
</ng-container>

<ng-container *ngIf="showHeader">
  <!-- header Html -->
  <header>
    <nav class="navbar navbar-expand-lg primary-bg-color navbar-dark fixed-top">
      <div class="container">
        <a
          class="navbar-brand h-50"
          [routerLink]="['../' + APP_ROUTES.dashboardHome]"
          ><img class="navbar-brand-height" src="/assets/img/logo.svg"
        /></a>
        <div class="top-menu-items">
          <ul>
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle white-color btn-items active"
                [class.active]="matchUrl('profile')"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <svg
                  id="icon-profile"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <g id="person-circle">
                    <path
                      id="Path_1"
                      data-name="Path 1"
                      d="M12.5,6.75A3.75,3.75,0,1,1,8.75,3,3.75,3.75,0,0,1,12.5,6.75Z"
                      transform="translate(1.25 0.75)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2"
                      data-name="Path 2"
                      d="M0,10A10,10,0,1,1,10,20,10,10,0,0,1,0,10ZM10,1.25A8.75,8.75,0,0,0,3.165,15.463C4.053,14.032,6.006,12.5,10,12.5s5.946,1.531,6.835,2.962A8.75,8.75,0,0,0,10,1.25Z"
                      fill="#fff"
                      fill-rule="evenodd"
                    />
                  </g>
                  <rect
                    id="Rectangle_5"
                    data-name="Rectangle 5"
                    width="20"
                    height="20"
                    fill="none"
                  />
                </svg>
              </a>

              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a
                    (click)="hideNavbar()"
                    class="dropdown-item fw-bold"
                    href="javascript:;"
                    [routerLink]="['../' + APP_ROUTES.profile]"
                    [class.active]="matchUrl('profile')"
                  >
                    <!-- My Profile -->
                    {{ "header.MyProfile" | translate }}</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item fw-bold"
                    href="javascript:;"
                    (click)="signOut()"
                  >
                    <!-- Logout -->
                    {{ "header.Logout" | translate }}</a
                  >
                </li>
              </ul>
            </li>
            <span *ngIf="getScreenWidth <= 992">
            <app-cart-dropdown></app-cart-dropdown>
          </span>
          </ul>
          <button
            (click)="Shownavbar()"
            class="navbar-toggler float-end"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>

        <div
          class="collapse navbar-collapse justify-content-end"
          [class.show]="showNavonToggle"
          id="navbarText"
        >
          <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
            <!-- <li class="nav-item">
                            <a class=" btn btn-main btn-red" aria-current="page" style="min-width: auto;" href="javascript:;"
                                [routerLink]="['/configurator']"
                                >
                                 New Pages
                            </a>
                        </li>  -->
            <li class="nav-item">
              <a
                (click)="hideNavbar()"
                class="btn btn-main btn-red"
                aria-current="page"
                style="min-width: auto"
                href="javascript:;"
                (click)="configure()"
              >
                <!-- Dashboard [class.active]="matchUrl('dashboard')" -->
                {{ "header.configurator" | translate }}
              </a>
            </li>
            <!-- <li class="nav-item"> -->
            <!-- <a class="nav-link white-color" href="javascript:;" -->
            <!-- [routerLink]="['../' + APP_ROUTES.wishlist]" [class.active]="matchUrl('wishlist')"> -->
            <!-- Wishlist -->
            <!-- {{'header.Wishlist' | translate}}</a> -->
            <!-- </li> -->
            <li class="nav-item">
              <a
                (click)="hideNavbar()"
                class="nav-link white-color"
                href="javascript:;"
                [routerLink]="['../' + APP_ROUTES.timeline]"
                [class.active]="matchUrl('timeline')"
              >
                <!-- Timeline -->
                {{ "header.messages" | translate }}
                <span class="activebar"></span>
                <!-- {{'header.Timeline' | translate}} -->
              </a>
              <a class="counter-msg">
              <span *ngIf="service?.messages" class="msg-notification">{{service?.messages}}</span>
            </a>
            </li>
            <li class="nav-item">
              <a
                (click)="hideNavbar()"
                class="nav-link white-color"
                href="javascript:;"
                [routerLink]="['../' + APP_ROUTES.faqList]"
                [class.active]="matchUrl('faq')"
              >
                <!-- FAQ -->
                {{ "header.FAQ" | translate }} <span class="activebar"></span
              ></a>
            </li>

            <!-- <li class="nav-item">
                            <a class="nav-link dropdown-toggle"  data-toggle="dropdown">
                             Language
                            </a>
                            <div class="dropdown-menu sub-menu-drop">
                              <a class="dropdown-item" > </a>
                              <a class="dropdown-item" ></a>
                            </div>
                          </li> -->
            <li class="nav-item dropdown">
              <a
                class="nav-link dropdown-toggle white-color no-border-mobile"
                id="navbarDropdown2"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <ng-container *ngIf="language == 'de'">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <g id="icon-globe" transform="translate(-1025 -92)">
                      <path
                        id="globe"
                        d="M0,10A10,10,0,1,1,10,20,10,10,0,0,1,0,10ZM9.375,1.346A4.4,4.4,0,0,0,7.016,3.665,9.963,9.963,0,0,0,6.431,5H9.375V1.346ZM5.113,5a11.584,11.584,0,0,1,.8-1.924A8.375,8.375,0,0,1,6.659,1.91,8.781,8.781,0,0,0,2.819,5ZM4.385,9.375a17.128,17.128,0,0,1,.39-3.125H2.092a8.7,8.7,0,0,0-.82,3.125H4.385ZM6.059,6.25a15.625,15.625,0,0,0-.423,3.125H9.375V6.25Zm4.566,0V9.375h3.737a15.619,15.619,0,0,0-.421-3.125ZM5.638,10.625a15.625,15.625,0,0,0,.421,3.125H9.375V10.625Zm4.987,0V13.75h3.316a15.567,15.567,0,0,0,.423-3.125ZM6.431,15a9.955,9.955,0,0,0,.585,1.335,4.407,4.407,0,0,0,2.359,2.319V15Zm.227,3.09a8.37,8.37,0,0,1-.746-1.166A11.585,11.585,0,0,1,5.112,15H2.819a8.78,8.78,0,0,0,3.84,3.09ZM4.775,13.75a17.065,17.065,0,0,1-.39-3.125H1.272a8.658,8.658,0,0,0,.82,3.125Zm8.566,4.34A8.78,8.78,0,0,0,17.181,15H14.887a11.587,11.587,0,0,1-.8,1.924,8.36,8.36,0,0,1-.746,1.166ZM10.625,15v3.654a4.4,4.4,0,0,0,2.359-2.319A9.955,9.955,0,0,0,13.569,15H10.625Zm4.6-1.25h2.683a8.658,8.658,0,0,0,.82-3.125H15.615a17.062,17.062,0,0,1-.39,3.125Zm3.5-4.375a8.7,8.7,0,0,0-.82-3.125H15.225a17.128,17.128,0,0,1,.39,3.125Zm-4.64-6.3A11.569,11.569,0,0,1,14.888,5h2.294a8.78,8.78,0,0,0-3.84-3.09,8.485,8.485,0,0,1,.746,1.166ZM13.569,5a9.957,9.957,0,0,0-.585-1.335,4.4,4.4,0,0,0-2.359-2.319V5Z"
                        transform="translate(1025 92)"
                        fill="#fff"
                      />
                      <rect
                        id="Rectangle_4"
                        data-name="Rectangle 4"
                        width="20"
                        height="20"
                        transform="translate(1025 92)"
                        fill="none"
                      />
                    </g>
                  </svg>
                  DE
                </ng-container>
                <ng-container *ngIf="language == 'en'">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <g id="icon-globe" transform="translate(-1025 -92)">
                      <path
                        id="globe"
                        d="M0,10A10,10,0,1,1,10,20,10,10,0,0,1,0,10ZM9.375,1.346A4.4,4.4,0,0,0,7.016,3.665,9.963,9.963,0,0,0,6.431,5H9.375V1.346ZM5.113,5a11.584,11.584,0,0,1,.8-1.924A8.375,8.375,0,0,1,6.659,1.91,8.781,8.781,0,0,0,2.819,5ZM4.385,9.375a17.128,17.128,0,0,1,.39-3.125H2.092a8.7,8.7,0,0,0-.82,3.125H4.385ZM6.059,6.25a15.625,15.625,0,0,0-.423,3.125H9.375V6.25Zm4.566,0V9.375h3.737a15.619,15.619,0,0,0-.421-3.125ZM5.638,10.625a15.625,15.625,0,0,0,.421,3.125H9.375V10.625Zm4.987,0V13.75h3.316a15.567,15.567,0,0,0,.423-3.125ZM6.431,15a9.955,9.955,0,0,0,.585,1.335,4.407,4.407,0,0,0,2.359,2.319V15Zm.227,3.09a8.37,8.37,0,0,1-.746-1.166A11.585,11.585,0,0,1,5.112,15H2.819a8.78,8.78,0,0,0,3.84,3.09ZM4.775,13.75a17.065,17.065,0,0,1-.39-3.125H1.272a8.658,8.658,0,0,0,.82,3.125Zm8.566,4.34A8.78,8.78,0,0,0,17.181,15H14.887a11.587,11.587,0,0,1-.8,1.924,8.36,8.36,0,0,1-.746,1.166ZM10.625,15v3.654a4.4,4.4,0,0,0,2.359-2.319A9.955,9.955,0,0,0,13.569,15H10.625Zm4.6-1.25h2.683a8.658,8.658,0,0,0,.82-3.125H15.615a17.062,17.062,0,0,1-.39,3.125Zm3.5-4.375a8.7,8.7,0,0,0-.82-3.125H15.225a17.128,17.128,0,0,1,.39,3.125Zm-4.64-6.3A11.569,11.569,0,0,1,14.888,5h2.294a8.78,8.78,0,0,0-3.84-3.09,8.485,8.485,0,0,1,.746,1.166ZM13.569,5a9.957,9.957,0,0,0-.585-1.335,4.4,4.4,0,0,0-2.359-2.319V5Z"
                        transform="translate(1025 92)"
                        fill="#fff"
                      />
                      <rect
                        id="Rectangle_4"
                        data-name="Rectangle 4"
                        width="20"
                        height="20"
                        transform="translate(1025 92)"
                        fill="none"
                      />
                    </g>
                  </svg>
                  EN
                </ng-container>
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li *ngIf="language === 'de'">
                  <a
                    (click)="hideNavbar()"
                    href="javascript:;"
                    class="dropdown-item fw-bold"
                    (click)="useLanguage('en')"
                    ><img src="./assets/img/enlish-language.jpg" /> English</a
                  >
                </li>
                <li *ngIf="language === 'en'">
                  <a
                    (click)="hideNavbar()"
                    href="javascript:;"
                    class="dropdown-item fw-bold"
                    (click)="useLanguage('de')"
                    ><img src="./assets/img/german.jpg" /> German</a
                  >
                </li>
              </ul>
            </li>

            <!-- {{'demo.title' | translate}} -->

            <li class="nav-item dropdown mobile-hide">
              <a
                class="nav-link dropdown-toggle white-color btn-items active"
                [class.active]="matchUrl('profile')"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <svg
                  id="icon-profile"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <g id="person-circle">
                    <path
                      id="Path_1"
                      data-name="Path 1"
                      d="M12.5,6.75A3.75,3.75,0,1,1,8.75,3,3.75,3.75,0,0,1,12.5,6.75Z"
                      transform="translate(1.25 0.75)"
                      fill="#fff"
                    />
                    <path
                      id="Path_2"
                      data-name="Path 2"
                      d="M0,10A10,10,0,1,1,10,20,10,10,0,0,1,0,10ZM10,1.25A8.75,8.75,0,0,0,3.165,15.463C4.053,14.032,6.006,12.5,10,12.5s5.946,1.531,6.835,2.962A8.75,8.75,0,0,0,10,1.25Z"
                      fill="#fff"
                      fill-rule="evenodd"
                    />
                  </g>
                  <rect
                    id="Rectangle_5"
                    data-name="Rectangle 5"
                    width="20"
                    height="20"
                    fill="none"
                  />
                </svg>
              </a>

              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <a
                    (click)="hideNavbar()"
                    class="dropdown-item fw-bold"
                    href="javascript:;"
                    [routerLink]="['../' + APP_ROUTES.profile]"
                    [class.active]="matchUrl('profile')"
                  >
                    <!-- My Profile -->
                    {{ "header.MyProfile" | translate }}</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item fw-bold"
                    href="javascript:;"
                    (click)="signOut()"
                  >
                    <!-- Logout -->
                    {{ "header.Logout" | translate }}</a
                  >
                </li>
              </ul>
            </li>
            <span *ngIf="getScreenWidth > 992">
            <app-cart-dropdown></app-cart-dropdown>
            </span>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  <!-- header Html -->
</ng-container>

<ng-container *ngIf="showOnlyDashboard">
  <header>
    <nav class="navbar navbar-expand-lg primary-bg-color navbar-dark fixed-top">
      <div class="container">
        <a class="navbar-brand h-50" href="javascript:;"
          ><img class="navbar-brand-height" src="/assets/img/logo.svg"
        /></a>
        <button
          class="navbar-toggler float-end"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbarText"
        >
          <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a
                class="nav-link white-color"
                aria-current="page"
                href="javascript:;"
                [routerLink]="['../' + APP_ROUTES.dashboardHome]"
                [class.active]="matchUrl('dashboard')"
                >Dashboard
              </a>
            </li>
            <!-- <li class="nav-item">
                            <a class="nav-link white-color" aria-current="page" href="javascript:;"
                                [routerLink]="['../' + APP_ROUTES.wishlist]"
                                [class.active]="matchUrl('wishlist')">Wishlist </a>
                        </li> -->

            <li class="nav-item">
              <a
                class="nav-link white-color"
                aria-current="page"
                href="javascript:;"
                >{{ username | titlecase }}
              </a>
            </li>
            <span *ngIf="getScreenWidth > 992">
              <app-cart-dropdown></app-cart-dropdown>
            </span>
          </ul>
        </div>
      </div>
    </nav>
  </header>
  <!-- header Html -->
</ng-container>

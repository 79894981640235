import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentsRoutingModule } from './documents-routing.module';
import { MainComponent } from './components/main/main.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DocumentMaskDirective } from 'src/app/shared/helpers/common/document-mask.directive';
import { DocMaskPipe } from 'src/app/shared/helpers/common/document.pipe';

@NgModule({
  declarations: [MainComponent, DocumentMaskDirective,DocMaskPipe],
  imports: [
    CommonModule,
    DocumentsRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
  ],
  exports:[DocumentMaskDirective,DocMaskPipe]
})
export class DocumentsModule {}

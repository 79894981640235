<!--ng-container *ngIf="showFooter"-->

<!-- footer Html -->
<footer class="footer mt-auto py-md-4 py-3 primary-bg-color fixed-bottom">
  <div class="container">
    <div class="footer-row row">
      <div class="col-md-6">
        <p class="white-color text-center text-md-start mb-0">
          {{ "footer.copyright" | translate }} © {{ currentYear }},
          {{ "footer.poweredByVilgertshofer" | translate }}
        </p>
      </div>
      <div class="col-md-6">
        <ul class="footer-listing">
          <a routerLink="/cookie-policy">{{
            "footer.CookiePolicy" | translate
          }}</a>
          <a routerLink="/data-privacy">{{
            "footer.DataPrivacy" | translate
          }}</a>
          <a routerLink="/imprint">{{ "footer.Imprint" | translate }}</a>
        </ul>
      </div>
    </div>
  </div>
</footer>
<!--/ng-container-->

    <div class="pt-3">
          <div class="px-4">
            <h3 class="text-center">
              {{ "shoppingcart.closeconfigurationtitle" | translate
              }}<span class="titlebar mx-auto d-block"></span>
            </h3>
            <p class="body-text text-center m-0 mt-3">
              {{ "shoppingcart.closeconfigurationdesc1" | translate }}
            </p>
            <p class="body-text text-center m-0">
              {{ "shoppingcart.closeconfigurationdesc2" | translate }}
            </p>
            <div class="button-row d-flex align-items-center justify-content-around p-3">
              <button type="button" class="btn btn-main btn-white" (click)="closeModal()">
                {{ "shoppingcart.closeconfigurationcancel" | translate }}
              </button>
  
              <button type="button" class="btn btn-main" data-bs-dismiss="modal" aria-label="Close"
                (click)="showCongigurationSuccessMessageApi()"
                data-bs-toggle="modal" data-bs-target="#configurationSuccess">
                {{ "shoppingcart.closeconfigurationcomplete" | translate }}
              </button>
            </div>
          </div>
    </div>
import { PreAuthPagesService } from './../pre-auth-pages.service';
import { Component, OnInit } from '@angular/core';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public APP_ROUTES = APP_ROUTES;
  public LoginForm: FormGroup;
  public isSubmitting: boolean = false;
  public isDisabled: boolean = false;
  public authorized: boolean = false;
  public passwordVisibility: boolean = false;
  password;
  showPassword=false;
  returnUrl: any = '';
  constructor(
    private router: Router,
    private auth: PreAuthPagesService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.password='password'
    this.route.queryParams.subscribe((params) => {
      if (params['redirect']) {
        this.returnUrl = params['redirect'];
      } else {
        this.returnUrl = '';
      }
    });
  }

  handleRedirect() {
    if (this.returnUrl.startsWith('/timeline')) {
      let url = this.returnUrl?.split('?')[1];
      let key = url?.split('=')[0];
      let id = url?.split('=')[1];
      this.router.navigate([this.returnUrl?.split('?')[0]], {
        queryParams: {
          [key]: id,
        },
      });
      return;
    }
    this.router.navigate([this.returnUrl]);
  }

  get formControls() {
    return this.LoginForm.controls;
  }

  private initForm(): void {
    this.LoginForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/),
      ]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
      rememberMe: new FormControl(''),
    });
  }

  public doLogin(e: number): void {
    if (this.LoginForm.invalid) {
      this.isSubmitting = true;
      return;
    }
    this.isSubmitting = false;

    this.auth.login(this.LoginForm.value).subscribe(
      (data: any) => {
        if (data.status === 200) {
          setTimeout(() => {
            this.toastr.success(
              this.translate.instant('login.loginMsgTsFile'),
              ''
            );
          }, 1000);
          if (this.returnUrl) {
            this.handleRedirect();
          } else {
            this.router.navigate([APP_ROUTES.dashboardHome]).then(() => { });
          }
         
        } else if (data.status === 401) {
          this.toastr.error(this.translate.instant('login.invalidcred'));
        } else if (data.status === 403) {
          this.toastr.error(
            'Your account is in blocked state, contact your administrator',
            'Account Blocked'
          );
        } else {
          this.toastr.error('Something went wrong, please try again', '');
        }
      },
      (err) => {
        console.log('error', err);
        if (err.status === 400 || err.status === 401) {
          this.toastr.error(this.translate.instant('login.invalidcred'));
        } else if (err.status === 403) {
          this.toastr.error(
            'Your account is in blocked state, contact your administrator',
            'Account Blocked'
          );
        } else if (err.error.message) {
          this.toastr.error(err.error.message, '');
        } else {
          this.toastr.error('Something went wrong. Please try again later', '');
        }
      }
    );
  }

  onClick() {
    this.showPassword = !this.showPassword;
    if (this.password === 'password') {
      this.password = 'text';
    } else {
      this.password = 'password';
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-cookies-policy',
  templateUrl: './cookies-policy.component.html',
  styleUrls: ['./cookies-policy.component.scss']
})
export class CookiesPolicyComponent implements OnInit {

  constructor(public common:CommonService,private cookieService: CookieService,public router:Router) { 
    
  }
 
  pageContent:any;

  ngOnInit(): void {
    const lang = this.cookieService.get('dataLang');
    const payload = {
      lang: lang,
      type: 'privacy_policy'
    }
    this.common.getPrivacy(payload).subscribe((res:any)=>{
      this.pageContent= res.content;
      console.log(this.pageContent)
    })
  }

}

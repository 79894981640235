import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chatMask'
})
export class TimeLinePipe implements PipeTransform{
    transform(value:string) : string {
    if(value?.lastIndexOf("_") > 0){
      const lastIndex = value?.lastIndexOf("_");
      const startingStr = value?.substring(0, lastIndex);
      const lastValue = value?.substring(lastIndex + 1)?.split('.')[1];
      return `${startingStr}.${lastValue}`
    }else{
      return value
    }
   
    }
}
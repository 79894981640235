import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-data-privacy',
  templateUrl: './data-privacy.component.html',
  styleUrls: ['./data-privacy.component.scss']
})
export class DataPrivacyComponent implements OnInit {

  constructor(public common:CommonService,private cookieService: CookieService) { }

  pageContent:any;
  ngOnInit(): void {
    const lang = this.cookieService.get('dataLang');
    const payload = {
      lang: lang,
      type: 'data_privacy'
    }
    this.common.getPrivacy(payload).subscribe((res:any)=>{
      this.pageContent= res.content;
      console.log(this.pageContent)
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from "@angular/router";
import { PreAuthPagesService } from '../../pre-auth-pages.service';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  public APP_ROUTES = APP_ROUTES;
	public forgotForm: FormGroup;
	public isSubmitting: boolean = false;
	public isDisabled: boolean = false;
  isForgotSuccess: boolean = false;
  
  constructor(private router: Router,
    private service : PreAuthPagesService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
		this.forgotForm = new FormGroup({
			'email': new FormControl('', [Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)])
		}, { updateOn: 'blur' });
  }
  
  doForgot(e: number): void {
		this.isForgotSuccess = false;
		if (this.forgotForm.invalid) {
			this.isSubmitting = true;
			return;
		}
		this.isSubmitting = false;
		this.service.forgotPassword(this.forgotForm.value).subscribe((data: any) => {
			if (data.status === 200) {
				setTimeout(() => {
					this.toastr.success(data.body.message, '');
				}, 1000);
				this.isForgotSuccess = true;
				this.router.navigate([APP_ROUTES.login]).then(() => {
					// for some async task
				});
			}
		}, err => {
			this.toastr.error(err.error.message, '');
		});
	}

}

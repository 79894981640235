import { CommonService } from 'src/app/shared/service/common.service';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { Component, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
} from '@angular/router';
import { LocalStorageService } from '../../storage/local-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { BaseService } from '../../service/base.service';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DocumentsService } from 'src/app/components/post-auth-pages/documents/documents.service';
import { ToastrService } from 'ngx-toastr';
import { TimelineService } from 'src/app/components/post-auth-pages/timeline/timeline.service';
import { constants } from '../../helpers/common/global-constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  language: string = '';
  subscription$: Subscription[] = [];
  docId: any;
  docFile: any;
  reqIds: any;
  private constants = constants;
  constructor(
    private ls: LocalStorageService,
    private router: Router,
    public _cs: CookieService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private baseService: BaseService,
    public CommonService: CommonService,
    private route: ActivatedRoute,
    private doc: DocumentsService,
    private toastr: ToastrService,
    public service: TimelineService,
    private ts: TranslateService,
    private cookies: CookieService
  ) {
    if (this._cs.get('dataLang')) {
      this.language = this._cs.get('dataLang');
    } else {
      this.language = 'de';
      this._cs.set('dataLang', this.language);
    }
    this.baseService.setSeletedLangugae(this.language);
    translate.setDefaultLang(this.language);
    this.translate.use(this.language);
    let s2 = this.CommonService.isHamburgerMenuHide$.subscribe(
      (isHide: boolean) => {
        if (isHide) {
          this.hideNavbar();
        }
      }
    );
    this.subscription$?.push(s2);
    let s3 = this.doc.shareDocument$.subscribe((r) => {
      if (r) {
        this.docId = r?.id;
        this.docFile = r?.document;
        this.sendDoc();
      }
    });
    this.subscription$?.push(s3);
  }

  sendDoc() {
    let currentUser = this.ls.getItem('currentUser');
    let payload = {
      sender_id: currentUser?.id,
      receiver_id: currentUser.create_by,
      message: '',
      document: this.docFile,
      type: 'document',
      sender_type: 'client',
      clientdocumentid: this.docId,
      lang_type: this.translate.currentLang
    };

    this.service.sendMessage(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.toastr.success(
            this.ts.instant('timeline.documentUploadedMsgTsFile'),
            ''
          );
        }
      },
      (err) => {
        this.toastr.error(err.error.message, '');
      }
    );
  }
  showHeader = false;
  APP_ROUTES = APP_ROUTES;
  username = '';
  showOnlyDashboard = false;
  preauthHeader = false;
  public getScreenWidth: any;
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
  }
  ngOnInit(): void {
    this.onWindowResize()
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.ls.getItem('token')) {
          let val = this.ls.getItem('adminSpecialLogin');
          if (val === true) {
            this.showOnlyDashboard = true;
            this.getUsername();
          } else {
            this.showHeader = true;
            this.getUsername();
          }
        } else {
          this.username = '';
          this.showHeader = false;
        }

        setTimeout(() => this.setChanged(), 0);
      } else {
        this.preauthHeader = true;
      }
    });
  }

  ngOnDestroy() {
    try {
      if (this.subscription$.length > 0) {
        this.subscription$.forEach((s: Subscription) => {
          !s.closed && s.unsubscribe();
        });
      }
    } catch (e: any) {
      console.log(e);
    }
  }
  fetchRequiredIDForGetRooms(): void {
    if (localStorage.getItem(this.constants.requiredId)) {
      this.reqIds = JSON.parse(localStorage.getItem(this.constants.requiredId));
    }
  }

  get isConfigured(): boolean {
    return JSON.parse(localStorage.getItem('ispConf'))
  }
  configure() {
    if (!this.isConfigured) {
      !this.router.url.includes('home') &&
        this.router.navigate(['../' + APP_ROUTES.dashboardHome], {
          queryParams: {
            configure: true,
          },
        });

      this.router.url.includes('home') && this.getRefresh();
      return
    }
    /**
     * for configurator page
     */

    this.fetchRequiredIDForGetRooms();
    let queryParams = {
      tId: this.reqIds?.tId,
      pId: this.reqIds?.pId,
    };
    this.router.navigate(['/configurator'], {
      queryParams,
    });

  }

  getRefresh() {
    this.CommonService.isProjectFocused.next(true);
  }

  matchUrl(segment: string): boolean {
    return this.router.url.includes(segment);
  }

  public signOut() {
    this.ls.clear();
    this.router.navigate([APP_ROUTES.login]);
  }

  getUsername() {
    let userData = this.ls.getItem('currentUser');
    this.username = userData?.first_name;
  }

  useLanguage(language: string) {
    this.baseService.setSeletedLangugae(language);
    this._cs.set('dataLang', language);
    this.translate.use(language);
    this.language = language;
    let url = this.router.url;
    if(url==='/cookie-policy'||'/imprint'||'/data-privacy'){
      window.location.reload()
    }
  }

  setChanged() {
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  showNavonToggle = false;

  Shownavbar() {
    this.showNavonToggle = true;
  }

  hideNavbar() {
    this.showNavonToggle = false;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  shareDocument$: Subject<any> = new Subject<any>();
  constructor(private http: HttpClient) {}

  public uplaodDocPdf(payload: any): Observable<any> {
    const url: string = ApiRoutes.uplaodDocPdf;
    return this.http.post(url, payload, { observe: 'response' });
  }
  public templateWiseDocs(payload: any): Observable<any> {
    const url: string = ApiRoutes.templateWiseDocs;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public addProjectDoc(payload: any): Observable<any> {
    const url: string = ApiRoutes.addProjectDoc;
    return this.http.post(url, payload, { observe: 'response' });
  }
  public DeleteDoc(payload: any): Observable<any> {
    const url: string = ApiRoutes.DeleteDoc;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getTempDocByID(payload: any): Observable<any> {
    const url: string = ApiRoutes.getTempDocByID;
    return this.http.post(url, payload, { observe: 'response' });
  }
  public updateDocByID(payload: any): Observable<any> {
    const url: string = ApiRoutes.updateDocByID;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public listDocCategory(payload: any): Observable<any> {
    const url: string = ApiRoutes.listDocCategory;
    return this.http.post(url, payload, { observe: 'response' });
  }

  get currentUser(): any {
    return localStorage?.currentUser
      ? JSON.parse(localStorage.getItem('currentUser'))
      : sessionStorage?.currentUser
      ? JSON.parse(sessionStorage.getItem('currentUser'))
      : null;
  }
}

import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class AuthGuard implements CanActivate {

	constructor(
		private router: Router
	) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean  {
        const url: string = state.url;
    	return this.checkAuthState(url, state);
	}

	canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		return this.canActivate(route, state);
	}

	checkAuthState(url: string, state:RouterStateSnapshot) {
		if (localStorage.getItem('token') || sessionStorage.getItem('token')) {
			return true;
        }else{
			this.router.navigate(['/login'], {
                queryParams: { redirect: state.url },
              });
		}
	}
}


<!-- <div class="login-box">
	<div class="login-logo">
	  <a href="../../index2.html"><b>Admin </b>Login</a>
	</div>
	<div class="card">
	  <div class="card-body login-card-body">
		<form novalidate [formGroup]="LoginForm">
		  <div class="input-group mb-3">
			<input type="email" class="form-control" formControlName="email" autocomplete="off" placeholder="Your Email Address" >
			<div>
				<ng-container *ngIf="LoginForm.controls['email'].errors && (LoginForm.get('email').dirty || LoginForm.get('email').touched || isSubmitting)">
					<small class="text-danger error-msg" [hidden]="!LoginForm.controls['email'].errors.required">*Email is required.</small>
					<small class="text-danger error-msg" [hidden]="!LoginForm.controls['email'].errors.pattern">*Email is invalid.</small>
				</ng-container>
			</div>
			<div class="input-group-append">
			  <div class="input-group-text">
				<span class="fas fa-envelope"></span>
			  </div>
			</div>
		  </div>
		  <div class="input-group mb-3">
			<input type="{{passwordVisibility ? 'text' : 'password'}}" class="form-control" formControlName="password" autocomplete="off" placeholder="Password">
			<div>
				<ng-container *ngIf="LoginForm.controls['password'].errors && (LoginForm.get('password').dirty || LoginForm.get('password').touched || isSubmitting)">
					<small class="text-danger error-msg" [hidden]="!LoginForm.controls['password'].errors.required">*Password is required.</small>
					<small class="text-danger error-msg" [hidden]="!LoginForm.controls['password'].errors.minlength">
						*Password must be minimum 6 character long.
					</small>
				</ng-container>
			</div>
			<div class="input-group-append">
			  <div class="input-group-text">
				<span class="fas fa-lock"></span>
			  </div>
			</div>
		  </div>
		  <div class="row">
			<div class="col-8">
			  <div class="icheck-primary">
				<input formControlName="rememberMe" type="checkbox" id="remember">
				<label for="remember">
				  Remember Me
				</label>
			  </div>
			</div>
			<div class="col-4">
			  <button type="submit" class="btn btn-primary btn-block" (click)="doLogin($event)">Sign In</button>
			</div>
		  </div>
		</form>

		<p class="mb-1">
		  <a href="javascript:;" [routerLink]="['../' + APP_ROUTES.forgotPassword]">I forgot my password</a>
		</p>
	  </div>
	</div>
  </div> -->
<app-header></app-header>

<section class="log-in-bg d-flex align-items-center justify-content-center">
  <div class="container pt-2 pb-2">
    <div
      class="row g-0 overflow-hidden d-flex bg-white box-shadow login-wrapper align-items-stretch">
      <div class="col-lg-6 text-center">
        <div class="login-img-wrapper" style="overflow: hidden;">
            <video id="loginVideo" muted loop playsinline style="max-height: 510px;" preload="auto">
                <source src="/assets/media/login-video.mp4" type="video/mp4">
            </video>
        </div>
      </div>
      <div class="col-lg-6 white-bg-color">
        <div class="form-signin">
          <form novalidate [formGroup]="LoginForm">
            <h1 class="page-title">
              {{ "login.heading2" | translate }} <span class="titlebar"></span>
            </h1>
            <div class="mb-3 w-100">
              <div class="form-floating border-bottom">
                <!-- <img src="assets/img/mail.png" alt="icon" class="input-icon"> -->
                <i
                  class="bi bi-envelope icons-style-input icon-color-input fs-5"
                ></i>
                <input
                  formControlName="email"
                  autocomplete="off"
                  type="email"
                  class="form-control ps-1 pe-4"
                  id="floatingInput"
                  placeholder="{{ 'login.emailPlaceholder' | translate }} "
                />
                <label for="floatingInput" class="ps-0"
                  >{{ "login.email" | translate }}
                </label>
              </div>
              <ng-container
                *ngIf="
                  LoginForm.controls['email'].errors &&
                  (LoginForm.get('email').dirty ||
                    LoginForm.get('email').touched ||
                    isSubmitting)
                "
              >
                <small
                  class="text-danger error-msg"
                  [hidden]="!LoginForm.controls['email'].errors.required"
                  >{{ "login.emailRequired" | translate }}</small
                >
                <small
                  class="text-danger error-msg"
                  [hidden]="!LoginForm.controls['email'].errors.pattern"
                  >{{ "login.emailInvalid" | translate }}</small
                >
              </ng-container>
            </div>

            <div class="mb-3 w-100">
              <div class="form-floating border-bottom">
                <!-- <img src="assets/img/pass.png" alt="icon" class="input-icon"> -->
                <!-- <i class="bi bi-lock icons-style-input icon-color-input fs-5"></i> -->

                <div (click)="onClick()">
                  <i
                    class="fa fa-eye icons-style-input icon-color-input fs-6"
                    aria-hidden="true"
                    *ngIf="!showPassword"
                  ></i>
                  <i
                    class="fa fa-eye-slash icons-style-input icon-color-input fs-6"
                    aria-hidden="true"
                    *ngIf="showPassword"
                  ></i>
                </div>
                <input
                  [type]="password"
                  formControlName="password"
                  autocomplete="off"
                  class="form-control ps-1 pe-4"
                  id="floatingPassword"
                  placeholder="{{ 'login.passwordPlaceholder' | translate }}"
                />
                <label for="floatingPassword" class="ps-0">{{
                  "login.password" | translate
                }}</label>
              </div>
              <ng-container
                *ngIf="
                  LoginForm.controls['password'].errors &&
                  (LoginForm.get('password').dirty ||
                    LoginForm.get('password').touched ||
                    isSubmitting)
                "
              >
                <small
                  class="text-danger error-msg"
                  [hidden]="!LoginForm.controls['password'].errors.required"
                  >{{ "login.passwordRequired" | translate }}</small
                >
                <small
                  class="text-danger error-msg"
                  [hidden]="!LoginForm.controls['password'].errors.minlength"
                >
                  {{ "login.passwordCharLimit" | translate }}
                </small>
              </ng-container>
            </div>
            <div class="row mb-4 forgot-row">
              <div class="col-6">
                <div class="form-check mb-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    formControlName="rememberMe"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    {{ "login.rememberMe" | translate }}
                  </label>
                </div>
              </div>
              <div class="col-6 text-right d-flex justify-content-end">
                <a
                  href="javascript:;"
                  [routerLink]="['../' + APP_ROUTES.forgotPassword]"
                  class="forgot-text"
                  >{{ "login.forgotPassword" | translate }}</a
                >
              </div>
            </div>
            <button
              class="btn btn-main"
              type="submit"
              (click)="doLogin($event)"
            >
              {{ "login.signIn" | translate }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../storage/local-storage.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  currentYear : any;

  constructor(private ls: LocalStorageService,
    private router: Router) { }

  showFooter = false;
  
  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.ls.getItem("token")) {
          this.showFooter = true;
          this.currentYear = new Date().getFullYear();
        }
        else {
          this.showFooter = false;
        }
      }
    });

    
  }

}

import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements OnInit {
  constructor(public common:CommonService,private cookieService: CookieService) { }

  pageContent:any;
  ngOnInit(): void {
    const lang = this.cookieService.get('dataLang');
    const payload = {
      lang: lang,
      type: 'imprint'
    }
    this.common.getPrivacy(payload).subscribe((res:any)=>{
      this.pageContent= res.content;
      console.log(this.pageContent)
    })
  }

}

import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDocMask]',
})
export class DocumentMaskDirective {
  constructor(public ngControl: NgControl) {

    console.log('dirfff');
    
   }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    if(event){
      this.onInputChange(event);
    }
  }

  // @HostListener('keydown.backspace', ['$event'])
  // keydownBackspace(event) {
  //   if(event){
  //   this.onInputChange(event.target.value, true);
  //   }
  // }
  

  onInputChange(str:any) {
      if(str?.lastIndexOf("_") > 0){
        const lastIndex = str?.lastIndexOf("_");
        const startingStr = str?.substring(0, lastIndex);
        const lastValue = str?.substring(lastIndex + 1)?.split('.')[1];
        this.ngControl.valueAccessor.writeValue(`${startingStr}.${lastValue}` || '');
      }else{
        this.ngControl.valueAccessor.writeValue(str || '');
      }
  }
}
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  // public clear(): void {
  //   localStorage.clear();
  // }

  // public getItemString(key: string): string {
  //   return localStorage.getItem(key);
  // }

  // public getItem(key: string) {
  //   if (key === 'token') {
  //     return localStorage.getItem(key);
  //   }
  //   return JSON.parse(localStorage.getItem(key));
  // }

  // public removeItem(key: string): void {
  //   localStorage.removeItem(key);
  // }

  // public setItem(key: string, data: string): void {
  //   localStorage.setItem(key, data);
  // }


  // ---------------------------------------------------------------------//

  public clear(): void {
    localStorage.clear();
    sessionStorage.clear();
  }

  public getItemString(key: string): string {
    return localStorage.getItem(key) || sessionStorage.getItem(key);
  }

  public isRememberMe(): boolean {
    return localStorage.getItem('currentUser') ? true : false;
  }

  public getItem(key: string) {
    const data = localStorage.getItem(key) || sessionStorage.getItem(key);
    if (key === 'token') {
      return data;
    }
    return JSON.parse(data);
  }

  public removeItem(key: string): void {
    localStorage.removeItem(key);
    sessionStorage.removeItem(key);
  }

  public setItem(key: string, data: string, rememberMe?: boolean): void {
    if (rememberMe) {
      localStorage.setItem(key, data);
    } else {
      sessionStorage.setItem(key, data);
    }
    if (key === 'token' && localStorage.getItem('userSelectedDate')) {
      localStorage.removeItem('userSelectedDate');
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../storage/local-storage.service';
import { Router } from '@angular/router';
import { APP_ROUTES } from '../../routes/appRoutes';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss']
})
export class TopNavbarComponent implements OnInit {

  constructor(private localStorage : LocalStorageService,
    private router: Router,) { }

  ngOnInit(): void {
  }
  public signOut(){
    this.localStorage.clear();
    this.router.navigate([APP_ROUTES.login]);  
}
  
}

<main class="page-wrapper">
    <section class="pt-5 pb-5">
        <div class="container">

            <div class="contact-us-wrapper">

                <div class="titles">
                    <h2 class="pagetitle text-center">Contact Us <span class="titlebar mx-auto"></span></h2>
                    <p class="body-text text-center mt-3">Any quetion and remark Just write us a message!</p>
                </div>

                <div class="contact-box-wrapper row mt-5 mx-0">

                    <div class="col-12 col-lg-4 px-0 ">
                        <div class="contac-info">
                            <h3>Contact Information</h3>
                            <p class="body-text mt-2">Fill the form or contact us through mail or call, our team will get back to you within 24 Hrs </p>
                            <a class="d-flex align-items-start cn-items mt-5" href="mailto:contact@cilgertshofer.com">
                                <img src="assets/img/email.png"> <span>contact@cilgertshofer.com</span>
                            </a>
                            <a class="d-flex align-items-start cn-items my-4" href="tel:+12 345-678-9012">
                                <img src="assets/img/phone-call.png"> <span>+12 345-678-9012</span>
                            </a>
                            

                            <a class="d-flex align-items-start cn-items" >
                                <img src="assets/img/location.png"> <span> Am Hartholz 1, 82239 Alling, Germany</span>
                            </a>

                        </div>
                       
                    </div>

                    <div class="col-12 col-lg-8 px-0 contact-form">
                        <div class="form-wrapper">

                                <form >
                                  
                                 <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="mb-3 w-100">
                                            <div class="form-floating border-bottom">
                                              <input autocomplete="off" type="email" class="form-control ps-1 pe-4"
                                                id="floatingInput" placeholder="Name " />
                                              <label for="floatingInput" class="ps-0">Name</label>
                                            </div>
                                           
                                          </div>
                                    </div>

                                    <div class="col-12 col-md-6">
                                        <div class="mb-3 w-100">
                                            <div class="form-floating border-bottom">
                                              <input autocomplete="off" type="email" class="form-control ps-1 pe-4"
                                                id="floatingInput" placeholder="Email " />
                                              <label for="floatingInput" class="ps-0">Email</label>
                                            </div>
                                           
                                          </div>
                                    </div>
                                 </div>

                                 <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="mb-3 w-100">
                                            <div class="form-floating border-bottom">
                                              <input autocomplete="off" type="email" class="form-control ps-1 pe-4"
                                                id="floatingInput" placeholder="Phone " />
                                              <label for="floatingInput" class="ps-0">Phone</label>
                                            </div>
                                           
                                          </div>
                                    </div>

                                    <div class="col-12 col-md-6">
                                        <div class="mb-4 w-100">
                                            <div class="form-floating border-bottom">
                                              <select autocomplete="off" type="email" class="form-control ps-1 pe-4">
                                                <option>Property Owner</option>
                                                <option>Customer</option>
                                                <option>Other</option>

                                                </select>
                                            </div>
                                           
                                          </div>
                                    </div>
                                 </div>

                                 <div class="row">
                                   

                                    <div class="col-12 col-md-12">
                                        <div class="mb-4 w-100">
                                            <div class="form-floating ">
                                                <p class="mb-2">Your Message</p>
                                              <textarea class="form-control ps-1 pe-4" placeholder="Your Message"></textarea>
                                              
                                            </div>
                                           
                                          </div>
                                    </div>
                                 </div>
                      
                                  <button class="btn btn-main mt-3 ms-auto d-block" type="submit">
                                    Submit
                                  </button>
                                </form>
                             

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </section>
</main>
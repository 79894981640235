import { Component, Input, OnInit } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationSuccessComponent } from '../configuration-success/configuration-success.component';
import { WishlistService } from '../../../wishlist/wishlist.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/shared/service/common.service';
import { LocalStorageService } from 'src/app/shared/storage/local-storage.service';

@Component({
  selector: 'app-configuration-confirmation',
  templateUrl: './configuration-confirmation.component.html',
  styleUrls: ['./configuration-confirmation.component.scss']
})
export class ConfigurationConfirmationComponent implements OnInit {
@Input() activeProject!:any;
  currentUser: any;
  recieverId: any;
  constructor(private modalService:NgbModal,
    private service: WishlistService,
    private _toastr: ToastrService,
    private _acRoute: ActivatedRoute,
    private _router: Router,
    private _commonService: CommonService,
    private fb: FormBuilder,
    private ls: LocalStorageService,
    private _translate: TranslateService) { }

  ngOnInit(): void {
    console.log(this.activeProject, 'active projectjjs');
    this.initSenderAndRecieverId()
  }
  initSenderAndRecieverId() {
    this.currentUser = this.ls.getItem('currentUser');
    this.recieverId = this.currentUser.create_by;
  }

  showCongigurationSuccessMessageApi(){
    let payload = {
      id:this.activeProject?.details?.id,
      is_completed:1,
      receiver_id:this.recieverId,
      lang_type: this._translate.currentLang
    }
    this.service.completeProject(payload).subscribe((res:any)=>{
      if (res.status === 200) {
        this.modalService.dismissAll();
        localStorage.setItem('configurationCompleted', 'true');
        localStorage.setItem('configuredProjectListCount','0')
        localStorage.setItem('projectCompleted','true')
        setTimeout(()=>{this.openSuccesModal()},300)
      }
    },
    (err) => {
      this._toastr.error(err.error.message, '');
    })
  }

  openSuccesModal(){
    const modalRef = this.modalService.open(ConfigurationSuccessComponent, {
      backdrop:'static',
      keyboard:false,
      centered:true,
    });

    modalRef.result.then(
      (result) => {
        // Handle modal close event
        if (result === ModalDismissReasons.BACKDROP_CLICK) {
          // Do something when the modal is closed by clicking on the backdrop
        } else if (result === ModalDismissReasons.ESC) {
          // Do something when the modal is closed by pressing the ESC key
        } else {
          // Do something when the modal is closed by other means
        }

        console.log('resultrr aadd');
        
      },
      (reason) => {
        // Handle modal dismissal (e.g., when the user clicks outside the modal or presses ESC)
        console.log(`Modal dismissed with reason: ${reason}`);
      }
    );
  }
  closeModal(){
   this.modalService.dismissAll()
  }

}

import { AuthGuard } from './auth/auth.guard';
import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { LoginComponent } from './components/pre-auth-pages/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';


//Shared
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { ForgotPasswordComponent } from './components/pre-auth-pages/forgot-password/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/pre-auth-pages/forgot-password/change-password/change-password.component';
import { MenubarComponent } from './shared/components/menubar/menubar.component';
import { TopNavbarComponent } from './shared/components/top-navbar/top-navbar.component';
import { FaqComponent } from './components/post-auth-pages/faq/faq.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { TimelineComponent } from './components/post-auth-pages/timeline/timeline.component';
import { FileSaverModule } from 'ngx-filesaver';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ImageZoomComponent } from './shared/components/image-zoom/image-zoom.component';
import { AdminLoginLoaderPageComponent } from './components/pre-auth-pages/admin-login-loader-page/admin-login-loader-page.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxSpinnerModule } from "ngx-spinner";
import { BaseService } from './shared/service/base.service';
import { TooltipModule, TooltipOptions } from '@teamhive/ngx-tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CartDropdownComponent } from './shared/components/cart-dropdown/cart-dropdown.component';
import { ShoppingCartModule } from './components/post-auth-pages/shopping-cart/shopping-cart.module';
import { DocumentsModule } from './components/post-auth-pages/documents/documents.module';
import { CookiesPolicyComponent } from './components/pre-auth-pages/informative-pages/cookies-policy/cookies-policy.component';
import { DataPrivacyComponent } from './components/pre-auth-pages/informative-pages/data-privacy/data-privacy.component';
import { ImprintComponent } from './components/pre-auth-pages/informative-pages/imprint/imprint.component';
import { ContactUsComponent } from './components/post-auth-pages/contact-us/contact-us.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { TimeLinePipe } from './shared/helpers/common/timeline.pipe';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    MenubarComponent,
    TopNavbarComponent,
    FaqComponent,
    HeaderComponent,
    FooterComponent,
    TimelineComponent,
    ImageZoomComponent,
    AdminLoginLoaderPageComponent,
    CartDropdownComponent,
    CookiesPolicyComponent,
    DataPrivacyComponent,
    ImprintComponent,
    ContactUsComponent,
    PageNotFoundComponent,
    TimeLinePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ShoppingCartModule,
    DocumentsModule,
   


    // Shared
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    ToastrModule.forRoot({
      preventDuplicates: true,
      maxOpened: 1
    }),
    SweetAlert2Module.forRoot(),
    FileSaverModule,
    NgxSpinnerModule,

    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    }),
    TooltipModule.forRoot({
        placement: 'top',
        arrow: 'true',
        arrowType: 'sharp',
        allowHTML: true,
        maxWidth: 200
      } as unknown as TooltipOptions),
    NgbModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    CookieService,BaseService,Title],
  bootstrap: [AppComponent],
  exports:[
    TranslateModule,
    CartDropdownComponent,
    TimeLinePipe
  ],
})
export class AppModule { }

export class APP_ROUTES {
    public static root: string = '';
    public static slash: string = '/';

    //* Pre auth Layout */
    public static login: string = 'login';
    public static forgotPassword: string = 'forgot-password';
    public static changePassword: string = 'reset_password';
    public static setSocialPassword: string = 'set_password';
    public static register: string = 'register';
    public static verification: string = 'verification';
    public static aboutUs: string = 'aboutUs';
    public static termsAndConditions: string ='termsAndConditions';
    public static privacyPolicy: string ='privacyPolicy';

    //* post auth Layoyt */
    public static home: string = 'home';
    public static profile: string = 'profile/view';
    public static editProfile: string = 'profile/edit';
    public static resetPassword: string = 'profile/reset-password';
    public static faqList: string = 'faq';
    public static timeline: string = 'timeline';
    public static dashboardHome: string = 'home';
    public static dashboardProducts: string = 'dashboard/product-select';
    public static wishlist: string = 'wishlist';
    

}
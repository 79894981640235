import { AdminLoginLoaderPageComponent } from './components/pre-auth-pages/admin-login-loader-page/admin-login-loader-page.component';
import { TimelineComponent } from './components/post-auth-pages/timeline/timeline.component';
import { ChangePasswordComponent } from './components/pre-auth-pages/forgot-password/change-password/change-password.component';
import { LoginComponent } from './components/pre-auth-pages/login/login.component';
import { Injector, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './components/pre-auth-pages/forgot-password/forgot-password/forgot-password.component';
import { AuthGuard } from './auth/auth.guard';
import { LoggedInUserGuard } from './auth/loggedInUser.guard';
import { FaqComponent } from './components/post-auth-pages/faq/faq.component';
import { ImprintComponent } from './components/pre-auth-pages/informative-pages/imprint/imprint.component';
import { DataPrivacyComponent } from './components/pre-auth-pages/informative-pages/data-privacy/data-privacy.component';
import { CookiesPolicyComponent } from './components/pre-auth-pages/informative-pages/cookies-policy/cookies-policy.component';
import { ContactUsComponent } from './components/post-auth-pages/contact-us/contact-us.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';


const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    pathMatch: 'full',
    canActivate: [LoggedInUserGuard],
    data: [{ title: 'Login' }],
  },

  {
    path: 'faq',
    component: FaqComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: [{ title: 'FAQ' }],
  },

  {
    path: 'contact',
    component: ContactUsComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: [{ title: 'Contact' }],
  },

  {
    path: 'login',
    component: LoginComponent,
    pathMatch: 'full',
    canActivate: [LoggedInUserGuard],
    data: [{ title: 'Login' }],
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    pathMatch: 'full',
    canActivate: [LoggedInUserGuard],
    data: [{ title: 'Forgot-password' }],
  },
  {
    path: 'admin-login-loader',
    component: AdminLoginLoaderPageComponent,
    pathMatch: 'full',
    canActivate: [LoggedInUserGuard],
  },
  {
    path: 'client_reset_password',
    component: ChangePasswordComponent,
    pathMatch: 'full',
    data: [{ title: 'Reset-password' }],
  },
  {
    path: 'cookie-policy',
    component: CookiesPolicyComponent,
    pathMatch: 'full',
    data: [{ title: 'Cookie-policy' }],
  },
  {
    path: 'data-privacy',
    component: DataPrivacyComponent,
    pathMatch: 'full',
    data: [{ title: 'Data-privacy' }],
  },
  {
    path: 'imprint',
    component: ImprintComponent,
    pathMatch: 'full',
    data: [{ title: 'Imprint' }],
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./components/post-auth-pages/profile/profile.module').then(
        (m) => m.ProfileModule
      ),
    data: [{ title: 'Profile' }],
    canActivate: [AuthGuard],
  },
  {
    path: 'timeline',
    component: TimelineComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: [{ title: 'Timeline' }],
  },
  {
    path: 'configurator',
    loadChildren: () =>
      import(
        './components/post-auth-pages/configurator/configurator.module'
      ).then((m) => m.ConfiguratorModule),
    data: [{ title: 'Configurator' }],
    canActivate: [AuthGuard],
  },
  {
    path: 'documents',
    loadChildren: () =>
      import(
        './components/post-auth-pages/documents/documents.module'
      ).then((m) => m.DocumentsModule),
    data: [{ title: 'Documents' }],
    canActivate: [AuthGuard],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./components/post-auth-pages/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
      data: [{ title: 'Home' }],
    canActivate: [AuthGuard],
  },
  {
    path: 'cart-page',
    loadChildren: () =>
      import('./components/post-auth-pages/shopping-cart/shopping-cart.module').then(
        (m) => m.ShoppingCartModule
      ),
    data: [{ title: 'Cart' }],
    canActivate: [AuthGuard],
  },
  {
    path: 'wishlist',
    loadChildren: () =>
      import('./components/post-auth-pages/wishlist/wishlist.module').then(
        (m) => m.WishlistModule
      ),
    data: [{ title: 'Wishlist' }],
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: [{ title: 'Not found' }],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PreAuthPagesService } from '../components/pre-auth-pages/pre-auth-pages.service';
import { LocalStorageService } from '../shared/storage/local-storage.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private ls: LocalStorageService,
    public _cs: CookieService,
    private auth: PreAuthPagesService,
    private toast: ToastrService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: string = this.ls.getItem('token');
    if (token) {
      req = req.clone({ headers: req.headers.set('Authorization', token) });
    }
    req = req.clone({
      headers: req.headers
        .set('Accept', 'application/json')
        .set('app-Lang', this._cs.get('dataLang') || 'de'),
    });

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((err: HttpErrorResponse) => {
        // this.toast.error(err.message)
        if (err?.status === 401 || err?.status === 403) {
          this.auth.logout();
        }
        return throwError(err);
      })
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShoppingCartRoutingModule } from './shopping-cart-routing.module';
import { MainComponent } from './components/main/main.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfigurationConfirmationComponent } from './components/configuration-confirmation/configuration-confirmation.component';
import { ConfigurationSuccessComponent } from './components/configuration-success/configuration-success.component';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [MainComponent, ConfigurationConfirmationComponent, ConfigurationSuccessComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ShoppingCartRoutingModule,
    TranslateModule,
    SharedModule,
    NgxSpinnerModule
  ],
  exports:[ConfigurationConfirmationComponent,ConfigurationSuccessComponent]
})
export class ShoppingCartModule {}

import { TitleCasePipe } from '@angular/common';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DashboardService } from './components/post-auth-pages/dashboard/dashboard.service';
import { TimelineService } from './components/post-auth-pages/timeline/timeline.service';
import { constants } from './shared/helpers/common/global-constants';
import { CommonService } from './shared/service/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [TitleCasePipe],
})
export class AppComponent {
  title = 'vilgertshofer';
  currentIndex = 0;
  imageList: any[] = [
    {
      img: 'assets/img/b1.jpg',
      title: 'BG1',
    },
    {
      img: 'assets/img/b2.jpg',
      title: 'BG2',
    },
    {
      img: 'assets/img/b3.jpg',
      title: 'BG3',
    },
    {
      img: 'assets/img/b4.jpg',
      title: 'BG4',
    },
    {
      img: 'assets/img/b5.jpg',
      title: 'BG5',
    },
  ];
  reqIds: any = {};
  private constants = constants;
  next() {
    if (this.currentIndex < this.imageList.length)
      this.currentIndex = this.currentIndex + 1;

    if (this.currentIndex > this.imageList.length - 1) this.currentIndex = 0;
  }
  prev() {
    if (this.currentIndex === 0) this.currentIndex = 5;

    if (this.currentIndex > 0) this.currentIndex = this.currentIndex - 1;
  }
  activeImage(index: any) {
    this.currentIndex = index;
  }

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private CommonService: CommonService,
    private dashService: DashboardService,
    public service: TimelineService,
  ) {
  }
  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const rt = this.getChild(this.activatedRoute);
        rt.data.subscribe((data: any) => {
          // this.titleService.setTitle(`VilgertShofer | ${this.titlecase.transform(event.url.split('/')[1])}`)
          
         
          
          // if(!this.router.url.startsWith('/home')) {
            let totalCart =
            localStorage.configuredProjectListCount && ((localStorage.getItem('configuredProjectListCount')) || 0);
            this.CommonService.cartTotalLengthEvent$.next(+totalCart || 0);
          // }
          
          // this.titleService.setTitle(`${data?.['0']?.title} | VilgertShofer`);
          if(localStorage.token || sessionStorage.token) this.getUnReadMessages();
        });
      });

    if(localStorage.token || sessionStorage.token ) this.getAllClientProjectList();

  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
  /**
   * get required id's
   */
  fetchRequiredIDForGetRooms(): void {
    if (localStorage.getItem(this.constants.requiredId)) {
      this.reqIds = JSON.parse(localStorage.getItem(this.constants.requiredId));
    }
  }
  /**
   * Implementing new api
   */
  getAllClientProjectList() {
    this.fetchRequiredIDForGetRooms();
    this.dashService.getAllClientProjects().subscribe((data: any) => {
      if (data.status === 200) {
        let newProjectList = [];
        newProjectList = data?.body?.projectTemplateRec;
        newProjectList.forEach((list: any) => {
          list.completed_date = new Date(
            list?.complete_project_date?.replace(/-/g, '/')
          );
        });
        let updatedProjectList = [];
        newProjectList.filter((list: any) => {
          return list?.projectdetail.map((details: any) => {
            updatedProjectList.push({
              progressPercentage:
                (+details.roomAddedWhist / +details.totalRoom) * 100,
              details,
              project: list,
            });
          });
        });
        let configuredProject = updatedProjectList.filter(
          (list) => +list?.details?.cartTotal > 0
        );
        this.fetchRequiredIDForGetRooms();
        if (!this.reqIds?.pId) {
          if (configuredProject?.length) {
            delete this.reqIds.completed_date;
            let queryParams = {
              ...this.reqIds,
              completed_date: configuredProject[0]?.project?.completed_date,
              is_project_completed:configuredProject[0]?.details?.is_completed
            };

            localStorage.setItem(
              this.constants.requiredId,
              JSON.stringify(queryParams)
            );
            localStorage.setItem(
              this.constants.isprojectConfigured,
              JSON.stringify(true)
            );
          } else {
            localStorage.setItem(
              this.constants.isprojectConfigured,
              JSON.stringify(false)
            );
          }
        }
        if (this.reqIds?.roomId) {
          this.fetchRequiredIDForGetRooms();
        }
        if (this.reqIds?.tId) {
          updatedProjectList.filter((item) => {
            if (+item.details.template_id === +this.reqIds?.tId) {
              this.fetchRequiredIDForGetRooms();
              delete this.reqIds.completed_date;
              delete this.reqIds.is_project_completed;
              let queryParams = {
                ...this.reqIds,
                completed_date: item?.project?.completed_date,
                is_project_completed:item?.details?.is_completed
              };
  
              localStorage.setItem(
                this.constants.requiredId,
                JSON.stringify(queryParams)
              );
              localStorage.setItem(
                this.constants.isprojectConfigured,
                JSON.stringify(true)
              );
            }
          });
        }
        updatedProjectList.forEach((n) => delete n?.project?.projectdetail);
      }
    });
  }

  getUnReadMessages(){
    this.dashService.getUnReadMessages().subscribe((data: any) => {
      if(data.status === 200){
        let messageList = data.body.messageList;
        // messageList = messageList?.length && messageList.filter((d)=>{!d?.review_status});
        this.service.messages = messageList?.length
      }
    })
  }
}

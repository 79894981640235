import { environment } from './../../../../../../environments/environment';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { constants } from 'src/app/shared/helpers/common/global-constants';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';
import { DashboardService } from '../../../dashboard/dashboard.service';
import { DocumentsService } from '../../documents.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  submitted = false;
  CategoryList: any = ['Purchase Documents', 'Property Documents'];
  docrecords: any;
  DocId: number;
  isUploadCLick: boolean = false;
  showHide = false;
  base64EncodedStr: any;
  isUploaded: boolean = false;
  imgURL: string | ArrayBuffer;
  newProjectList: any[];
  activeProject: any = null;
  updatedProjectList: any[];
  activeTemplate: any;
  selectedData = 'Building Project 1';
  selectedData2 = '| Semi-detached house | 5';
  private constants = constants;
  pdfFormControl = true;
  pdfUrl: any;
  clientId: number;
  private unsubscribe$ = new Subject();
  form: FormGroup;
  ProjectDocList: any[] = [];
  allFilteredDocs: any[] = [];
  reqIds: any = {};
  docCategoryList: any[] = [];
  imgBaseUrl = environment.imgBaseUrl;
  deletePopup: boolean = false;
  docDeleteObj: any;
  isDocumentExist:boolean = false
  isContentLoaded:boolean = false
  constructor(
    public fb: FormBuilder,
    private lc: Location,
    private http: HttpClient,
    private toastr: ToastrService,
    private ts: TranslateService,
    private doc: DocumentsService,
    private _dashboardService: DashboardService,
    private modalService: NgbModal,
    private _router: Router
  ) {
    this.fetchRequiredIDForGetRooms();
    this.form = this.fb.group({
      pdf: [''],
      doc_name: [
        '',
        [
          Validators.required,
          Validators.maxLength(30),
          this.noWhitespaceValidator,
          Validators.pattern(/^[a-zA-Z0-9_ÄäÖöÜüÆæß,\s]*$/),
        ],
      ],
      cat_id: ['', Validators.required],
    });
  }

  goBack() {
    this.lc.back();
  }
  ngOnInit(): void {
    // this.clientId = JSON.parse(sessionStorage.getItem('currentUser')).id;
    // this.getDoclist();

    this.getAllClientProjectList();
    this.showllistDocCategory();
  }

  getAllClientProjectList(isUpdated?: boolean) {
    this._dashboardService.getAllClientProjects().subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.newProjectList = [];
          this.newProjectList = data?.body?.projectTemplateRec;
          this.fetchRequiredIDForGetRooms();
          // let activeProject: any[] = this.newProjectList.filter(
          //   (p: any) => p?.id === this.reqIds?.pId
          // );
          // this.activeProject = activeProject[0]?.id;
          // this.templateList = activeProject[0]?.projectdetail;
          // let activeTemplate: any[] = this.templateList.filter(
          //   (t: any) => t?.template_id === this.reqIds?.tId
          // );
          // this.activeTemplate = activeTemplate[0]?.template_id;
          this.updatedProjectList = [];
          this.newProjectList.filter((list: any) => {
            return list?.projectdetail.map((details: any) => {
              this.updatedProjectList.push({
                progressPercentage:
                  (+details.roomAddedWhist / +details.totalRoom) * 100,
                details,
                project: list,
              });
            });
          });
          this.updatedProjectList.forEach(
            (n) => delete n?.project?.projectdetail
          );
          setTimeout(() => {
            let activeProject: any[] =
              this.reqIds?.pId && this.reqIds?.tId
                ? this.getActiveProject()
                : [this.updatedProjectList[0]];
            this.activeProject = activeProject[0];
            this.selectedData = this.activeProject?.project?.name;

            this.selectedData2 = ` | ${this.activeProject?.details?.Template?.name} | ${this.activeProject?.details?.apartment_name}`;

            this.activeTemplate = this.activeProject?.details?.template_id;
          }, 200);
          this.getDoclist();
        }
      },
      (err) => {
        this.toastr.error(err.error.message, '');
      }
    );
  }

  getActiveProject() {
    if (!this.activeProject?.project?.id) {
      return this.updatedProjectList.filter(
        (p: any) =>
          p?.project?.id === this.reqIds?.pId &&
          p?.details?.template_id === this.reqIds?.tId
      );
    } else {
      return this.updatedProjectList.filter(
        (p: any) =>
          p?.project?.id === this.activeProject?.project?.id &&
          p?.details?.template_id === this.activeProject?.details?.template_id
      );
    }
  }

  OnClickupdatedata(p: any, pi: number) {
    this.isContentLoaded = false;
    this.isDocumentExist = false;
    this.selectedData = p?.project?.name;
    this.selectedData2 = ` | ${p?.details?.Template?.name} | ${p?.details?.apartment_name}`;
    this.activeProject = p;
    let queryParams = {
      ...this.reqIds,
      completed_date: new Date(
        this.activeProject?.project?.complete_project_date?.replace(/-/g, "/")
      ),
      // is_project_completed:this.activeProject?.details?.is_completed,
    };
    localStorage.setItem(
      this.constants.requiredId,
      JSON.stringify(queryParams)
    );
    this.fetchRequiredIDForGetRooms();
    this.getDoclist();
  }

  pdfClose() {
    this.pdfFormControl = false;
  }

  uploadDoc(event: any): void {
    this.pdfFormControl = true;
    this.isUploadCLick = true;
    this.isUploaded = false;
    const promise = new Promise((resolve, reject) => {
      if (event.target.files && event.target.files[0]) {
        const contentType = event.target.files[0].type;
        const size = event.target.files[0].size;
        const mb = Math.round(size / 1024);
        if (mb > 15360) {
          this.isUploaded = false;
          this.toastr.error(this.ts.instant('documentspage.desc2'), '');
          reject(this.ts.instant('documentspage.pleaseselctfilelessthan15mb'));
        }
        const file = event.target.files[0];
        const extension = file.name.split('.').pop();
        if (!this.isFilePdf(file)) {
          this.isUploaded = false;
          this.toastr.error(this.ts.instant('documentspage.desc3'), '');
          return;
        }
        const reader = new FileReader();
        reader.onload = (): void => {
          this.imgURL = reader.result;
          let payload = {
            file,
            contentType,
          };
          resolve(payload);
        };
        reader.readAsDataURL(file);
      } else {
        reject('Please select a file');
      }
    });
    promise
      .then((resp) => {
        this.uploadDocApi(resp);
      })
      .catch((err) => {
        this.toastr.error(err, '');
      });
    this.changeShowStatus();
  }

  isFilePdf(file: any) {
    let type = file['type']?.toLowerCase();
    return (
      (file && type?.includes('pdf')) ||
      type?.includes('jpeg') ||
      type?.includes('png') ||
      type?.includes('jpg')
    );
  }
  uploadDocApi(result: any): void {
    let input = new FormData();
    input.append('file', result.file);

    // let payload = {
    //   file: input,
    // };

    this.doc
      .uplaodDocPdf(input)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp1: any) => {
          this.form.patchValue({
            pdf: resp1.body.filename,
          });
          this.isUploaded = true;
        },
        (err) => {
          this.toastr.error(
            this.ts.instant('documentspage.somethingwentwrongwithpdfuploaded'),
            ''
          );
        }
      );
  }

  shareDoc(data: any) {
    this.doc.shareDocument$.next(data);
    setTimeout(() => {
      this._router.navigate(['/timeline']);
    }, 700);
  }

  downloadDoc(data: any) {
    let docName = data?.document;
    let blob = environment.imgBaseUrl + docName;
    let wert = this.getFileFromUrl(blob, docName);
    wert.then((res) => {
      var reader = new FileReader();
      reader.readAsDataURL(res);
      reader.onload = function () {
        let fileData: any = reader.result;
        const link = document.createElement('a');
        link.setAttribute('download', '');
        link.setAttribute('target', '_blank');
        link.setAttribute('href', fileData);
        document.body.appendChild(link);
        link.click();
        link.remove();
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    });
  }

  async getFileFromUrl(url, name, defaultType = 'image/jpeg') {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }

  uploadProjectDoc() {
    this.submitted = true;

    let data = this.form.value;

    if (data?.pdf) {
      if (this.form.valid) {
        let payload = {
          docfile: data.pdf,
          doc_name: data.doc_name,
          cat_id: data.cat_id,
          project_id: this.activeProject?.project?.id || this.reqIds?.pId,
          template_id:
            this.activeProject?.details?.template_id || this.reqIds?.tId,
          userid: this.doc.currentUser?.id,
        };

        this.doc.addProjectDoc(payload).subscribe((res) => {
          if (res) {
            document.getElementById('closeModal').click();
            this.modalService.dismissAll();
            this.toastr.success(
              this.ts.instant('documentspage.addedsuccessfully'),
              '',
              { timeOut: 5000 }
            );
            this.getDoclist();
            this.form.reset();
            this.form.controls['cat_id'].patchValue('');
            this.showHide = false;
            this.submitted = false;
            this.updateInput();
          } else {
            this.toastr.error(
              this.ts.instant('documentspage.pleaseadddocuments'),
              ''
            );
          }
          // this.form.patchValue({
          //   pdf: res.body.filename,
          // });
        });
      } else {
        this.form.markAllAsTouched();
      }
    } else {
      this.toastr.error(
        this.ts.instant('documentspage.pleaseadddocuments'),
        '',
        { timeOut: 5000 }
      );
    }
  }

  showllistDocCategory() {
    let payload = {
      search: '',
      page_start: 1,
      page_limit: 100,
    };
    this.doc.listDocCategory(payload).subscribe((data) => {
      if (data?.body?.status) {
        this.docCategoryList = [];
        this.docCategoryList = data?.body?.catalogue;
      }
    });
  }

  getDoclist() {
    let payload = {
      userid: this.doc.currentUser?.id,
      project_id: this.activeProject?.project?.id || this.reqIds?.pId,
      template_id: this.activeProject?.details?.template_id || this.reqIds?.tId,
      // template_id: this.reqIds?.tId,
      // project_id: this.reqIds?.pId,
    };
    this.doc.templateWiseDocs(payload).subscribe((data) => {
      this.ProjectDocList = data.body.docmunets;
      this.allFilteredDocs = [];
      let flrec: any = [];
      // this.ProjectDocList.reverse();
      this.isContentLoaded = true;
      this.ProjectDocList.forEach((list) => {
        list?.ClientDocuments?.sort(function (a, b) {
          return (
            new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
          );
        });
        list['createdAtlatest'] = list?.ClientDocuments[0]?.createdAt;
        list?.ClientDocuments?.forEach((x) => {
          this.isDocumentExist = true
          let obj = {
            cat: list?.title,
            ...x,
          };
          flrec.push(obj);
        });
      });
      this.ProjectDocList.sort(function (a, b) {
        return (
          // new Dateb?.updatedAt ? b?.updatedAt : b?.createdAt).getTime() -
          // new Date(a?.updatedAt ? a?.updatedAt : a?.createdAt).getTime()
          new Date(b?.createdAtlatest).getTime() -
          new Date(a?.createdAtlatest).getTime()
        );
      });

      flrec.sort(function (a, b) {
        return (
          new Date(b?.createdAtlatest).getTime() -
          new Date(a?.createdAtlatest).getTime()
        );
      });
      this.allFilteredDocs = flrec;
    });
  }

  getDoclistById(obj: any) {
    this.DocId = obj.id;
    this.showHide = false;
    let payload = {
      userid: this.doc.currentUser?.id,
      id: obj.id,
      project_id: this.activeProject?.project?.id || this.reqIds?.pId,
      template_id: this.activeProject?.details?.template_id || this.reqIds?.tId,
    };

    this.doc.getTempDocByID(payload).subscribe((res) => {
      this.docrecords = res?.body?.docmunets;
      this.createForm(this.docrecords);
    });
    // this.form.patchValue({
    //   doc_name: this.docrecords.name,
    //   pdf: this.docrecords.document,
    // });
  }
  createForm(data: any) {
    this.form.patchValue({
      pdf: data.document,
      doc_name: data.name,
      cat_id: data.catid,
    });
  }

  addNewDoc() {
    this.submitted = false;
    this.form.reset();
    this.form.controls['cat_id'].patchValue('');
    this.showHide = false;
  }

  updatedoc() {
    let data = this.form.value;
    if (data.pdf) {
      if (this.form.valid) {

        let payload = {
          id: this.DocId,
          docfile: data.pdf,
          doc_name: data.doc_name,
          cat_id: data.cat_id,
          userid: this.doc.currentUser?.id,
          project_id: this.activeProject?.project?.id || this.reqIds?.pId,
          template_id:
            this.activeProject?.details?.template_id || this.reqIds?.tId,
        };
        this.doc.updateDocByID(payload).subscribe((res) => {
          document.getElementById('closeModaledit').click();
          this.modalService.dismissAll();
          this.getDoclist();
          this.form.reset();
          this.form.controls['cat_id'].patchValue('');
          this.updateInput();
          this.showHide = false;
        });

      } else {
        this.form.markAllAsTouched();
      }
    } else {
      this.toastr.error('Please select document');
    }
  }

  updateInput() {
    let inputids = document.getElementsByTagName('input').length;
    for (let index = 0; index < inputids; index++) {
      const element = document.getElementsByTagName('input').item(index);
      if (element.id === 'file-upload') {
        element.value = '';
      }
    }
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace =
      ((control && control.value && control.value.toString()) || '').trim()
        .length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  changeCity(e: any) {
    this.catId?.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  get catId() {
    return this.form.get('catId');
  }
  fetchRequiredIDForGetRooms(): void {
    if (localStorage.getItem(this.constants.requiredId)) {
      this.reqIds = JSON.parse(localStorage.getItem(this.constants.requiredId));
    }
  }
  changeShowStatus() {
    this.showHide = true;
  }
  DeleteDoc(obj: any) {
    this.docDeleteObj = obj;
    this.deletePopup = true;
  }
  hideDeletepopup() {
    this.deletePopup = false;
  }
  deleteDocs() {
    let obj = this.docDeleteObj;
    let payload = {
      id: obj.id,
      userid: this.doc.currentUser?.id,
      project_id: this.activeProject?.project?.id || this.reqIds?.pId,
      template_id: this.activeProject?.details?.template_id || this.reqIds?.tId,
    };

    this.doc.DeleteDoc(payload).subscribe((res) => {
      this.hideDeletepopup();
      this.getDoclist();
    });
  }
}

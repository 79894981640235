import { environment } from './../../../../environments/environment';
import { LocalStorageService } from 'src/app/shared/storage/local-storage.service';
import { Component, OnInit } from '@angular/core';
import { TimelineService } from './timeline.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DocumentsService } from '../documents/documents.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
})
export class TimelineComponent implements OnInit {
  private unsubscribe$ = new Subject();
  currentUser: any;
  senderId: any;
  recieverId: any;
  docUrl: any;
  docName: any;
  docList: any;
  msgHistory: any;
  clientInfo: any;
  imgBaseUrl = environment.imgBaseUrl;
  msgInput:any=''
  messageSenderId:any;
  form = this.fb.group({
    sender_id: ['', [Validators.required]],
    receiver_id: ['', [Validators.required]],
    message: ['', [Validators.maxLength(300)]],
    document: [''],
    type: ['', [Validators.required, Validators.maxLength(10)]],
    sender_type: ['', [Validators.required, Validators.maxLength(10)]],
    clientdocumentid: [0],
    lang_type: ['']
  });

  constructor(
    private localStorage: LocalStorageService,
    private service: TimelineService,
    public fb: FormBuilder,
    private doc: DocumentsService,
    private toastr: ToastrService,
    private ts: TranslateService,
    private cookies: CookieService
  ) {

  }

  ngOnInit(): void { }
  ngAfterViewInit() {
    this.initSenderAndRecieverId();
    this.getMessageHistory();
    this.getUploadedDocList();
    // this.doc.shareDocument$.subscribe((doc) => {
    //   if (doc) {
    //     // this.form.controls['clientdocumentid'].patchValue(doc?.id);
    //     // this.sendMessageOrDoc('doc', '');
    //   }
    // });
  }

  initSenderAndRecieverId() {
    this.currentUser = this.localStorage.getItem('currentUser');
    this.senderId = this.currentUser.id;
    this.recieverId = this.currentUser.create_by;
    this.prepForm();
  }

  prepForm() {
    this.form.patchValue({
      sender_id: this.senderId,
      receiver_id: this.recieverId,
      sender_type: 'client',
    });
  }

  downloadDoc(data: any) {
    let docName = data?.document;
    let blob = environment.imgBaseUrl + docName;
    let wert = this.getFileFromUrl(blob, docName);
    wert.then((res) => {
      var reader = new FileReader();
      reader.readAsDataURL(res);
      reader.onload = function () {
        let fileData: any = reader.result;
        const link = document.createElement('a');
        link.setAttribute('download', docName);
        link.setAttribute('target', '_blank');
        link.setAttribute('href', fileData);
        document.body.appendChild(link);
        link.click();
        link.remove();
      };
      reader.onerror = function (error) {
      };
    });
  }

  async getFileFromUrl(url, name, defaultType = 'image/jpeg') {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type || defaultType,
    });
  }

  sendMessageOrDoc(type?: any, input?: any) {
    this.form.patchValue({ lang_type: this.ts.currentLang })
    if (type === 'msg') {
      if (input.value.trim().length == 0) {
        this.toastr.error('Whitespace not accepted', '');
        return;
      }
      this.form.patchValue({
        message: input.value,
        type: 'message',
      });
    } else if (type === 'doc') {
      this.form.patchValue({
        message: input,
        type: 'document',
      });
    }

    this.service.sendMessage(this.form.value).subscribe(
      (data: any) => {
        if (data.status === 200) {
          if (type === 'msg') {
            input.value = '';
            this.toastr.success(
              this.ts.instant('timeline.messageSentMsgTsFile'),
              ''
            );
          } else if (type === 'doc') {
            this.toastr.success(
              this.ts.instant('timeline.documentUploadedMsgTsFile'),
              ''
            );
          }
          this.clearForm();
          this.getMessageHistory();
          this.getUploadedDocList();
        }
      },
      (err) => {
        this.toastr.error(err.error.message, '');
      }
    );
  }

  clearForm() {
    this.form.patchValue({
      message: '',
      document: '',
      type: '',
    });
    this.docUrl = '';
    this.docName = '';
    this.msgInput = '';
  }

  //  formatBytes(bytes) {
  //   if (!+bytes) return '0 Bytes'
  //   const k = 1024
  //   const dm = 0
  //   const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  //   const i = Math.floor(Math.log(bytes) / Math.log(k))
  //   return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  // }

  getMessageHistory() {
    let payload = {
      type: 'message',
      client_id: this.senderId,
    };

    this.service.getMessageHistory(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.clientInfo = data.body.clientRec;
          this.msgHistory = data.body.messageRec;
          // console.info(this.formatBytes(new Blob([JSON.stringify(this.msgHistory)]).size))
          // console.log(this.msgHistory, 'filter---');
          this.messageSenderId = +this.service.client_id === +this.msgHistory[0]?.receiver_id ? +this.msgHistory[0]?.sender_id : +this.msgHistory[0]?.receiver_id;
          setTimeout(() => {
            this.getSmoothScroll('islast');
            this.isMessageRead();
          }, 300);
        }
      },
      (err) => {
        this.toastr.error(err.error.message, '');
      }
    );
  }

  isMessageRead() {
    let payload = {
      id: this.messageSenderId
    }
    this.service.isMessageRead(payload).subscribe((data)=>{
      if(data.status === 200){
        this.service.messages = 0;
      }
    });  
  }

  getSmoothScroll(id: string) {
    document.getElementById(id)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  }
  getUploadedDocList() {
    let payload = {
      type: 'document',
      client_id: this.senderId,
    };

    this.service.getMessageHistory(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.docList = data.body.messageRec;
        }
      },
      (err) => {
        this.toastr.error(err.error.message, '');
      }
    );
  }

  uploadDoc(event: any): void {
    const promise = new Promise((resolve, reject) => {
      if (event.target.files && event.target.files[0]) {
        const contentType = event.target.files[0].type;
        const size = event.target.files[0].size;
        if (Number((size / 1048576).toFixed(1)) > 5) {
          reject('Please select file less than 5 mb');
        }
        const file = event.target.files[0];
        const extension = file.name.split('.').pop();

        const reader = new FileReader();
        reader.onload = (): void => {
          // this.imgURL = reader.result;
          let payload = {
            file,
            contentType,
          };
          resolve(payload);
        };
        reader.readAsDataURL(file);
      } else {
        reject('Please select a file');
      }
    });
    promise
      .then((resp) => {
        this.uploadDocApi(resp);
      })
      .catch((err) => {
        this.toastr.error(err, '');
      });
  }

  uploadDocApi(result: any): void {
    let input = new FormData();
    input.append('id', result.file);

    this.service
      .uploadDoc(input)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp1: any) => {
          this.docUrl = resp1.body.imageUrl;
          this.docName = resp1.body.filename;

          this.form.patchValue({
            document: resp1.body.filename,
          });

          this.sendMessageOrDoc('doc', this.docName);
          this.getUploadedDocList();
        },
        (err) => {
          this.toastr.error(
            this.ts.instant('timeline.Somethingwentwrongwithimageupload'),
            ''
          );
        }
      );
  }

  downloadMyFile(docName: any) {
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', environment.imgBaseUrl + docName);
    link.setAttribute('download', docName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  onDown(type: string, fromRemote: boolean) {
    this.service.onDown(type, fromRemote);
  }

  getDocName(value:string):string{
    if(value?.lastIndexOf("_") > 0){
      const lastIndex = value?.lastIndexOf("_");
      const startingStr = value?.substring(0, lastIndex);
      const lastValue = value?.substring(lastIndex + 1)?.split('.')[1];
      return `${startingStr}.${lastValue}`
    }else{
      return value
    }
  }
}

<div class="property-wrapper d-md-flex d-block">
  <div class="img-wrapper col-md-6 col-12 position-relative">
    <img
      [src]="
        singleProject?.details?.image
          ? imgBaseUrl + singleProject?.details?.image
          : 'assets/img/placeholder.png'
      "
      class="img-fluid"
    />
    <span
      class="change"
      [routerLink]="['/home']"
      [queryParams]="{ configure: true }"
      >{{ "configpage.changeproject" | translate }}</span
    >
  </div>
  <div class="property-details col-md-6 col-12">
    <h3 class="d-flex justify-content-between align-items-start m-0">
      <span class="property-name" title="{{ singleProject?.details?.name }}">{{ singleProject?.details?.name }}</span>
      <span
        class="progressbar"
        [class.progressbar-positive]="
          singleProject?.details?.progressPercentage === 100
        "
        >{{
          +singleProject?.details?.progressPercentage | number : "1.0-0"
        }}%</span
      >
    </h3>
    <p title="{{ singleProject?.details?.location }}">{{ singleProject?.details?.location }}</p>
    <div class="floors">
      <span class="me-2" style="max-width: 60%;" title="{{ reqIds?.template_name }}">{{ reqIds?.template_name }}</span>
      <span style="max-width: 40%;" title="{{ reqIds?.apartment_name }}">{{ reqIds?.apartment_name }}</span>
    </div>
    <button
      type="button"
      class="btn btn-main mt-1 w-100"
      routerLink="/documents"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        class="me-2"
        height="16"
        viewBox="0 0 12 16"
      >
        <path
          id="icon_document_white"
          data-name="icon document white"
          d="M14,4.5V14a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V2A2,2,0,0,1,4,0H9.5Zm-3,0A1.5,1.5,0,0,1,9.5,3V1H4A1,1,0,0,0,3,2V14a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V4.5Z"
          transform="translate(-2)"
          fill="#fff"
        />
      </svg>
      {{ "configpage.documents" | translate }}
    </button>
  </div>
</div>

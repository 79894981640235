<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>

<!-- <div class="carousel">
    <div class="indicators-wrapper">
        <button type="button" (click)="prev()">
            <svg xmlns="http://www.w3.org/2000/svg" width="10.771" height="20" viewBox="0 0 10.771 20">
                <path id="chevron-left-slider"
                    d="M10.955,4.725a.769.769,0,0,1,1.089,0l9.23,9.23a.77.77,0,0,1-1.089,1.089L11.5,6.357,2.814,15.044a.77.77,0,1,1-1.089-1.089Z"
                    transform="translate(-4.499 21.499) rotate(-90)" fill="#213e62" fill-rule="evenodd" />
            </svg>
        </button>

        <div class="carousel-indicators">
            <ng-container *ngFor="let item of imageList; let i = index; let first = first; let last = last">
                <button id="auto_trigger" type="button" [class.active]="i === currentIndex" (click)="activeImage(i)">
                    <img src="{{item?.img}}" class="img-fluid" height="100px" width="100px" alt="..." />
                </button>
            </ng-container>

        </div>

        <button type="button" (click)="next()">
            <svg xmlns="http://www.w3.org/2000/svg" width="10.771" height="20" viewBox="0 0 10.771 20">
                <path id="chevron-right-slider"
                    d="M1.725,4.725a.769.769,0,0,1,1.089,0L11.5,13.412l8.685-8.687a.77.77,0,0,1,1.089,1.089l-9.23,9.23a.769.769,0,0,1-1.089,0l-9.23-9.23a.769.769,0,0,1,0-1.089Z"
                    transform="translate(-4.499 21.499) rotate(-90)" fill="#213e62" fill-rule="evenodd" />
            </svg>
        </button>
    </div>

    <div class="carousel-inner">
        <ng-container *ngFor="let item of imageList; let i = index; let first = first; let last = last">
            <div class="carousel-item" [class.active]="i === currentIndex">
                <img src="{{item?.img}}" class="d-block w-100" alt="..." />
                <div class="carousel-caption">
                    <div class="container d-flex">
                        <div class="caption-wrapper" style="background: none">
                            <h1 class="caption-heading">
                                {{item?.title}}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div> -->
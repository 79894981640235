import { Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { constants } from 'src/app/shared/helpers/common/global-constants';
import {
  NavigationEnd,
  ActivatedRouteSnapshot,
  Router,
  ActivatedRoute,
} from '@angular/router';
import { filter, map } from 'rxjs/operators';
type Position = 'center' | 'start' | 'end';
@Injectable({
  providedIn: 'root',
})
export class ConfiguratorService {
  imgBaseUrl: string = environment.imgBaseUrl;
  private constants = constants;
  activeRoomId$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  showcartitems$: Subject<any> = new Subject<any>();
  isCompletedProjectChanged$: Subject<any> = new Subject<any>();
  removeCartItems$: Subject<any> = new Subject<any>();
  deleteitem: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  currentDate = new Date();
  currentRoomDetail$: Subject<any> = new Subject<any>();
  currentSelectedRoomObserver$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  ShowItemsOnOtherCartPages$: Subject<any> = new Subject<any>();
  roomListObserver$: Subject<any> = new Subject<any>();
  isShowingPercentageProgressObserver$: Subject<any> = new Subject<any>();
  totalCostRoom: number = 0;
  costodAllRooms: number = 0;
  isRoomConfiguredRealTime: number = 0;
  roomName: string = '';
  applyItemSync$: Subject<any> = new Subject<any>();
  isPdfDownloading:boolean = false;
  changeRoom: Subject<any> = new Subject<any>();
  roomConfigure:boolean=false;
  roomId:any;
  public get isroomId() : any {
    return this.roomId
  }
  public set isroomId(v : any) {
    this.roomId = v;
  }


  public get isRoomConfigured() : boolean {
    return this.roomConfigure
  }
  public set isRoomConfigured(v : boolean) {
    this.roomConfigure = v;
  }

  roomList:any=[];
  
  public get isRoomListConfig() : any[] {
    return this.roomList
  }
  public set isRoomListConfig(v : any[]) {
    this.roomList = v;
  }
  
  
  constructor(
    private http: HttpClient,
    private fb: FormBuilder,
    private _router: Router,
    private route: ActivatedRoute
  ) {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map((route: ActivatedRouteSnapshot) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        this.totalCostRoom = 0;
      });
  }
  fetchLocalData(): Object {
    if (localStorage.getItem(this.constants.requiredId)) {
      let ids = JSON.parse(localStorage.getItem(this.constants.requiredId));
      return ids;
    } else {
      return {};
    }
  }
  /**
   * Return booleans
   */
  get isProjectDateExpired(): boolean {
    let ids: any = this.fetchLocalData();
    let projectDate = new Date(ids?.completed_date);
    return this.currentDate.getTime() > projectDate.getTime() ? true : false;
  }
  get isRoomListExists(): boolean {
    return localStorage.getItem(this.constants.roomList) ? true : false;
  }

  get roomData(): Array<any> {
    return JSON.parse(localStorage.getItem(this.constants.roomList));
  }
  get client_id(): any {
    if(localStorage.currentUser){
      return JSON.parse(localStorage.getItem('currentUser'))?.id || null;
    }
    if(sessionStorage.currentUser){
      return JSON.parse(sessionStorage.getItem('currentUser'))?.id || null;
    }
  }
  get isConfiguredProjectCompleted():boolean{
    if (localStorage.getItem(this.constants.requiredId)) {
      return JSON.parse(localStorage.getItem(this.constants.requiredId))?.is_project_completed;
    }else{
      return false;
    }
  }

  /**
   * Return booleans ends
   */

  public getClientProjectList(): Observable<any> {
    const url: string = ApiRoutes.getClientProjectList;
    return this.http.get(url, { observe: 'response' });
  }

  public getTemplateAndUnitsList(payload: any): Observable<any> {
    const url: string = ApiRoutes.getTemplateAndUnitsList;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getRooms(payload: any): Observable<any> {
    const url: string = ApiRoutes.getRooms;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getProductList(payload: any): Observable<any> {
    const url: string = ApiRoutes.getProductList;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getProductDetails(payload: any): Observable<any> {
    const url: string = ApiRoutes.getProductDetails;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public addProductToWishlist(payload: any): Observable<any> {
    const url: string = ApiRoutes.addProductToWishlist;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getTemplateDetails(payload: any): Observable<any> {
    const url: string = ApiRoutes.templateDetails;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getAllClientProjects(): Observable<any> {
    const url: string = ApiRoutes.getAllClientProjects;
    return this.http.get(url, { observe: 'response' });
  }

  public getWishlistItems(body): Observable<any> {
    const url: string = ApiRoutes.getWishlistItems;
    return this.http.post(url, body);
  }


  public getwishlistitembytempId(body): Observable<any> {
    const url: string = ApiRoutes.getwishlistitembytempId;
    return this.http.post(url, body);
  }
  public getProfile(): Observable<any> {
    const url: string = ApiRoutes.getProfile;
    return this.http.get(url, { observe: 'response' });
  }

  /**
   * common methods
   */

  formIntialState(form: FormGroup) {
    form = this.fb.group({
      categories: this.fb.array([]),
    });
  }

  resetCategories(category: FormArray) {
    category.clear();
  }

  scrollIntoView(htmlElementId: string, position: Position): void {
    document.getElementById(htmlElementId)?.scrollIntoView({
      behavior: 'smooth',
      block: position,
      inline: position,
    });
  }

  getClientDetails(): any {
    let clientDetails = JSON.parse(
      localStorage.getItem('currentUser') ||
        sessionStorage.getItem('currentUser')
    );
    let val = JSON.parse(
      localStorage.getItem('adminSpecialLogin') ||
        sessionStorage.getItem('adminSpecialLogin')
    );
    let userType;
    let addedBy;
    if (val === true) {
      userType = 'admin';
      addedBy = JSON.parse(
        localStorage.getItem('adminId') || sessionStorage.getItem('adminId')
      );
    } else {
      userType = 'client';
      addedBy = clientDetails.id;
    }
    return { clientDetails, userType, addedBy };
  }

  prepForm(catergoryFormArray: FormArray, productList: any, dimensions?: any) {
    // console.log(productList,'prod list prepform');
    
    for (var val of productList) {
      this.addUnitArray(catergoryFormArray, val, dimensions);
    }
    
  }

  addUnitArray(catergoryFormArray: FormArray, obj: any, dimensions: any) {
    catergoryFormArray.push(this.newUnitArray(obj, dimensions));
    // console.log(catergoryFormArray,'cat array--------------');
    
  }

  getprice(product): number {
    return (
      +product?.templateAreaPrice ||
      +product?.runningMeterAreaPrice ||
      +product?.templateAreaWallPrice
    );
  }
  formatPrice(price: number): string {
    return price.toFixed(2);
  }

  newUnitArray(obj: any, dimensions: any): FormGroup {
    // console.log(obj,'object data 224');
    
    let newForm = this.fb.group({
      cat_id: [obj?.product_list[0]?.Catalogue.id],
      // cat_id: [obj?.cat_id],
      prod_cat_id: [+obj?.cat_id],
      cat_qty: [+obj?.qty],
      cat_level:[+obj?.cat_level],
      cat_total_qty: [+obj?.cat_total_qty],
      is_cat_limit_exceeded: [false],
      cat_name: [
        obj?.cat_name,
        [Validators.required, Validators.maxLength(30)],
      ],
      prodList: this.fb.array([]),
      children: [obj?.children ? obj.children : []],
      added_cat: [obj?.added_cat],
      total_cat: [obj?.total_cat]
    });
    let productList = newForm.get('prodList') as FormArray;

    // console.log(newForm,'form data config line 239');
    
    for (var prodr of obj?.product_list) {
      let prod = prodr.Product;
      if (prod?.ProductFeatures?.length > 0) {
        let fullStr: number = 0;
        prod.ProductFeatures?.every((element, index) => {
          element.isSelected = false;
          fullStr += +(
            String(element?.CatalogueFeature?.label_name).length +
            (String(element?.price).length + 2)
          );
          if (fullStr > this.constants.show_feature_limit) {
            element.show_limit = index - 1;
            element.show_more = true;
            return false;
          } else {
            element.initial = true;
            element.show_more = false;
            return true;
          }
        });
        let quantityArray = [];
        // for (let i = 0; i < prod.quantity; i++) {
        //   quantityArray = [...quantityArray, i + 1];
        // }
        let drop_arr = [];
        prod.prod_drop_feature = [];
        let dropFeatArr = [];
        let all_drop_items = prod.ProductFeatures?.filter(item=> item?.control_type === 'dropDown');
        // console.log(all_drop_items,'all drop 269');
        all_drop_items = all_drop_items.filter(item=> ((+item?.CatalogueFeature?.catalogue_id)===(+obj?.cat_id)))
        // console.log(all_drop_items,'all drop 271',+obj?.cat_id);
        drop_arr = all_drop_items?.reduce((prev, t, index, arr) => {
          if (typeof prev[t.feature_id] === 'undefined') {
            prev[t.feature_id] = [];
          }
          arr['is_feature_selected'] = false;
          prev[t.feature_id].push(t);
          return prev;
        }, {});
        
        // console.log(drop_arr, 'drop_arr +', prod?.id);
        
        
        Object.keys(drop_arr)?.forEach(i => {
          let obj = {
             selected_drop_feature: '',
            is_outer_selected:false,
            feat_list:drop_arr[i]
          }
          dropFeatArr.push(obj);
          
        });
        prod.prod_drop_feature = this.featurePriceForDropdownTypeCalculation(dropFeatArr,prod)||[];
        // prod.prod_drop_feature = prod.prod_drop_feature.filter(item=> (+obj?.cat_id)===(+item?.CatalogueFeature?.catalogue_id))
        // console.log(prod.prod_drop_feature,'data 293----------');
        
        // console.log(prod?.prod_drop_feature, 'same ids proof eature ' + prod?.id);
        // console.log(prod?.ProductFeatures.filter(x=> x?.control_type ==='input'), 'input feat');
        
        productList.push(
          this.fb.group({
            prod_drop_feature:[prod?.prod_drop_feature ||[]],
            product_id: [
              prod.id,
              [Validators.required, Validators.maxLength(30)],
            ],
            product_name: [
              prod.product_name,
              [Validators.required, Validators.maxLength(30)],
            ],
            product_description: [prod.description, [Validators.required]],
            product_price: [
              prod.price,
              // prod?.type === 'variant' ? (prod.price == 0 ) ? prod.price = 1 : prod.price : prod.price,
              [Validators.required, Validators.maxLength(30)],
            ],
            productOriginalPrice: [
              prod?.type === 'flat' ? prod.price : this.getprice(prod) || 0,
            ],
            product_image: [prod.image ? prod.image.split(',') : '', []],
            category_id: [
              prodr.catalogue_id,
              [Validators.required, Validators.maxLength(30)],
            ],
            category_name: [obj.cat_name],
            selected_feature_id: [
              '',
              [Validators.required, Validators.maxLength(30)],
            ],
            feature_id: ['', [Validators.required, Validators.maxLength(30)]],
            product_feature_id: [
              '',
              [Validators.required, Validators.maxLength(30)],
            ],
            feature_controlValue_value: [
              '',
              [Validators.required, Validators.maxLength(30)],
            ],
            feature_controlValue_price: [
              '',
              [Validators.required, Validators.maxLength(30)],
            ],
            feature_price: [
              '',
              [Validators.required, Validators.maxLength(30)],
            ],
            is_featrue_optional:[!prod?.is_extra],
            feature_data: [''],
            template_area_id: [dimensions?.id || null],
            template_area: [dimensions?.template_area || null],
            template_area_type: [dimensions?.template_area_type || null],
            template_type_id: [dimensions?.template_type_id || null],
            template_id: [dimensions?.template_id || null],
            selected: [false, []],
            wishlistProductadded: [prod.wishlistProductadded],
            type: [prod.type],
            templateAreaPrice: [prod.templateAreaPrice],
            runningMeterAreaPrice: [prod.runningMeterAreaPrice],
            templateAreaWallPrice: [prod.templateAreaWallPrice],
            template_area_wall: [prod.price_wall],
            running_meter_area: [dimensions.running_meter_area],
            admin_id: [this.getClientDetails()?.clientDetails.create_by],
            userType: [this.getClientDetails()?.userType],
            addedBy: [this.getClientDetails()?.addedBy],
            productWishlistRec: [prod.productWishlistRec],
            quantity: [0],
            show_quantity: [quantityArray.length > 0],
            quantity_list: [quantityArray],
            selectedQuantity: [],
            total_area_price: [0],
            product_quantity: [+prod.quantity],
            product_breadcrum: [prod?.breadchrum],
            product_features: [this.featurePriceForInputTypeCalculation(prod?.ProductFeatures,prod).filter(item=>(+obj?.cat_id)===(+item?.CatalogueFeature?.catalogue_id) )],
            isProductDisabled: [this.isProjectDateExpired ? true : false],
            isAddQuantityDisabled: [this.isProjectDateExpired ? true : false],
            isOutOfStock: [
              prod?.type !== 'variant'
                ? +prod?.quantity <= 0
                  ? true
                  : false
                : false,
            ],
            isSurfaceType: [prod?.type === 'variant' ? true : false],
            combineTempshowPrice:[]
          })
        );
      } else {
        let quantityArray = [];
        // for (let i = 0; i < prod.quantity; i++) {
        //   quantityArray = [...quantityArray, i + 1];
        // }
        productList.push(
          this.fb.group({
            prod_drop_feature:[prod?.prod_drop_feature ||[]],
            product_id: [
              prod.id,
              [Validators.required, Validators.maxLength(30)],
            ],
            product_name: [
              prod.product_name,
              [Validators.required, Validators.maxLength(30)],
            ],
            product_description: [prod.description, [Validators.required]],
            product_price: [
              prod.price,
              // prod?.type === 'variant' ? prod.price == 0 ? prod.price = 1 : prod.price : prod.price,
              [Validators.required, Validators.maxLength(30)],
            ],
            productOriginalPrice: [
              prod?.type === 'flat' ? prod.price : this.getprice(prod) || 0,
            ],
            product_image: [prod.image ? prod.image.split(',') : '', []],
            category_id: [
              prodr.catalogue_id,
              [Validators.required, Validators.maxLength(30)],
            ],
            category_name: [obj.cat_name],
            feature_id: ['', [Validators.required, Validators.maxLength(30)]],
            selected_feature_id: [
              '',
              [Validators.required, Validators.maxLength(30)],
            ],
            product_feature_id: [
              '10#11100',
              [Validators.required, Validators.maxLength(30)],
            ],
            feature_controlValue_value: [
              '',
              [Validators.required, Validators.maxLength(30)],
            ],
            feature_controlValue_price: [
              0,
              [Validators.required, Validators.maxLength(30)],
            ],
            feature_price: [
              '',
              [Validators.required, Validators.maxLength(30)],
            ],
            is_featrue_optional:[!prod?.is_extra],
            feature_data: [''],
            template_area_id: [dimensions?.id || null],
            template_area: [dimensions?.template_area || null],
            template_area_type: [dimensions?.template_area_type || null],
            template_type_id: [dimensions?.template_type_id || null],
            template_id: [dimensions?.template_id || null],
            selected: [false, []],
            wishlistProductadded: [prod.wishlistProductadded],
            type: [prod.type],
            templateAreaPrice: [prod.templateAreaPrice],
            runningMeterAreaPrice: [prod.runningMeterAreaPrice],
            templateAreaWallPrice: [prod.templateAreaWallPrice],
            running_meter_area: [dimensions.running_meter_area],
            template_area_wall: [prod.price_wall],
            admin_id: [this.getClientDetails()?.clientDetails.create_by],
            userType: [this.getClientDetails()?.userType],
            addedBy: [this.getClientDetails()?.addedBy],
            show_quantity: [quantityArray.length > 0],
            productWishlistRec: [prod.productWishlistRec],
            quantity: [0],
            quantity_list: [quantityArray],
            product_quantity: [+prod.quantity],
            product_breadcrum: [prod?.breadchrum],
            // product_features: [prod?.ProductFeatures],
            product_features: [this.featurePriceForInputTypeCalculation(prod?.ProductFeatures,prod).filter(item=>(+obj?.cat_id)===(+item?.CatalogueFeature?.catalogue_id))],
            isProductDisabled: [this.isProjectDateExpired ? true : false],
            isAddQuantityDisabled: [this.isProjectDateExpired ? true : false],
            total_area_price: [0],
            isOutOfStock: [
              prod?.type !== 'variant'
                ? +prod?.quantity <= 0
                  ? true
                  : false
                : false,
            ],
            isSurfaceType: [prod?.type === 'variant' ? true : false],
            combineTempshowPrice:[]
          })
        );
      }
    }
    return newForm;
  }

  mapProductListFromWishList(productList: any, wishList: any) {
    // console.log(wishList,'current?.feature_price');
    
    this.totalCostRoom =
      this.getTotalCostFromWhishlist(wishList) > 0
        ? this.getTotalCostFromWhishlist(wishList) || 0
        : this.totalCostRoom;

        // this.totalCostRoom =
        // this.getTotalCostFromWhishlist(wishList) 

    for (var category of productList?.controls) {
      if (category.controls.prodList.controls.length) {
        for (var prod of category.controls.prodList.controls) {
          if (!this.isProjectDateExpired) {
            for (var cartProd of wishList)
            // quantity set
            if(prod?.value?.category_id === cartProd?.category_id){
              if (prod?.value?.product_id === cartProd?.product_id) {
                prod?.controls['quantity'].patchValue(+cartProd?.quantity);
                // when we refresh the page
                // category.controls['total_cat'].patchValue(
                //   this.getTotalSelectedQtyForSingleCategory(category.value)
                // );
                for (var feature of prod?.value?.product_features) {
                  if (feature.id === cartProd?.product_feature_id) {
                    feature.isSelected = true;
                    const tempPrice=this.formatPrice(feature.price) +'€'+' '+ feature.CatalogueFeature.label_name +" (" + feature?.control_value + ")";
                    prod?.controls['combineTempshowPrice'].patchValue(tempPrice);
                    prod.controls['feature_id'].patchValue(
                      +feature?.feature_id
                    );
                    prod.controls['product_feature_id'].patchValue(
                      +feature?.id
                    );
                    prod.controls['feature_price'].patchValue(
                      +this.formatPrice(feature?.price) || 0
                    );
                  }else{
                    feature.isSelected = false;
                  }
                }
                for (var feat of (prod?.value?.prod_drop_feature || [] )) {
                  for (var feature of feat?.feat_list) {
                    if (feature.id === cartProd?.product_feature_id) {
                      feat.is_outer_selected = true;
                      feature.is_feature_selected = true;
                      feat.selected_drop_feature = `${feature?.control_value}  +${this.formatPrice(feature?.price)}€`
                      prod.controls['feature_id'].patchValue(
                        +feature?.feature_id
                      );
                      prod.controls['product_feature_id'].patchValue(
                        +feature?.id
                      );
                      prod.controls['feature_price'].patchValue(
                        +feature?.price || 0
                      );
                    }
                  }
                 
                }
              //   prod?.value?.product_features?.sort((x,y)=>{
              //     return Number(y.isSelected) - Number(x.isSelected);
              // });
              
                if (
                  category.controls['added_cat'].value <=
                  category.controls['total_cat'].value
                ) {
                  this.disabledCartOperation(category, true);
                } else {
                  this.disabledCartOperation(category, false);
                }
              }
              // else {
              //   this.disabledCartOperation(category, true);
              // }
            }
            
          } 
        }
      }
    }
    if (productList?.value?.length)
      this.isRoomConfiguredRealTime = productList.value.filter(
        (cat: any) => cat?.cat_qty !== cat?.cat_total_qty
      )?.length;

    // if (
    //   this.isRoomConfiguredRealTime === 0 ||
    //   productList?.value?.length >= this.isRoomConfiguredRealTime
    // ) {
    //    this.isShowingPercentageProgressObserver$.next(true);
    // }

    // if (productList.value.length)
    //   productList.value.every((cat: any) => {
    //     if (
    //       cat?.cat_qty === cat?.cat_total_qty ||
    //       cat?.cat_qty - 1 === cat?.cat_total_qty
    //     ) {
    //       this.isShowingPercentageProgressObserver$.next(true);
    //       return false;
    //     }
    //     return true;
    //   });
  }

  isSurfaceProductIncludedInStandard(product:any):boolean{
    return (+product?.templateAreaPrice === 0 &&
    +product?.runningMeterAreaPrice === 0 &&
    +product?.templateAreaWallPrice === 0);
  }

  getVarientProductCost(product: any): number {
    if (!product?.isSurfaceType) {
      let price;
      if (+product?.product_price) {
        price = +product.quantity * +product?.product_price;
      } else {
        price = 0;
      }
      if (product?.feature_price) {
        price = price + Number(product?.feature_price) * +product.quantity;
      }
      return price || 0;
    } else {
      return 0;
    }
  }

  getFlatProductCost(product: any): number {
    let price = 0;
    if (product?.isSurfaceType) {
       price =
          +product?.templateAreaPrice ||
          +product?.runningMeterAreaPrice ||
          +product?.templateAreaWallPrice;
        if (product?.feature_price) {
          price = price + +product?.feature_price;
        }
      } 
      return price || 0;
  }

  getvarientCost(product: any): number {
    if (product?.isSurfaceType) {
      if (product?.product_price) {
        let price =
          +product?.templateAreaPrice ||
          +product?.runningMeterAreaPrice ||
          +product?.templateAreaWallPrice;
        return price || 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  getFlatCost(product: any): number {
    if (!product?.isSurfaceType) {
      let price;
      if (+product?.product_price) {
        price = +product.quantity * +product?.product_price;
      } else {
        price = 0;
      }
      return price || 0;
    } else {
      return 0;
    }
  }

  showProductPrice(obj: any): number {
    // if (obj.productWishlistRec) {
    //   return obj.productWishlistRec.product_price as number;
    // }

    if (obj?.type === 'variant') {
      return (
        +obj?.templateAreaPrice ||
        +obj?.templateAreaWallPrice ||
        (+obj?.runningMeterAreaPrice as number)
      );
    }

    return +obj.product_price as number;
  }

  showProductPriceForDropdown(obj: any): number {
    // if (obj.productWishlistRec) {
    //   return obj.productWishlistRec.product_price as number;
    // }

    if (obj?.type === 'variant') {
      return ((
        +obj?.Product?.templateAreaPrice ||
        +obj?.Product?.templateAreaWallPrice ||
        (+obj?.Product?.runningMeterAreaPrice as number)
      ) + (+obj?.feature_price || 0)) * +obj?.quantity;
    }
    return (+obj?.product_price + (+obj?.feature_price || 0) || 0) * (+obj?.quantity)
    
    // if (obj?.type === 'variant') {
    //   return (
    //     +obj?.Product?.templateAreaPrice ||
    //     +obj?.Product?.templateAreaWallPrice ||
    //     (+obj?.Product?.runningMeterAreaPrice as number)
    //   );
    // }

    // return +obj.product_price as number;
  }

  getTotalSelectedQtyForSingleCategory(parentCat: any): number {
    return (
      parentCat?.prodList?.length &&
      parentCat?.prodList?.reduce(
        (previous, current) => previous + +current?.quantity,
        0
      )
    );
  }

  // replace product price with  product total price
  getTotalCostFromWhishlist(wishList: any): number {
    return (
      wishList?.length &&
      wishList?.reduce(
        (previous, current) => previous + +current?.product_total_price,
        0
      )
    );

    // return (
    //   wishList?.length &&
    //   wishList?.reduce(
    //     (previous, current) => {
    //       // return previous + +current?.product_total_price
    //       if(current?.Product.type === 'flat'){
    //         return (previous + (+current?.Product?.price + (+current?.feature_price) || 0) * (+current?.quantity))
    //       }else{
    //         return previous + ((
    //                       +current?.Product?.templateAreaPrice ||
    //                       +current?.Product?.templateAreaWallPrice ||
    //                       (+current?.Product?.runningMeterAreaPrice as number)
    //                     )+ (+current?.feature_price) || 0);
    //       }
    //     },
    //     0
    //   )
    // );
    
    // return (
    //   wishList?.length &&
    //   wishList?.reduce(
    //     (previous, current) => {
    //       if(current?.type === 'flat'){
    //         if(!(current?.feature_price)){
    //           console.log(+current?.feature_price, '!current?.feature_price');
    //           console.log(previous, 'previous');
    //           console.log((current?.Product?.price * current?.quantity) , '(current?.Product?.price * current?.quantity) ');
    //           return previous + (+current?.Product?.price * +current?.quantity) 
    //         }else{
    //             console.log(+current?.feature_price, 'current?.feature_price');
    //             return (previous + (+((+current?.Product?.price) * (+current?.quantity)))) + (((+current?.feature_price) + (+current?.quantity)))
    //         }
           
    //       }else{
    //         if(!(current?.feature_price)){
    //           return previous + (
    //             +current?.Product?.templateAreaPrice ||
    //             +current?.Product?.templateAreaWallPrice ||
    //             (+current?.Product?.runningMeterAreaPrice as number)
    //           );
    //         }
    //        else{
    //         return previous + ((
    //           +current?.Product?.templateAreaPrice ||
    //           +current?.Product?.templateAreaWallPrice ||
    //           (+current?.Product?.runningMeterAreaPrice as number)
    //           + +current?.feature_price));
    //        }
    //       }
          
    //     },
    //     0
    //   )
    // );
  }

  disabledCartOperation(parentCat: any, disabled: boolean): void {
    if (parentCat?.controls?.prodList?.controls?.length)
      parentCat?.controls?.prodList?.controls?.forEach((prod: FormGroup) => {
        prod?.controls?.isProductDisabled.patchValue(disabled);
      });
  }

  calculatePrice(obj, dimension: any): number {
    if (obj.type === 'variant') {
      if (obj.productWishlistRec) {
        let sum =
          (obj.productWishlistRec.product_price
            ? Number(obj.productWishlistRec.product_price)
            : 0) +
          (obj.productWishlistRec.product_price_wall
            ? Number(obj.productWishlistRec.product_price_wall)
            : 0) +
          (obj.productWishlistRec.product_price_running_meter
            ? Number(obj.productWishlistRec.product_price_running_meter)
            : 0);
        if (
          obj.productWishlistRec.feature_price &&
          Number(obj.productWishlistRec.feature_price) !== 0
        ) {
          sum += Number(obj.productWishlistRec.feature_price);
        }
        return sum;
      }
      return (
        (obj.templateAreaPrice && Number(obj.templateAreaPrice) !== 0
          ? Number(obj.templateAreaPrice)
          : 0) +
        (obj.runningMeterAreaPrice && Number(obj.runningMeterAreaPrice) !== 0
          ? Number(obj.runningMeterAreaPrice)
          : 0) +
        (obj.templateAreaWallPrice && Number(obj.templateAreaWallPrice) !== 0
          ? Number(obj.templateAreaWallPrice)
          : 0) +
        (obj.feature_data &&
        obj.product_feature_id &&
        Number(obj.feature_controlValue_price) !== 0
          ? this.calulateFeaturePrice(obj.feature_controlValue_price, dimension)
          : 0)
      );
    } else {
      if (obj.productWishlistRec) {
        return (
          Number(obj.productWishlistRec.product_price) *
            Number(obj.productWishlistRec.quantity) +
          (obj.productWishlistRec.ProductFeature
            ? Number(obj.productWishlistRec.ProductFeature.price)
            : 0)
        );
      }
      return (
        Number(obj.product_price) * Number(obj.quantity) +
        (obj.feature_data &&
        obj.product_feature_id &&
        Number(obj.feature_controlValue_price) !== 0
          ? Number(obj.feature_controlValue_price)
          : 0)
      );
    }
  }

  calulateFeaturePrice(price, dimensions: any): number {
    price = Number(price);
    const squareMeterFloor = dimensions.template_area
      ? Number(dimensions.template_area)
      : 0;
    const squareMeterWall = dimensions.template_area_wall
      ? Number(dimensions.template_area_wall)
      : 0;
    return price * squareMeterWall + price * squareMeterFloor;
  }

  featurePriceForDropdownTypeCalculation(featureArray:any[],product:any):Array<any>{
    // console.log(featureArray, 'feature arru dropp[[ww');
    // console.log(product, 'product ee drop[[ww');
    if(product?.type === 'variant'){
      featureArray?.forEach((Ofeat=>{
        Ofeat?.feat_list?.forEach(feat=>{
          if(feat?.control_type === 'dropDown'){
            feat.price = +feat?.price * (this.totVariantPrice(product) || 0)
           }
        })
      }))
    }
    return featureArray
  }

  featurePriceForInputTypeCalculation(featureArray:any[],product:any):Array<any>{
    // console.log(featureArray, 'feature arru');
    // console.log(product, 'product ee');
    if(product?.type === 'variant'){
      featureArray.forEach((feat=>{
       if(feat?.control_type === 'input'){
        feat.price = +feat?.price * (this.totVariantPrice(product) || 0)
       }
      }))
    }
    

    return featureArray
  }

  totVariantPrice(obj){
    if(+obj?.templateAreaPrice || obj.floor){
      return +obj?.template_area
    }
    if(+obj?.runningMeterAreaPrice || obj.running_meter){
      return +obj?.running_meter_area
    }
    if(+obj?.templateAreaWallPrice || obj.wall){
      return +obj?.template_area_wall
    }
  }
}

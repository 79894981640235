import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { forkJoin, Observable, Subject, BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { constants } from '../helpers/common/global-constants';
import { ApiRoutes } from '../routes/apiRoutes';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  imgBaseUrl = environment.imgBaseUrl;
  private constants = constants;
  currentDate = new Date();
  public isActiveProductlistView: boolean = false;
  isProjectFocused: Subject<boolean> = new Subject<boolean>();
  isHamburgerMenuHide$: Subject<boolean> = new Subject<boolean>();
  public hideCartDiv: boolean = false;
  public isConfigured: boolean = false;
  cartTotalLengthEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  reqIds: any = {};
  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.route.snapshot),
        map((route: ActivatedRouteSnapshot) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route: ActivatedRouteSnapshot) => {
        this.isActiveProductlistView =
          route?.routeConfig?.path === 'item' ? true : false;
        this.hideCartDiv = false;
        this.isConfigured = this.isProjectConfigured;
        // if (!this.isActiveProductlistView) {
        //   this.fetchRequiredIDForGetRoom();
        //   if (this.reqIds?.pId && this.reqIds?.tId)
        //     this.getwishlistitembytempId();
        // }
      });
  }
  public requestDataFromMultipleSources(
    req: Observable<any[]>
  ): Observable<any[]> {
    // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
    return forkJoin(req);
  }

  /**
   * get required id's
   */
  fetchRequiredIDForGetRooms(): Object {
    if (localStorage.getItem(this.constants.requiredId)) {
      let ids = JSON.parse(localStorage.getItem(this.constants.requiredId));
      return ids;
    } else {
      return {};
    }
  }

  fetchRequiredIDForGetRoom(): void {
    if (localStorage.getItem(this.constants.requiredId)) {
      this.reqIds = JSON.parse(localStorage.getItem(this.constants.requiredId));
    }
  }
  getwishlistitembytempId() {
    const wishlistData = {
      project_id: this.reqIds?.pId,
      template_id: this.reqIds?.tId,
      template_type_id: this.reqIds?.roomId,
    };
    this.getwishlistitembytempIdr(wishlistData).subscribe((data: any) => {
      let prodLength = 0;
      // data.productWishlistRec?.length &&
      //   data.productWishlistRec?.forEach((el) => {
      //     prodLength += el?.products?.length || 0;
      //   });

      data.productWishlistRec?.length &&
        data.productWishlistRec?.forEach((el) => {
          el?.products?.forEach((prod) => {
            prodLength += +prod?.quantity || 0;
          });
        });
      this.cartTotalLengthEvent$.next(prodLength);
    });
  }
  public getwishlistitembytempIdr(body): Observable<any> {
    const url: string = ApiRoutes.getwishlistitembytempId;
    return this.http.post(url, body);
  }

  get isProjectDateExpired(): boolean {
    let ids: any = this.fetchRequiredIDForGetRooms();
    let projectDate = new Date(ids?.completed_date);
    return this.currentDate.getTime() > projectDate.getTime() ? true : false;
  }

  getRefresh() {
    let url = this._router.url;
    this._router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this._router.navigate([url]));
  }

  get isProjectConfigured(): boolean {
    return JSON.parse(localStorage.getItem(this.constants.isprojectConfigured))
      ? true
      : false;
  }

  getPrivacy(body:any){
    const url = ApiRoutes.getPrivacy
    return this.http.post(url, body);
  }
}

import localeDeExtra from '@angular/common/locales/extra/de';
import localeDe from '@angular/common/locales/de';
import { RouterModule } from '@angular/router';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { ProjectListCardComponent } from './components/cards/project-list-card/project-list-card.component';
import { TranslateModule } from '@ngx-translate/core';
export const CUSTOM_LOCALE = 'it-IT';
export const DEFAULT_LOCALE = 'de-DE';

export function initializeLocale(): string {
  const locale = DEFAULT_LOCALE;
  registerLocaleData(localeDe, 'de-DE', localeDeExtra);
  return locale;
}
@NgModule({
  declarations: [ProjectListCardComponent],
  imports: [CommonModule, RouterModule, TranslateModule],
  exports: [ProjectListCardComponent, RouterModule],
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: initializeLocale,
    },
  ],
})
export class SharedModule { }

import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-project-list-card',
  templateUrl: './project-list-card.component.html',
  styleUrls: ['./project-list-card.component.scss'],
})
export class ProjectListCardComponent implements OnInit {
  @Input() singleProject!: any;
  @Input() reqIds: any;
  imgBaseUrl = environment.imgBaseUrl;
  constructor() {}

  ngOnInit(): void {}
}

<section class="log-in-bg d-flex align-items-center justify-content-center">
  <div class="container pt-2 pb-2">
    <div
      class="row g-0 overflow-hidden d-flex bg-white box-shadow login-wrapper"
    >
      <div class="col-lg-6 text-center">
        <div class="login-img-wrapper">
          <img src="/assets/img/restepass.svg" class="img-fluid" />
        </div>
      </div> 

      <div class="col-lg-6 white-bg-color">
        <div class="form-signin">
          <form novalidate [formGroup]="ChangePasswordForm">
            <h1 class="page-title">
              <!-- Reset Password -->
              {{ "reset-password.heading1" | translate }}
              <span class="titlebar mb-3"></span>
            </h1>
           
            <p class="body-text">
              <!-- Please enter your new password -->
              {{ "forgot-password.heading2" | translate }}
            </p>
            <!-- Errors => {{getAllErrors(ChangePasswordForm) | json}} -->

            <div class="mb-3 w-100">
              <div class="form-floating border-bottom">
                <!-- <img src="assets/img/pass.png" alt="icon" class="input-icon"> -->
                <i
                  class="bi bi-lock icons-style-input icon-color-input fs-5"
                ></i>

                <input
                  type="password"
                  class="form-control ps-1 pe-4"
                  formControlName="newPassword"
                  autocomplete="off"
                  placeholder="{{
                    'change-password.passwordPlaceholder' | translate
                  }}"
                />
                <label for="floatingPassword" class="ps-0">
                  <!-- Type new password -->
                  {{ "change-password.newPassword" | translate }}
                </label>
              </div>
              <ng-container
                *ngIf="
                  ChangePasswordForm.controls['newPassword'].errors &&
                  (ChangePasswordForm.get('newPassword').dirty ||
                    ChangePasswordForm.get('newPassword').touched)
                "
              >
                <small
                  class="text-danger error-msg"
                  [hidden]="
                    !ChangePasswordForm.controls['newPassword'].errors.required
                  "
                >
                  <!-- *Password is required. -->
                  {{ "change-password.passwordRequired" | translate }}
                </small>
                <small
                  class="text-danger error-msg"
                  [hidden]="
                    !ChangePasswordForm.controls['newPassword'].errors.minlength
                  "
                >
                  <!-- *Password must be minimum 10 character long. -->
                  {{ "change-password.passwordCharLimit" | translate }}
                </small>
                <!-- <small class="text-danger error-msg" [hidden]="!ChangePasswordForm.controls['newPassword'].hasError('pattern')">
															*Password must contain alphanumeric and special characters.
													</small> -->
                <small
                  class="text-danger error-msg"
                  *ngIf="
                    ChangePasswordForm.controls['newPassword'].hasError(
                      'pattern'
                    ) &&
                    !ChangePasswordForm.controls['newPassword'].errors.minlength
                  "
                >
                  <!-- *Password must contain alphanumeric and special characters. -->
                  {{ "change-password.passwordAlphaNumericMsg" | translate }}
                </small>
              </ng-container>
            </div>

            <div class="mb-3 w-100">
              <div class="form-floating border-bottom">
                <!-- <img src="assets/img/pass.png" alt="icon" class="input-icon"> -->
                <i
                  class="bi bi-lock icons-style-input icon-color-input fs-5"
                ></i>
                <input
                  type="password"
                  class="form-control ps-1 pe-4"
                  formControlName="confirmNewPassword"
                  autocomplete="off"
                  placeholder="{{
                    'change-password.confirmPasswordPlaceholder' | translate
                  }}"
                />
                <label for="floatingPassword" class="ps-0">
                  <!-- Confirm new password -->
                  {{ "change-password.confirmPassword" | translate }}
                </label>
              </div>
              <ng-container
                *ngIf="
                  ChangePasswordForm.controls['confirmNewPassword'].errors &&
                  (ChangePasswordForm.get('confirmNewPassword').dirty ||
                    ChangePasswordForm.get('confirmNewPassword').touched)
                "
              >
                <small
                  class="text-danger error-msg"
                  *ngIf="
                    ChangePasswordForm.controls['confirmNewPassword'].errors
                      .required &&
                    !ChangePasswordForm.controls['confirmNewPassword'].hasError(
                      'passwordNotMatch'
                    )
                  "
                >
                  {{ "change-password.passwordRequired" | translate }}
                </small>
                <!-- <small
                  class="text-danger error-msg"
                  *ngIf="
                    ChangePasswordForm.controls['confirmNewPassword'].errors
                      .minlength
                  "
                >
                  {{ "change-password.passwordCharLimit" | translate }}
                </small> -->
                <small
                  class="text-danger error-msg"
                  *ngIf="
                    ChangePasswordForm.controls['confirmNewPassword'].hasError(
                      'passwordNotMatch'
                    )
                  "
                >
                  <!-- Passwords do not match -->
                  {{ "change-password.passwordNotMatch" | translate }}
                </small>
                <!-- <small
                  class="text-danger error-msg"
                  *ngIf="
                    ChangePasswordForm.controls['confirmNewPassword'].hasError(
                      'pattern'
                    ) &&
                    !(
                      ChangePasswordForm.controls['confirmNewPassword'].errors
                        .minlength &&
                      ChangePasswordForm.controls[
                        'confirmNewPassword'
                      ].hasError('passwordNotMatch')
                    )
                  "
                > 
                  {{ "change-password.passwordAlphaNumericMsg" | translate }}
                </small> -->
              </ng-container>
            </div>
            <button
              [disabled]="ChangePasswordForm.invalid"
              class="btn btn-main"
              type="submit"
              (click)="change($event)"
            >
              <!-- Submit -->
              {{ "change-password.submit" | translate }}
            </button>
          </form>

          <!-- {{ChangePasswordForm.value | json}} -->
        </div>
      </div>
    </div>
  </div>
</section>

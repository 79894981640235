<main class="page-wrapper" id="pdfTable" #pdfTable>
  <section class="py-md-5 pb-5 pt-3">
    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "large" color = "#fff" type = "square-jelly-box" ><p style="color: white" > Loading... </p></ngx-spinner>
    <div class="container mb-md-5">
      <div class="row">
        <div class="col-12 col-lg-9">
          <div class="cart-items-wrapper me-0 me-lg-4">
            <div class="cart-title d-block d-md-flex justify-content-between align-items-center" id="pdfTable"
              #pdfTable>
              <h2 class="mb-3 mb-md-0">
                {{ "shoppingcart.cart" | translate }}
              </h2>
              <div class="dropdown">
                <button class="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <span class="droptext">
                    <span>{{ selectedData }}</span> {{ selectedData2 }}
                  </span>
                  <svg class="select-icon" xmlns="http://www.w3.org/2000/svg" width="14" height="7.54"
                    viewBox="0 0 14 7.54">
                    <path id="chevron-down-dropdown"
                      d="M4.657,1.657a.538.538,0,0,1,.762,0L11.88,8.118a.538.538,0,0,1,0,.762L5.42,15.341a.539.539,0,1,1-.762-.762L10.738,8.5,4.657,2.42a.538.538,0,0,1,0-.762Z"
                      transform="translate(15.499 -4.499) rotate(90)" fill="#4c89d3" fill-rule="evenodd" />
                  </svg>
                </button>
                <ul class="dropdown-menu">
                  <li *ngFor="let p of updatedProjectList; let pi = index">
                    <a class="dropdown-item onhover" (click)="OnClickupdatedata(p, pi)">
                      <span>{{ p?.project?.name }}</span> |
                      {{ p?.details?.Template?.name }} |
                      {{ p?.details?.apartment_name }}
                    </a>
                  </li>
                </ul>
              </div>
              <!-- <div class="select-property mt-4 mt-md-0">
                <div class="dropdown">
                  <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span>Building Project 1</span> | Semi-detached house | 5
                  </button>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="#" >Action</a></li>
                    <li><a class="dropdown-item" href="#"  >Another action</a></li>
                    <li><a class="dropdown-item" href="#" >Something else here</a></li>
                  </ul>
                </div>
                <select [(ngModel)]="activeProject" class="select-project" #project
                  (change)="chooseActiveProject($event, project.value)">
                  <ng-container *ngFor="
                      let project of newProjectList;
                      let pListIndex = index
                    ">
                    <option [project-data]="project" [value]="project?.id">
                      {{ project?.name }}
                    </option>
                  </ng-container>
                </select>
                <span>|</span>
                <select [(ngModel)]="activeTemplate" class="projects-assets" #projectTemplate
                  (change)="chooseActiveTemplate($event, projectTemplate.value)">
                  <ng-container *ngFor="
                      let template of templateList;
                      let tListIndex = index
                    ">
                    <option [template-data]="template" [value]="template?.template_id">
                      {{ template?.Template?.name }} |
                      {{ template?.apartment_name }}
                    </option>
                  </ng-container>
                </select>
                <svg class="select-icon" xmlns="http://www.w3.org/2000/svg" width="14" height="7.54"
                  viewBox="0 0 14 7.54">
                  <path id="chevron-down-dropdown"
                    d="M4.657,1.657a.538.538,0,0,1,.762,0L11.88,8.118a.538.538,0,0,1,0,.762L5.42,15.341a.539.539,0,1,1-.762-.762L10.738,8.5,4.657,2.42a.538.538,0,0,1,0-.762Z"
                    transform="translate(15.499 -4.499) rotate(90)" fill="#4c89d3" fill-rule="evenodd" />
                </svg>
              </div> -->
            </div>
            <div>
              <ng-container *ngIf="isProductExist">
                <div class="cart-items mt-4" *ngFor="let room of wishlistdata; index as i">
                  <h3 class="ctg-title" *ngIf="room.products?.length > 0">
                    {{ room.name }} <span class="titlebar"></span>
                  </h3>
                  <div *ngFor="let data of room.products; index as l">
                    <div class="cart-item-wrapper row mx-0 mt-3">
                      <div class="col-12 col-md-4 col-lg-3 px-0">
                        <div class="cart-img-wrapper" (click)="redirectToProductDetail(room, data)">
                          <img src="{{
                            data.Product.image
                              ? imgBaseUrl + data.Product.image.split(',')[0]
                              : 'assets/img/placeholder.png'
                          }}" class="w-100" />
                        </div>
                      </div>
                      <div class="col-12 col-md-8 col-lg-9 px-0">
                        <div class="d-lg-flex d-block cart-info">
                          <div class="item-info">
                            <div class="d-flex justify-content-between">
                              <h4 (click)="redirectToProductDetail(room, data)">
                                {{ data.Product.product_name }}
                              </h4>
                              <!-- <a title="{{'shoppingcart.viewNots' | translate}}" class="onhover" data-bs-toggle="modal" data-bs-target="#viewNotes"
                            (click)="viewNotes(data)">
                            <i style='font-size:18px' class='fas'>&#xf044;</i>
                           </a> -->
                            </div>

                            <p>{{ data.cat_name }}</p>
                            <div class="extras" *ngIf="data?.Product?.ProductFeatures?.length > 0">
                              <h5>Extras
                                <sup *ngIf="data?.Product?.is_extra" class="sup_feat_required">
                                  *</sup>:
                                <pre *ngIf="!data?.Product?.is_extra"
                                  style="display: inline-block;position: absolute;"> (optional):</pre>
                              </h5>
                              <div class="d-flex flex-wrap">


                                <div [class.project_completed]="activeProject?.details?.is_completed">
                                  <ng-container *ngFor="
                                  let feature of data?.Product?.ProductFeatures;
                                  index as featrueIndex
                                ">
                                    <!-- previou check  -->
                                    <!-- *ngIf="
                                    (feature?.show_more
                                      ? featrueIndex <= feature?.show_limit
                                      : true) && feature?.initial
                                  " -->
                                    <!-- previou check  end-->

                                    <!-- id="extraImage{{ featrueIndex }}{{ i }}{{
                                  l
                                }}" -->
                                    <div id="extraImage{{ featrueIndex }}{{ i }}{{
                                  l
                                }}" class="modal fade popup-images" *ngIf="
                                    feature?.CatalogueFeature?.image ||
                                    feature?.control_img
                                  " data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
                                      aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                      <a type="button" class="aroundclose" data-bs-dismiss="modal"
                                        aria-label="Close"></a>
                                      <div class="modal-dialog modal-dialog-centered modal-lg">
                                        <div class="modal-content h-100" style="
                                        background: transparent;
                                        box-shadow: none;
                                        border: 0;
                                      
                                        width: max-content;
                                      ">
                                          <div class="modal-body extra-img-popup">
                                            <button type="button" class="btn-close" data-bs-dismiss="modal"
                                              aria-label="Close"></button>
                                            <img *ngIf="
                                            feature?.control_type === 'input' &&
                                            feature?.CatalogueFeature?.image
                                          " [src]="
                                            feature?.CatalogueFeature?.image
                                              ? imgBaseUrl +
                                                feature?.CatalogueFeature?.image
                                              : 'assets/img/placeholder.png'
                                          " alt="{{
                                            feature?.CatalogueFeature
                                              ?.label_name
                                          }}" />

                                            <img *ngIf="
                                            feature?.control_type ===
                                              'dropDown' && feature?.control_img
                                          " [src]="
                                            feature?.control_img
                                              ? imgBaseUrl +
                                                feature?.control_img
                                              : 'assets/img/placeholder.png'
                                          " alt="{{ feature?.control_value }}" />
                                            <h3>
                                              {{
                                              feature?.CatalogueFeature
                                              ?.label_name
                                              }}
                                              {{
                                              feature?.control_value
                                              ? "(" +
                                              feature?.control_value +
                                              ")"
                                              : ""
                                              }}
                                              {{
                                              +formatPrice(feature?.price)
                                              ? " + " + +formatPrice(feature?.price) + " €"
                                              : ""
                                              }}
                                            </h3>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <!-- *ngIf="feature?.control_type === 'input' && data?.selectedFeatureType === 'input'" -->
                                    <div class="position-relative extras-wrapper"
                                      *ngIf="data?.selectedFeatureType ? (feature?.control_type === 'input' && data?.selectedFeatureType === 'input') : feature?.control_type === 'input'">
                                      <a title="click to see on full view" data-bs-toggle="modal" href="#extraImage{{ featrueIndex }}{{ i }}{{
                                      l
                                    }}" class="onhover extra-img" *ngIf="
                                      feature?.control_type === 'input' &&
                                      feature?.CatalogueFeature?.image
                                    ">
                                        <img *ngIf="
                                        feature?.control_type === 'input' &&
                                        feature?.CatalogueFeature?.image
                                      " [src]="
                                        feature?.CatalogueFeature?.image
                                          ? imgBaseUrl +
                                            feature?.CatalogueFeature?.image
                                          : 'assets/img/placeholder.png'
                                      " alt="{{
                                        feature?.CatalogueFeature?.label_name
                                      }}" />
                                      </a>

                                      <a class="onhover extra-img" title="click to see on full view"
                                        data-bs-toggle="modal" href="#extraImage{{ featrueIndex }}{{ i }}{{
                                      l
                                    }}" *ngIf="
                                      feature?.control_type === 'dropDown' &&
                                      feature?.control_img
                                    ">
                                        <img *ngIf="
                                        feature?.control_type === 'dropDown' &&
                                        feature?.control_img
                                      " [src]="
                                        feature?.control_img
                                          ? imgBaseUrl + feature?.control_img
                                          : 'assets/img/placeholder.png'
                                      " alt="{{ feature?.control_value }}" />
                                      </a>

                                      <span title="{{
                                      feature?.CatalogueFeature?.label_name
                                    }} {{
                                      feature?.control_value
                                        ? '(' + feature?.control_value + ')'
                                        : ''
                                    }}
                                    {{
                                      +formatPrice(feature?.price)
                                        ? ' + ' + +formatPrice(feature?.price) + ' €'
                                        : ''
                                    }}" [class.selected]="feature?.isSelected" *ngIf="featrueIndex < 2"
                                        (click)="chooseFeatrue(obj, data, feature)">
                                        <!-- <img
                                    *ngIf="feature?.CatalogueFeature?.image"
                                    [src]="
                                      feature?.CatalogueFeature?.image
                                        ? imgBaseUrl +
                                          feature?.CatalogueFeature?.image
                                        : 'assets/img/placeholder.png'
                                    "
                                    alt="{{
                                      feature?.CatalogueFeature?.label_name
                                    }}"
                                  />
                                  {{ feature?.CatalogueFeature?.label_name }} -->

                                        <!-- {{ feature?.control_value }} -->
                                        <span class="name-extra">
                                          {{
                                          feature?.CatalogueFeature?.label_name
                                          }}
                                          {{
                                          feature?.control_value
                                          ? "(" + feature?.CatalogueFeature?.control_value + ")"
                                          : ""
                                          }}
                                        </span>
                                        <span class="additional-price" *ngIf="feature?.price">+
                                          {{
                                          +formatPrice(feature?.price)
                                          | number : "1.0-0" : "de-DE"
                                          }}
                                          €</span>
                                      </span>
                                    </div>
                                  </ng-container>


                                  <!-- <ng-container *ngFor="let dropFeat of data?.Product?.prod_drop_feature;let iouter=index">
                                <div class="position-relative extras-wrapper" *ngIf="iouter <= visibleDropFeature()">
                                  <div class="position-relative dropdown">
                                    <div >
                                      <button type="button" class="btn btn-main mt-3 dropdown-toggle" data-bs-toggle="dropdown"
                                      aria-expanded="false">
                                      <span style="width: 20px;" *ngIf="!dropFeat?.selected_drop_feature">
                                        {{dropFeat?.feat_list[0]?.CatalogueFeature?.label_name}}
                                     </span>
                      
                                     <span style="width: 20px;" *ngIf="dropFeat?.selected_drop_feature">
                                      {{dropFeat?.selected_drop_feature}}
                                   </span>

                                    </button> 
                                    <div (click)="handleClick($event)" class="dropdown-menu">
                                    <ng-container *ngFor="let feature of dropFeat?.feat_list;let innerI= index">
                                      <div
                                      id="extraImagee1{{ innerI }}{{iouter}}{{
                                        j + 1
                                      }}{{ i }}"
                                      class="modal fade"
                                      data-bs-backdrop="static"
                                      data-bs-keyboard="false"
                                      tabindex="-1"
                                      aria-labelledby="staticBackdropLabel"
                                      aria-hidden="true"
                                      >
                                      <a
                                        type="button"
                                        class="aroundclose"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></a>
                                      <div
                                        class="modal-dialog modal-dialog-centered modal-lg"
                                      >
                                        <div class="modal-content">
                                          <div class="modal-body extra-img-popup">
                                            <button
                                              type="button"
                                              class="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                            ></button>
                    
                                            <img
                                              *ngIf="
                                                feature?.control_type ===
                                                  'dropDown' &&
                                                feature?.control_img
                                              "
                                              [src]="
                                                feature?.control_img
                                                  ? imgBaseUrl +
                                                    feature?.control_img
                                                  : 'assets/img/placeholder.png'
                                              "
                                              alt="{{ feature?.control_value }}"
                                            />
                                            <h3>
                                              {{
                                                feature?.CatalogueFeature
                                                  ?.label_name
                                              }}
                                              {{
                                                feature?.control_value
                                                  ? "(" +
                                                    feature?.control_value +
                                                    ")"
                                                  : ""
                                              }}
                                              {{
                                                +feature?.price
                                                  ? " + " + +feature?.price + " €"
                                                  : ""
                                              }}
                                            </h3>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                      <div
                                      style=" width: 100%; background: rgb(255, 255, 255); border-top-left-radius: 0px; border-top-right-radius: 0px; box-shadow: rgba(204, 204, 204, 0.49) 0px 10px 6px 6px;">
                                      
                                       
                                     
                                     
                                     
                                      
                                      <a (click)="chooseDropFeaure(feature, dropFeat,data, cat)" class="dropdown-item" >
                                          {{feature?.control_value + '  + ' + feature?.price+' €'}}</a>

                                           <a data-bs-toggle="modal"
                                           href="#extraImagee1{{ innerI }}{{iouter}}{{
                                            j + 1
                                          }}{{ i }}" 
                                   id="extraImagee1{{innerI}}{{iouter}}{{j+1}}{{i}}" class="fa fa-eye dropdown-item w-25" title="click to see on full view">
                                      </a>
                             
                                   
                                      
                                  </div>
                                  </ng-container>
                                    </div>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>  -->

                                  <!-- use below-->
                                  <ng-container
                                    *ngFor="let dropFeat of data?.Product?.prod_drop_feature;let iouter=index">
                                    <div
                                      *ngIf="data?.selectedFeatureType ? (data?.selectedFeatureType === 'dropDown' ? iouter <= visibleDropFeature() : false ): iouter <= visibleDropFeature()">
                                      <div *ngIf="dropInnerIndex" id="extraImagee1{{ dropInnerIndex }}{{iouter}}{{
                                    j + 1
                                  }}{{ i }}" class="modal fade dropdown_feat_modal" data-bs-backdrop="static"
                                        data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel"
                                        aria-hidden="true">
                                        <a type="button" class="aroundclose" data-bs-dismiss="modal"
                                          aria-label="Close"></a>
                                        <div class="modal-dialog modal-dialog-centered modal-lg">
                                          <div class="modal-content">
                                            <div class="modal-body extra-img-popup">
                                              <button type="button" class="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"></button>

                                              <img *ngIf="
                                            feature?.control_type ===
                                              'dropDown' &&
                                            feature?.control_img
                                          " [src]="
                                            feature?.control_img
                                              ? imgBaseUrl +
                                                feature?.control_img
                                              : 'assets/img/placeholder.png'
                                          " alt="{{ feature?.control_value }}" />
                                              <h3>
                                                {{
                                                feature?.CatalogueFeature
                                                ?.label_name
                                                }}
                                                {{
                                                feature?.control_value
                                                ? "(" +
                                                feature?.control_value +
                                                ")"
                                                : ""
                                                }}
                                                {{
                                                +formatPrice(feature?.price)
                                                ? " + " + +formatPrice(feature?.price )+ " €"
                                                : ""
                                                }}
                                              </h3>
                                            </div>
                                          </div>
                                        </div>
                                      </div>


                                      <div class="dropdown position-relative ">
                                        <div>
                                          <span [class.selected]="dropFeat?.selected_drop_feature" type="button"
                                            class="btn feat_drop_btn  dropdown-toggle" data-bs-toggle="dropdown"
                                            aria-expanded="false">
                                            <span class="dropdown_span_feat" *ngIf="!dropFeat?.selected_drop_feature">
                                              {{dropFeat?.feat_list[0]?.CatalogueFeature?.label_name}}
                                            </span>

                                            <span [class.selected]="dropFeat?.selected_drop_feature"
                                              class="dropdown_span_feat" *ngIf="dropFeat?.selected_drop_feature">
                                              {{dropFeat?.selected_drop_feature}}
                                            </span>

                                          </span>
                                          <div (click)="handleClick($event)" class="dropdown-menu" style="padding:0">
                                            <ng-container *ngFor="let feature of dropFeat?.feat_list;let innerI= index">

                                              <div class="d-flex justify-content-between align-items-center onhover-img"
                                                style="width: 100%;background: rgb(255, 255, 255); border-top-left-radius: 0px; border-top-right-radius: 0px; box-shadow: rgba(204, 204, 204, 0.49) 0px 2px 3px 3px;">
                                                <a style="cursor: pointer;font-size: 14px;
                                      font-weight: 600;
                                      " (click)="chooseDropFeaure(feature, dropFeat, data, cat)" class="dropdown-item">
                                                  {{feature?.control_value + ' +' + formatPrice(feature?.price)+' €'}}</a>
                                                <a style="cursor: pointer;font-size: 14px;
                                           font-weight: 600;
                                           
                                           margin-top: 0px;" (mouseenter)="getId(feature,innerI+1,iouter,j+1,i)"
                                                  class="fa fa-arrow-circle-right dropdown-item w-25 pr-2"
                                                  title="click to see on full view" data-bs-toggle="modal" href="#extraImagee1{{ innerI+1 }}{{iouter}}{{
                                              j + 1
                                            }}{{ i }}">

                                            <!-- feature image for dropdown feature  -->
                                            <img class="img-ft-popup" *ngIf="feature?.control_img" [src]="
                          feature?.control_img
                            ? imgBaseUrl +
                              feature?.control_img
                            : 'assets/img/placeholder.png'
                        ">
                                            <!-- feature image for dropdown feature end -->
                                          
                                          
                                          </a>

                                               


                                              </div>
                                            </ng-container>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </ng-container>



                                </div>
                                <span *ngIf="
                            data?.Product?.ProductFeatures?.length > 2
                          " class="showmorebutton" style="margin-left: 10px;width: fit-content;height: fit-content;"><a
                                    (click)="redirectToProductDetail(room, data)">+ {{('configpage.more' | translate) | lowercase}}
                                  </a></span>
                              </div>
                            </div>
                          </div>

                          <div class="item-pricing">
                            <div class="price d-flex align-items-center d-lg-block">
                              <a *ngIf="data?.product_note" title="{{'shoppingcart.viewNots' | translate}}"
                                class="onhover " data-bs-toggle="modal" data-bs-target="#viewNotes"
                                (click)="viewNotes(data)">
                                <!-- <i  class='fas fs-6 me-1  '>&#xf044;</i> -->
                                <img src="assets/img/writing.png" height="30" class="me-2">
                              </a>


                              <div class="price d-inline mt-1" *ngIf="!(+data?.product_price) && !data?.isSurfaceType">
                                <div *ngIf="data?.ProductFeature?.price==0">
                                  {{ "configpage.includedinstandard" | translate }}
                                </div>
                                <div *ngIf="data?.ProductFeature?.price!==0">
                                  {{data?.ProductFeature?.price}}€
                                </div>
                              </div>
                              <div class="price"
                                *ngIf="data?.isSurfaceType && _configuratorService?.isSurfaceProductIncludedInStandard(data)">
                                {{ "configpage.includedinstandard" | translate }}
                              </div>
                              <div class="price d-inline mt-1" *ngIf="+data?.product_price && !data?.isSurfaceType">
                                {{
                                showProductPrice(data)
                                | number : "1.0-0" : "de-DE"
                                }}
                                €
                              </div>

                              <div class="price d-inline mt-1"
                                *ngIf="data?.isSurfaceType && !_configuratorService?.isSurfaceProductIncludedInStandard(data)">
                                {{
                                showProductPrice(data)
                                | number : "1.0-0" : "de-DE"
                                }}
                                €
                              </div>



                              <!-- <div class="price d-inline mt-1" *ngIf="!data.Product.price">
                              {{
                              "shoppingcart.includedinstandard" | translate
                              }}
                            </div>
                            <div *ngIf="data.Product.price" class="d-inline">
                             
                              {{
                              data?.productOriginalPrice
                              | number : "1.0-0" : "de-DE"
                              }}
                              €
                            </div> -->

                              <!-- <div
                              *ngIf="
                                data?.type === 'flat' && data?.Product?.price
                              "
                            >
                              {{
                                data?.type !== "flat"
                                  ? (getvarientprce(data)
                                    | number : "1.2-2" : "de-DE")
                                  : (data.Product.price
                                    | number : "1.2-2" : "de-DE")
                              }}€
                            </div>

                            <div *ngIf="data?.type === 'variant'">
                              {{
                                data?.type !== "flat"
                                  ? (getvarientprce(data)
                                    | number : "1.2-2" : "de-DE")
                                  : (data.Product.price
                                    | number : "1.2-2" : "de-DE")
                              }}€
                            </div> -->

                              <!-- <span
                              class="ms-3 ms-lg-0"
                              *ngIf="!data.Product.quantity == 0"
                              >Quantity: {{ data.Product.quantity }}</span
                            > -->
                            </div>
                            <div class="cart-quantity" [class.project_completed]="activeProject?.details?.is_completed">
                              <div class="d-md-flex d-block justify-content-end">
                                <a class="onhover" data-bs-toggle="modal" data-bs-target="#applyItems"
                                  (click)="showroomItems(data)">+
                                  {{
                                  "shoppingcart.adoptarticlesforotherrooms"
                                  | translate
                                  }}</a>
                              </div>
                              <div class="d-flex align-items-center mt-2">
                                <!-- cart item -->

                                <div class="cartquantity" *ngIf="data?.type === 'flat' && data?.quantity > 0">
                                  <button type="button" (click)="addQunatity(room, data)" class="plus" [disabled]="
                                    !_configuratorService.isProjectDateExpired
                                      ? data?.isDisabled
                                      : true
                                  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="9.259" height="9.834"
                                      viewBox="0 0 9.259 9.834">
                                      <path id="icon_plus_white" data-name="icon plus white"
                                        d="M1.438-7.663H4.6v2.681H1.438v3.627H-1.484V-4.982H-4.657V-7.663h3.173v-3.525H1.438Z"
                                        transform="translate(4.657 11.188)" fill="#fff" />
                                    </svg>
                                  </button>
                                  <input type="text" class="cartquantityvalue" [(ngModel)]="data.quantity" disabled />

                                  <button type="button" (click)="minusQunatity(room, data)" class="minus" [disabled]="
                                    _configuratorService.isProjectDateExpired
                                  ">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="10.058" height="2.449"
                                      viewBox="0 0 10.058 2.449">
                                      <path id="icon_minus_white" data-name="icon minus white"
                                        d="M5.007-5.362H-5.052V-7.812H5.007Z" transform="translate(5.052 7.812)"
                                        fill="#fff" />
                                    </svg>
                                  </button>
                                </div>
                                <!-- cart item end -->

                                <button class="btn btn-main ms-3" type="button" (click)="deleteProduct(data)"
                                  [disabled]="
                                  _configuratorService.isProjectDateExpired
                                ">
                                  {{ "shoppingcart.remove" | translate }}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!isProductExist && isContentLoaded">
                <div class="articles-not-found">
                  <h1>{{'notFound.notfoundtitle' | translate}}</h1>
                  <small>{{'notFound.articlenotfounddesc' | translate}}</small>
                </div>
              </ng-container>
            </div>
            <!-- <div class="cart-items mt-5">
              <h3 class="ctg-title">Bedroom <span class="titlebar"></span></h3>

              <div class="cart-item-wrapper row mx-0 mt-3">
                <div class="col-12 col-md-4 col-lg-3 px-0">
                  <div class="cart-img-wrapper">
                    <img src="assets/img/b1.jpg" class="w-100" />
                  </div>
                </div>
                <div class="col-12 col-md-8 col-lg-9 px-0">
                  <div class="d-lg-flex d-block cart-info">
                    <div class="item-info">
                      <h4>Herholt Esprit | White | Shiny</h4>
                      <p>Interior doors</p>
                    </div>
                    <div class="item-pricing">
                      <div class="price d-flex align-items-center d-lg-block">
                        50 € <span class="ms-3 ms-lg-0">Quantity: 2</span>
                      </div>
                      <div class="cart-quantity">
                        <div class="d-md-flex d-block justify-content-end">
                          <a
                            class=""
                            data-bs-toggle="modal"
                            data-bs-target="#applyItems"
                            >+ Adopt article for other rooms</a
                          >
                        </div>
                        <div class="d-flex align-items-center mt-2">
                          

                          <div class="cartquantity">
                            <button
                              type="button"
                              (click)="addQunatity()"
                              class="plus"
                              style="background: #9ca8b6; border-color: #9ca8b6"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="9.259"
                                height="9.834"
                                viewBox="0 0 9.259 9.834"
                              >
                                <path
                                  id="icon_plus_white"
                                  data-name="icon plus white"
                                  d="M1.438-7.663H4.6v2.681H1.438v3.627H-1.484V-4.982H-4.657V-7.663h3.173v-3.525H1.438Z"
                                  transform="translate(4.657 11.188)"
                                  fill="#fff"
                                />
                              </svg>
                            </button>
                            <input
                              type="text"
                              class="cartquantityvalue"
                              [value]="quantity"
                              disabled
                            />

                            <button
                              type="button"
                              (click)="minusQunatity()"
                              class="minus"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10.058"
                                height="2.449"
                                viewBox="0 0 10.058 2.449"
                              >
                                <path
                                  id="icon_minus_white"
                                  data-name="icon minus white"
                                  d="M5.007-5.362H-5.052V-7.812H5.007Z"
                                  transform="translate(5.052 7.812)"
                                  fill="#fff"
                                />
                              </svg>
                            </button>
                          </div>
                         

                          <button class="btn btn-main ms-3" type="button">
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cart-item-wrapper row mx-0 mt-3">
                <div class="col-12 col-md-4 col-lg-3 px-0">
                  <div class="cart-img-wrapper">
                    <img src="assets/img/b1.jpg" class="w-100" />
                  </div>
                </div>
                <div class="col-12 col-md-8 col-lg-9 px-0">
                  <div class="d-lg-flex d-block cart-info">
                    <div class="item-info">
                      <h4>Herholt Esprit | White | Shiny</h4>
                      <p>Interior doors</p>
                    </div>
                    <div class="item-pricing">
                      <div class="price d-flex align-items-center d-lg-block">
                        50 € <span class="ms-3 ms-lg-0">Quantity: 2</span>
                      </div>
                      <div class="cart-quantity">
                        <div class="d-md-flex d-block justify-content-end">
                          <a
                            class=""
                            data-bs-toggle="modal"
                            data-bs-target="#applyItems"
                            >+ Adopt article for other rooms</a
                          >
                        </div>
                        <div class="d-flex align-items-center mt-2">
                          

                          <div class="cartquantity">
                            <button
                              type="button"
                              (click)="addQunatity()"
                              class="plus"
                              style="background: #9ca8b6; border-color: #9ca8b6"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="9.259"
                                height="9.834"
                                viewBox="0 0 9.259 9.834"
                              >
                                <path
                                  id="icon_plus_white"
                                  data-name="icon plus white"
                                  d="M1.438-7.663H4.6v2.681H1.438v3.627H-1.484V-4.982H-4.657V-7.663h3.173v-3.525H1.438Z"
                                  transform="translate(4.657 11.188)"
                                  fill="#fff"
                                />
                              </svg>
                            </button>
                            <input
                              type="text"
                              class="cartquantityvalue"
                              [value]="quantity"
                              disabled
                            />

                            <button
                              type="button"
                              (click)="minusQunatity()"
                              class="minus"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10.058"
                                height="2.449"
                                viewBox="0 0 10.058 2.449"
                              >
                                <path
                                  id="icon_minus_white"
                                  data-name="icon minus white"
                                  d="M5.007-5.362H-5.052V-7.812H5.007Z"
                                  transform="translate(5.052 7.812)"
                                  fill="#fff"
                                />
                              </svg>
                            </button>
                          </div> -->
            <!-- cart item end -->

            <!-- <button class="btn btn-main ms-3" type="button">
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cart-item-wrapper row mx-0 mt-3">
                <div class="col-12 col-md-4 col-lg-3 px-0">
                  <div class="cart-img-wrapper">adop
                    <img src="assets/img/b1.jpg" class="w-100" />
                  </div>
                </div>
                <div class="col-12 col-md-8 col-lg-9 px-0">
                  <div class="d-lg-flex d-block cart-info">
                    <div class="item-info">
                      <h4>Herholt Esprit | White | Shiny</h4>
                      <p>Interior doors</p>
                    </div>
                    <div class="item-pricing">
                      <div class="price d-flex align-items-center d-lg-block">
                        50 € <span class="ms-3 ms-lg-0">Quantity: 2</span>
                      </div>
                      <div class="cart-quantity">
                        <div class="d-md-flex d-block justify-content-end">
                          <a
                            class=""
                            data-bs-toggle="modal"adop
                          >
                        </div>
                        <div class="d-flex align-items-center mt-2">
                         

                          <div class="cartquantity">
                            <button
                              type="button"
                              (click)="addQunatity()"
                              class="plus"
                              style="background: #9ca8b6; border-color: #9ca8b6"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="9.259"
                                height="9.834"
                                viewBox="0 0 9.259 9.834"
                              >
                                <path
                                  id="icon_plus_white"
                                  data-name="icon plus white"
                                  d="M1.438-7.663H4.6v2.681H1.438v3.627H-1.484V-4.982H-4.657V-7.663h3.173v-3.525H1.438Z"
                                  transform="translate(4.657 11.188)"
                                  fill="#fff"
                                />
                              </svg>
                            </button>
                            <input
                              type="text"
                              class="cartquantityvalue"
                              [value]="quantity"
                              disabled
                            />

                            <button
                              type="button"
                              (click)="minusQunatity()"
                              class="minus"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10.058"
                                height="2.449"
                                viewBox="0 0 10.058 2.449"
                              >
                                <path
                                  id="icon_minus_white"
                                  data-name="icon minus white"
                                  d="M5.007-5.362H-5.052V-7.812H5.007Z"
                                  transform="translate(5.052 7.812)"
                                  fill="#fff"
                                />
                              </svg>
                            </button>
                          </div>
                          

                          <button class="btn btn-main ms-3" type="button">
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>

        <div class="row col-12 col-lg-3 mt-4 mt-lg-0 ">
          <div class="sidebar">
            <button type="button" class="btn btn-main btn-red w-100" (click)="configure()">
              {{ "shoppingcart.goToConfigurator" | translate }}
            </button>
            <!-- <div class="col-8 col-lg-8 mt-4 mt-lg-0">
              <select type="text" [(ngModel)]="exportType">
                <option [value]="''" [hidden]="exportType">choose</option>
                <option value="1">with price</option>
                <option value="2">without price</option>
                <option value="3">both</option>
              </select>
            </div> -->
            <div class="position-relative dropdown">
              <button [disabled]="!isProductExist" type="button" class="btn btn-main mt-3 w-100 dropdown-toggle"
                data-bs-toggle="dropdown" aria-expanded="false">
                <span style="width: 20px;">
                  <svg *ngIf="!_configuratorService.isPdfDownloading" id="file-earmark-pdf_white" class="me-3"
                    data-name="file-earmark-pdf white" xmlns="http://www.w3.org/2000/svg" width="12" height="16"
                    viewBox="0 0 12 16">
                    <path id="Path_33" data-name="Path 33"
                      d="M14,14V4.5L9.5,0H4A2,2,0,0,0,2,2V14a2,2,0,0,0,2,2h8A2,2,0,0,0,14,14ZM9.5,3A1.5,1.5,0,0,0,11,4.5h2V14a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V2A1,1,0,0,1,4,1H9.5Z"
                      transform="translate(-2)" fill="#fff" />
                    <path id="Path_34" data-name="Path 34"
                      d="M4.6,14.087a.81.81,0,0,1-.438-.42,1.073,1.073,0,0,1,.08-1.1,2.662,2.662,0,0,1,.9-.787,7.68,7.68,0,0,1,1.482-.645A19.7,19.7,0,0,0,7.686,8.906a7.269,7.269,0,0,1-.43-1.3A2.778,2.778,0,0,1,7.21,6.475a1.08,1.08,0,0,1,.65-.823,1.043,1.043,0,0,1,.6-.077.7.7,0,0,1,.477.365,1.243,1.243,0,0,1,.127.538,3.2,3.2,0,0,1-.047.614A9.847,9.847,0,0,1,8.5,8.886a10.954,10.954,0,0,0,.98,1.686,5.753,5.753,0,0,1,1.334.05,1.6,1.6,0,0,1,.96.465.846.846,0,0,1,.2.518,1.163,1.163,0,0,1-.138.563,1.04,1.04,0,0,1-.354.416.856.856,0,0,1-.51.138,1.694,1.694,0,0,1-.933-.417,5.712,5.712,0,0,1-.911-.95,11.651,11.651,0,0,0-2,.406,11.307,11.307,0,0,1-1.02,1.51,2.7,2.7,0,0,1-.927.787A.793.793,0,0,1,4.6,14.087Zm1.379-1.9a5.017,5.017,0,0,0-.459.238,1.955,1.955,0,0,0-.647.547.327.327,0,0,0-.04.361.227.227,0,0,0,.026.044l.035-.012a2.034,2.034,0,0,0,.635-.572q.239-.292.45-.606Zm1.64-1.33q.5-.117,1.01-.193-.273-.418-.51-.858-.235.532-.5,1.05Zm2.446.45a3.833,3.833,0,0,0,.435.41,1,1,0,0,0,.5.256.107.107,0,0,0,.07-.015.307.307,0,0,0,.094-.125.436.436,0,0,0,.059-.2.1.1,0,0,0-.026-.063.915.915,0,0,0-.518-.209,3.876,3.876,0,0,0-.612-.053ZM8.078,7.8a6.7,6.7,0,0,0,.2-.828,2.355,2.355,0,0,0,.038-.465.613.613,0,0,0-.032-.2.517.517,0,0,0-.145.04c-.087.035-.158.106-.2.283a2.087,2.087,0,0,0,.046.822c.024.111.054.227.09.346Z"
                      transform="translate(-2)" fill="#fff" />
                  </svg>
                  <img *ngIf="_configuratorService.isPdfDownloading" src="assets/img/loading-gif.gif"
                    class="img-fluid me-3" style="margin-left: -6px;" />
                </span>
                <span style="max-width: calc(100% - 20px);">{{ "shoppingcart.exportcartaspdf" | translate }}</span>


              </button>
              <ul class="dropdown-menu pdf-dropdonw"
                style=" width: 100%; background: rgb(255, 255, 255); border-top-left-radius: 0px; border-top-right-radius: 0px; box-shadow: rgba(204, 204, 204, 0.49) 0px 10px 6px 6px;">
                <li style="cursor:pointer"><a class="dropdown-item" (click)="exportType = 1;onExport()">{{ "shoppingcart.exportwithprice" | translate }}</a></li>
                <li style="cursor:pointer"><a class="dropdown-item" (click)="exportType = 2;onExport()">{{ "shoppingcart.exportwithoutprice" | translate }}</a></li>
                <li style="cursor:pointer"><a class="dropdown-item" (click)="exportType = 3;onExport()">{{ "shoppingcart.exportbothversion" | translate }}</a></li>
              </ul>
            </div>


            <div class="sidebar-items-box">
              <h3 class="d-flex align-items-start justify-content-between flex-wrap">
                <span class="me-2 mb-2" *ngIf="activeProject">
                  {{ selectedData }}
                  <span class="prj-description d-block mt-1">{{
                    setApartmentName(selectedData2)
                    }}</span></span>
                <span class="status">Status
                  <span class="progressbar" [class.progressbar-positive]="
                      activeProject?.progressPercentage === 100
                    ">{{
                    +activeProject?.progressPercentage | number : "1.0-0"
                    }}%</span></span>
              </h3>

              <ul>
                <li *ngFor="let data of wishlistdata; let ind = index">
                  <!-- <svg
                    width="14"
                    height="14"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8Z"
                      fill="#C23D31"
                      fill-opacity="0.2"
                    />
                    <path
                      d="M14.7083 8.00002C14.7083 4.29511 11.7049 1.29169 7.99999 1.29169C4.29508 1.29169 1.29166 4.29511 1.29166 8.00002C1.29166 11.7049 4.29508 14.7084 7.99999 14.7084C11.7049 14.7084 14.7083 11.7049 14.7083 8.00002Z"
                      stroke="#D12835"
                    />
                    <path
                      d="M10.3333 8.00002C10.3333 6.71136 9.28865 5.66669 7.99999 5.66669C6.71133 5.66669 5.66666 6.71136 5.66666 8.00002C5.66666 9.28868 6.71133 10.3334 7.99999 10.3334C9.28865 10.3334 10.3333 9.28868 10.3333 8.00002Z"
                      fill="#D12835"
                    />
                  </svg> -->
                  <ng-container *ngIf="data?.isConfigured">
                    <svg width="24" height="24" style="margin-top: -2px" viewBox="0 0 24 24" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12Z"
                        fill="#4A9BFF" fill-opacity="0.3" />
                      <path
                        d="M23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5C18.3513 23.5 23.5 18.3513 23.5 12Z"
                        stroke="#294C77" />
                      <path
                        d="M16.2288 8.38903C16.2988 8.3178 16.3822 8.26121 16.4742 8.22259C16.5662 8.18396 16.665 8.16406 16.7648 8.16406C16.8647 8.16406 16.9635 8.18396 17.0555 8.22259C17.1475 8.26121 17.2309 8.3178 17.3008 8.38903C17.4414 8.53202 17.5211 8.72399 17.5229 8.92452C17.5248 9.12504 17.4488 9.31846 17.3108 9.46404L11.2578 16.623C11.189 16.6985 11.1055 16.7592 11.0125 16.8013C10.9194 16.8434 10.8188 16.8661 10.7166 16.8679C10.6145 16.8698 10.5131 16.8508 10.4186 16.8121C10.324 16.7735 10.2384 16.7159 10.1668 16.643L6.47984 12.914C6.33754 12.7691 6.25781 12.5741 6.25781 12.371C6.25781 12.1679 6.33754 11.9729 6.47984 11.828C6.54975 11.7568 6.63316 11.7002 6.72519 11.6616C6.81722 11.623 6.91603 11.6031 7.01584 11.6031C7.11565 11.6031 7.21446 11.623 7.30649 11.6616C7.39852 11.7002 7.48193 11.7568 7.55184 11.828L10.6778 14.992L16.2078 8.41403L16.2278 8.39103L16.2288 8.38903Z"
                        fill="#070D15" />
                    </svg>
                  </ng-container>
                  <ng-container *ngIf="!data?.isConfigured">
                    <svg width="24" height="24" style="margin-top: -2px" viewBox="0 0 103 102" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M102.75 51C102.75 22.8335 79.9165 0 51.75 0C23.5835 0 0.75 22.8335 0.75 51C0.75 79.1665 23.5835 102 51.75 102C79.9165 102 102.75 79.1665 102.75 51Z"
                        fill="#C23D31" fill-opacity="0.2" />
                      <path
                        d="M100.625 51C100.625 24.0071 78.7429 2.125 51.75 2.125C24.7571 2.125 2.875 24.0071 2.875 51C2.875 77.9929 24.7571 99.875 51.75 99.875C78.7429 99.875 100.625 77.9929 100.625 51Z"
                        stroke="#D12835" stroke-width="4.25" />
                      <path
                        d="M68.75 51C68.75 41.6112 61.1388 34 51.75 34C42.3612 34 34.75 41.6112 34.75 51C34.75 60.3888 42.3612 68 51.75 68C61.1388 68 68.75 60.3888 68.75 51Z"
                        fill="#D12835" />
                    </svg>
                  </ng-container>
                  <span class="items-name" (click)="chooseSingleRoom(data, ind)">
                    <span class="items-name-inner">{{ data.name }}</span>
                    <!-- <a>Tiles</a>
                    <a>Farbe</a>
                    <a>Herholt </a> -->
                  </span>
                  <span class="items-price">{{ data?.roomCost | number : "1.0-0" : "de-DE" }} €</span>
                </li>
                <!-- <li>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M14.7083 8.00002C14.7083 4.29511 11.7049 1.29169 8.00001 1.29169C4.2951 1.29169 1.29167 4.29511 1.29167 8.00002C1.29167 11.7049 4.2951 14.7084 8.00001 14.7084C11.7049 14.7084 14.7083 11.7049 14.7083 8.00002Z"
                      stroke="#294C77" />
                    <path
                      d="M10.4669 5.89362C10.5077 5.85207 10.5564 5.81906 10.6101 5.79653C10.6637 5.774 10.7214 5.76239 10.7796 5.76239C10.8378 5.76239 10.8955 5.774 10.9491 5.79653C11.0028 5.81906 11.0515 5.85207 11.0923 5.89362C11.1743 5.97703 11.2207 6.08902 11.2218 6.20599C11.2229 6.32296 11.1785 6.43579 11.0981 6.52071L7.56718 10.6968C7.52704 10.7408 7.47834 10.7762 7.42406 10.8008C7.36978 10.8253 7.31105 10.8385 7.25148 10.8396C7.19191 10.8407 7.13275 10.8297 7.0776 10.8071C7.02246 10.7846 6.97249 10.751 6.93076 10.7085L4.78001 8.53321C4.69701 8.44867 4.6505 8.33493 4.6505 8.21646C4.6505 8.09798 4.69701 7.98424 4.78001 7.89971C4.8208 7.85815 4.86945 7.82514 4.92314 7.80261C4.97682 7.78008 5.03446 7.76847 5.09268 7.76847C5.1509 7.76847 5.20854 7.78008 5.26223 7.80261C5.31591 7.82514 5.36457 7.85815 5.40535 7.89971L7.22885 9.74537L10.4547 5.90821L10.4663 5.89479L10.4669 5.89362Z"
                      fill="#070D15" />
                  </svg>

                  <span class="items-name">
                    <span class="items-name-inner">Kitchen</span>
                    <a>Tiles</a>
                    <a>Farbe</a>
                    <a>Herholt </a>
                  </span>
                  <span class="items-price">100 €</span>
                </li> -->

                <!-- <li>
                  <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8Z"
                      fill="#C23D31" fill-opacity="0.2" />
                    <path
                      d="M14.7083 8.00002C14.7083 4.29511 11.7049 1.29169 7.99999 1.29169C4.29508 1.29169 1.29166 4.29511 1.29166 8.00002C1.29166 11.7049 4.29508 14.7084 7.99999 14.7084C11.7049 14.7084 14.7083 11.7049 14.7083 8.00002Z"
                      stroke="#D12835" />
                    <path
                      d="M10.3333 8.00002C10.3333 6.71136 9.28865 5.66669 7.99999 5.66669C6.71133 5.66669 5.66666 6.71136 5.66666 8.00002C5.66666 9.28868 6.71133 10.3334 7.99999 10.3334C9.28865 10.3334 10.3333 9.28868 10.3333 8.00002Z"
                      fill="#D12835" />
                  </svg>
                  <span class="items-name">
                    <span class="items-name-inner">Kitchen</span>
                    <a>Tiles</a>
                    <a>Farbe</a>
                    <a>Herholt </a>
                  </span>
                  <span class="items-price">100 €</span>
                </li> -->

                <!-- <li>
                  <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8Z"
                      fill="#C23D31" fill-opacity="0.2" />
                    <path
                      d="M14.7083 8.00002C14.7083 4.29511 11.7049 1.29169 7.99999 1.29169C4.29508 1.29169 1.29166 4.29511 1.29166 8.00002C1.29166 11.7049 4.29508 14.7084 7.99999 14.7084C11.7049 14.7084 14.7083 11.7049 14.7083 8.00002Z"
                      stroke="#D12835" />
                    <path
                      d="M10.3333 8.00002C10.3333 6.71136 9.28865 5.66669 7.99999 5.66669C6.71133 5.66669 5.66666 6.71136 5.66666 8.00002C5.66666 9.28868 6.71133 10.3334 7.99999 10.3334C9.28865 10.3334 10.3333 9.28868 10.3333 8.00002Z"
                      fill="#D12835" />
                  </svg>
                  <span class="items-name">
                    <span class="items-name-inner">Kitchen</span>
                    <a>Tiles</a>
                    <a>Farbe</a>
                    <a>Herholt </a>
                  </span>
                  <span class="items-price">100 €</span>
                </li>

                <li>
                  <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8Z"
                      fill="#C23D31" fill-opacity="0.2" />
                    <path
                      d="M14.7083 8.00002C14.7083 4.29511 11.7049 1.29169 7.99999 1.29169C4.29508 1.29169 1.29166 4.29511 1.29166 8.00002C1.29166 11.7049 4.29508 14.7084 7.99999 14.7084C11.7049 14.7084 14.7083 11.7049 14.7083 8.00002Z"
                      stroke="#D12835" />
                    <path
                      d="M10.3333 8.00002C10.3333 6.71136 9.28865 5.66669 7.99999 5.66669C6.71133 5.66669 5.66666 6.71136 5.66666 8.00002C5.66666 9.28868 6.71133 10.3334 7.99999 10.3334C9.28865 10.3334 10.3333 9.28868 10.3333 8.00002Z"
                      fill="#D12835" />
                  </svg>
                  <span class="items-name">
                    <span class="items-name-inner">Kitchen</span>
                    <a>Tiles</a>
                    <a>Farbe</a>
                    <a>Herholt </a>
                    <a>Herholt </a>
                    <a>Herholt </a>
                    <a>Herholt </a>
                    <a>Herholt </a>
                  </span>
                  <span class="items-price">100 €</span>
                </li> -->
              </ul>
              <h4 class="d-flex align-items-center">
                {{ "shoppingcart.total" | translate }}
                <small> {{ "shoppingcart.gross" | translate }}</small>
                <span class="total-price">{{
                  _configuratorService.costodAllRooms
                  | number : "1.0-0" : "de-DE"
                  }}
                  €</span>
              </h4>
              <button type="button" class="btn btn-main mt-3 w-100" [disabled]="
                  (!_configuratorService.isProjectDateExpired
                    ? !isCompleted
                    : true) || activeProject?.details?.is_completed
                " (click)="configurationOrderComplete()">
                {{ (!activeProject?.details?.is_completed ? "shoppingcart.Complete" : "shoppingcart.completed") |
                translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Apply Items Modal -->
  <div class="modal fade" id="applyItems" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="applyItems" aria-hidden="true">
    <a type="button" class="aroundclose" data-bs-dismiss="modal" aria-label="Close"></a>
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

        <div class="modal-body">
          <h3 class="text-center">
            {{ "shoppingcart.applyitem" | translate
            }}<span class="titlebar mx-auto d-block"></span>
          </h3>
          <p class="body-text text-center m-0">
            {{ "shoppingcart.pleaseselecttheroomyouwnat" | translate }}
          </p>
          <h4>{{ currentSelectedModalProduct?.Product?.product_name }}</h4>
          <a class="selectall" (click)="selectAllFeatureToggle()">{{
            "shoppingcart.selectall" | translate
            }}</a>
          <form>
            <div class="doc-form">
              <div class="input-group" *ngFor="let data of templates">
                <input type="checkbox"
                  *ngIf="(data?.checkProductforRoom?.length==0) || (data?.checkProductforRoom?.length==0 && data?.product?.length == 0)"
                  class="select-input" [checked]="
                (data?.checked ||
                data?.id === temptypeId || 
                data?.product?.length > 0) || (data?.checkProductforRoom?.length==0 && data?.product?.length == 0)"
                  [disabled]="
                (data?.id === temptypeId || data?.product?.length > 0 || data?.checkProductforRoom?.length ==0) || !data?.isCatExists 
            " (click)="toggleCheckbox(data)" />

                <!-- " [disabled]="
              data.id === temptypeId || data?.product?.length > 0 || data.checkProductforRoom.length ==0
            "  -->

                <input type="checkbox" class="select-input"
                  *ngIf="!data?.checkProductforRoom?.length==0 && !(data?.checkProductforRoom?.length==0 && data?.product?.length == 0)"
                  [checked]="  (data?.checked ||
                  data?.id === temptypeId || 
                  data?.product?.length > 0) || (data?.isMaxLimitReached && data?.isCatExists)
            " [disabled]="
            (data?.id === temptypeId || data?.isMaxLimitReached || data?.product?.length > 0)  || !data?.isCatExists 
            " (click)="toggleCheckbox(data)" />

                <span class="checkbox">
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8.5" viewBox="0 0 11 8.5">
                    <path id="check-lg_white" data-name="check-lg white"
                      d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                      transform="translate(-3.001 -3.75)" fill="#fff" />
                  </svg>
                </span>
                <div class="chekedoverlay"></div>
                <span>{{ data.name }}</span>
                <p class="alreadytext" *ngIf="((data?.id === temptypeId || data?.product?.length > 0) 
                  || (data?.checkProductforRoom?.length==0 && data?.product?.length == 0))||((!data?.isCatExists))||data?.isMaxLimitReached ">
                <span  *ngIf="(data?.id === temptypeId || data?.product?.length > 0) 
                  || (data?.checkProductforRoom?.length==0 && data?.product?.length == 0)"> {{"shoppingcart.itemhasalreadybeenset" | translate}}</span>
                  <span  *ngIf="(!data?.isCatExists)"> {{"shoppingcart.catNotAdded" | translate}}</span>
                  <span  *ngIf="data?.isMaxLimitReached">{{"shoppingcart.maxlimitreached" | translate}}</span>
              </p>
                  <!-- <p class="alreadytext" *ngIf=" data.checkProductforRoom ==0"
                  [checked]="!data.checked || data.checkProductforRoom==0">
                  {{"shoppingcart.productnotavailableinthisroom" |
                  translate}}
                </p> -->
              </div>

              <!-- <div class="input-group">
                <input type="checkbox" class="disabled" disabled />
                <div class="chekedoverlay"></div>
                <span class="checkbox">
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8.5" viewBox="0 0 11 8.5">
                    <path id="check-lg_white" data-name="check-lg white"
                      d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                      transform="translate(-3.001 -3.75)" fill="#fff" />
                  </svg>
                </span>

                <span>Bedroom</span>
                <p class="alreadytext">Item has already been set.</p>
              </div>

              <div class="input-group">
                <input type="checkbox" />
                <span class="checkbox">
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8.5" viewBox="0 0 11 8.5">
                    <path id="check-lg_white" data-name="check-lg white"
                      d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                      transform="translate(-3.001 -3.75)" fill="#fff" />
                  </svg>
                </span>
                <div class="chekedoverlay"></div>
                <span>Living room</span>
              </div>

              <div class="input-group">
                <input type="checkbox" />
                <span class="checkbox">
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8.5" viewBox="0 0 11 8.5">
                    <path id="check-lg_white" data-name="check-lg white"
                      d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                      transform="translate(-3.001 -3.75)" fill="#fff" />
                  </svg>
                </span>
                <div class="chekedoverlay"></div>
                <span>Nursery 1</span>
              </div> -->
              <!-- 
              <div class="input-group">
                <input type="checkbox" />
                <span class="checkbox">
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8.5" viewBox="0 0 11 8.5">
                    <path id="check-lg_white" data-name="check-lg white"
                      d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                      transform="translate(-3.001 -3.75)" fill="#fff" />
                  </svg>
                </span>
                <div class="chekedoverlay"></div>
                <span>Nursery 2</span>
              </div> -->

              <!-- <div class="input-group">
                <input type="checkbox" />
                <span class="checkbox">
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8.5" viewBox="0 0 11 8.5">
                    <path id="check-lg_white" data-name="check-lg white"
                      d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                      transform="translate(-3.001 -3.75)" fill="#fff" />
                  </svg>
                </span>
                <div class="chekedoverlay"></div>
                <span>Hallway</span>
              </div> -->

              <!-- <div class="input-group">
                <input type="checkbox" />
                <span class="checkbox">
                  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8.5" viewBox="0 0 11 8.5">
                    <path id="check-lg_white" data-name="check-lg white"
                      d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                      transform="translate(-3.001 -3.75)" fill="#fff" />
                  </svg>
                </span>
                <div class="chekedoverlay"></div>
                <span>Bathroom</span>
              </div> -->
            </div>
            <div class="button-row d-flex align-items-center justify-content-between">
              <button type="button" class="btn btn-main btn-white" data-bs-dismiss="modal" aria-label="Close">
                {{ "shoppingcart.cancel" | translate }}
              </button>

              <button type="button" class="btn btn-main" data-bs-dismiss="modal" aria-label="Close"
                (click)="selectedApply()">
                {{ "shoppingcart.apply" | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- PDf  -->
  <!-- <h1 ngModel="myHeight">Height is {{content.offsetHeight}}</h1> -->
  <div id="content" #content style="
      margin: 0 auto;
      display: block;
      padding: 0 20px;
      position: absolute;
      bottom: -100%;
      top: auto;width: 100%;
      display: none;
      visibility: hidden;
    ">
    <table #myIdentifier style="max-width: 550px;width: 100%;">
      <tr>
        <td colspan="6">
          <h2 class="pt-3" style="
              text-align: center;
              font-size: 17px;
              font-weight: 900;
              word-spacing: 5px;
              color: rgb(22, 43, 69);
              margin-bottom: 0px;
              margin-top: 2px;
              display: block;
              border-radius: 2px; 
            ">
            {{ "shoppingcart.cartitemsfor" | translate }} - {{ selectedData }} |
            {{ setApartmentName(selectedData2) }}
          </h2>
          <div class="cart-items" *ngFor="let room of wishlistdata">
            <h3 class="ctg-title mt-2 pt-3" *ngIf="room.products" [ngStyle]="{ 'margin-bottom.px': '9' }" style="
                color: rgb(22, 43, 69);
                font-size: 14px;
                font-weight: 700;
                border-radius: 2px;
              ">
              {{ room.name }} <span class="titlebar"></span>
            </h3>
            <div *ngFor="let data of room.products">
              <div class="cart-item-wrapper  row mx-0 mt-2">
                <div class="col-3 col-md-3 col-lg-3 px-0">
                  <div class="cart-img-wrapper" style="padding: 2px;">
                    <img src="{{
                        data.Product.image
                          ? imgBaseUrl + data.Product.image
                          : 'assets/img/placeholder.png'
                      }}" style="
                        height: 100px;
                        width: 100%;
                        object-fit: cover;
                        max-width: 100%;
                      " />
                  </div>
                </div>
                <div class="col-9 col-md-9 col-lg-9 px-0">
                  <div class="d-flex cart-info" style="height: 100%">
                    <div class="item-info" style="width: 60%">
                      <h4 style="
                          word-spacing: 5px;
                          font-size: 12px;
                          max-width: 300px;
                          margin-top: 2px;
                        ">
                        {{ data.Product.product_name }}
                      </h4>
                      <p style="font-size: 8px">
                        {{ data.cat_name }}
                      </p>
                      <div class="extras" style="display: flex; margin-top: 12px"
                        *ngIf="data?.Product?.ProductFeatures?.length > 0">
                        <div class="d-flex flex-wrap">
                          <ng-container *ngFor="
                              let feature of data?.Product?.ProductFeatures;
                              index as featrueIndex
                            ">
                            <!-- previou check  -->
                            <!-- *ngIf="
                                (feature?.show_more
                                  ? featrueIndex <= feature?.show_limit
                                  : true) && feature?.initial
                              " -->
                            <!-- previou check  end-->
                            <div [class.selected]="feature?.isSelected" [class.d-none]="!feature?.isSelected" style="
                                background: transparent;
                                border: 0;
                                display: flex;
                                align-items: center;
                              ">
                              <h5 style="
                                  font-family: 'Roboto', sans-serif;
                                  font-size: 10px;
                                  margin-top: -10px;
                                ">
                                Extras:
                              </h5>

                              <span
                                style="text-overflow: inherit; overflow: inherit; max-width: 100%; width: auto; background: transparent; border: 0; margin: -10px 0 0 0; font-size: 7px; padding-bottom: 2px;font-weight: 600;"
                                [class.selected]="feature?.isSelected" [class.d-none]="!feature?.isSelected"
                                *ngIf="featrueIndex < 2" (click)="chooseFeatrue(obj, data, feature)">
                                <!-- <img
                                *ngIf="feature?.CatalogueFeature?.image"
                                [src]="
                                  feature?.CatalogueFeature?.image
                                    ? imgBaseUrl +
                                      feature?.CatalogueFeature?.image
                                    : 'assets/img/placeholder.png'
                                "
                                alt="{{
                                  feature?.CatalogueFeature?.label_name
                                }}"
                              />
                              {{ feature?.CatalogueFeature?.label_name }} -->
                                <img *ngIf="
                                    feature?.control_type === 'input' &&
                                    feature?.CatalogueFeature?.image
                                  " [src]="
                                    feature?.CatalogueFeature?.image
                                      ? imgBaseUrl +
                                        feature?.CatalogueFeature?.image
                                      : 'assets/img/placeholder.png'
                                  " alt="{{
                                    feature?.CatalogueFeature?.label_name
                                  }}" />

                                <img *ngIf="
                                    feature?.control_type === 'dropDown' &&
                                    feature?.control_img
                                  " [src]="
                                    feature?.control_img
                                      ? imgBaseUrl + feature?.control_img
                                      : 'assets/img/placeholder.png'
                                  " alt="{{ feature?.control_value }}" />
                                <!-- {{ feature?.control_value }} -->
                                <span class="name-extra"
                                  style="text-overflow: inherit; overflow: inherit; max-width: 100%; width: auto; font-size: 8px;background: white;padding: 1px 7px 3px;font-weight: bold;">
                                  {{ feature?.CatalogueFeature?.label_name }}
                                  ({{ feature?.control_value }})
                                </span>
                                <span class="additional-price" *ngIf="feature?.price">+
                                  {{
                                  +formatPrice(feature?.price) | number : "1.0-0" : "de-DE"
                                  }}
                                  €</span>
                              </span>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>

                    <div class="item-pricing" style="
                        width: 40%;
                        display: flex;
                        flex-flow: column;
                        height: 100%;
                        justify-content: space-between;
                      ">
                      <div class="price d-block align-items-center d-lg-block">
                        <div class="price" *ngIf="!data.Product.price" style="
                            text-align: right;
                            color: rgb(74, 155, 255);
                            font-size: 12px;
                            font-weight: 900;
                          ">
                          Included in standard
                        </div>
                        <div *ngIf="data.Product.price" style="
                            text-align: right;
                            color: rgb(74, 155, 255);
                            font-size: 14px;
                            font-weight: 900;
                          ">
                          {{
                          data?.type === "flat"
                          ? (data.Product.price
                          | number : "1.0-0" : "de-DE")
                          : (getvariantpricee(data)
                          | number : "1.0-0" : "de-DE")
                          }}
                          €
                        </div>
                      </div>
                      <div class="cart-quantity" style="margin-right: 0;">
                        <div class="d-flex align-items-center">
                          <!-- cart item -->

                          <div class="cartquantity" style="margin-right: 0;justify-content: flex-end;"
                            *ngIf="data?.type === 'flat'">
                            <p class="" style="font-size: 11px; margin-top: -5px; margin-bottom: 0;">
                              Total Quantity:
                            </p>

                            <input type="text"
                              style="margin-top: -5px; width: 20px; height: 25px; font-size: 10px; background: white; box-shadow: none; border: 0; border-radius: 2px; margin-left: 10px; color: #000;"
                              class="cartquantityvalue" [(ngModel)]="data.quantity" disabled />
                          </div>
                          <!-- cart item end -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td>
          <h4 style="
              text-align: right;
              font-size: 20px;
              font-weight: 600;
              word-spacing: 5px;padding-top: 25px;
            ">
            Total
            <small style="font-size: 7px; color: #777"> excl. MwSt.</small>
            <span style="
                font-size: 20px;
                padding-left: 21px;
                color: rgb(74, 155, 255);
                font-weight: 900;
              " class="total-price">{{
              _configuratorService.costodAllRooms | number : "1.0-0" : "de-DE"
              }}
              €</span>
          </h4>
        </td>
      </tr>
    </table>
  </div>

  <!-- <script src="https://code.jquery.com/jquery-1.12.3.min.js"></script>

  <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/0.9.0rc1/jspdf.min.js"></script> -->
  <!-- <button (click)="onExport()">Export PDF</button> -->
</main>

<div class="modal fade" id="viewNotes" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="viewNotes" aria-hidden="true">
  <a type="button" class="aroundclose" data-bs-dismiss="modal" aria-label="Close"></a>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

      <div class="modal-body">
        {{selectedProductNote?.product_note}}
      </div>
    </div>
  </div>
</div>
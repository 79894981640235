import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import html2canvas from 'html2canvas';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { constants } from 'src/app/shared/helpers/common/global-constants';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { CommonService } from 'src/app/shared/service/common.service';
import { LocalStorageService } from 'src/app/shared/storage/local-storage.service';
import { ConfiguratorService } from '../../../configurator/configurator.service';
import { DashboardService } from '../../../dashboard/dashboard.service';
import { WishlistService } from '../../../wishlist/wishlist.service';
import * as jspdf from 'jspdf';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationConfirmationComponent } from '../configuration-confirmation/configuration-confirmation.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  isDisabled: boolean = true;
  @ViewChild('pdfTable') pdfTable: ElementRef;

  @ViewChild('content') content: ElementRef;
  imgBaseUrl: string = '';
  reqIds: any = {};
  templates: any;
  wishListProducts: any[] = [];
  private constants = constants;
  itemquantity = false;
  quantity: number = 1;
  i = 1;
  form: FormGroup = this.fb.group({
    categories: this.fb.array([]),
  });
  subscription$: Subscription[] = [];
  newProjectList: any[];
  updatedProjectList: any[];
  toastr: any;
  wishlistdata: any;
  activeProject: any = null;
  activeTemplate: any;
  templateList: any[] = [];
  projectName: string = '';
  templateName: string = '';
  roomName: string = '';
  selectedData = 'Building Project 1';
  selectedData2 = '| Semi-detached house | 5';
  dimensions: any;
  isCompleted: boolean = false;
  temptypeId: number;
  checkedTemplate: boolean = false;
  templateTypeId: any;
  product_id: any;
  height: number;
  currentSelectedModalProduct: any;
  roomId: any = '';
  exportLimit: number = 0;
  exportType: any = '';
  isProductExist:boolean = false;
  isContentLoaded:boolean = false;
  isPdfDownloading:boolean = false;
  selectedProductNote:any;
  dropInnerIndex = 0;
  feature:any;
  selectedFeatureType:any = ''
  constructor(
    private service: WishlistService,
    public _configuratorService: ConfiguratorService,
    private _dashboardService: DashboardService,
    private _toastr: ToastrService,
    private _acRoute: ActivatedRoute,
    private _router: Router,
    private _commonService: CommonService,
    private fb: FormBuilder,
    private ls: LocalStorageService,
    private _translate: TranslateService,
    private _cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService
  ) {
    // if(sessionStorage?.configurationCompleted){
    //   let congigComplete = JSON.parse(sessionStorage.getItem('configurationCompleted'))
    //   if(congigComplete){
    //     this._router.navigate(['/'])
    //   }
    // }
    this._configuratorService.isPdfDownloading;
    this.imgBaseUrl = this._commonService.imgBaseUrl;
    this.fetchRequiredIDForGetRooms();
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.getAllClientProjectList(true);
    //alert(myHeight)
  }

  // formatPrice(price: any): any {
  //   console.log(price,'---price----');
    
  //   return parseFloat((price ?? 0).toFixed(2));
  // }
  formatPrice(price: any): any {
    const numericPrice = typeof price === 'number' ? price : 0;
    return parseFloat(numericPrice.toFixed(2));
  }

  configure() {
    !this._router.url.includes('home') &&
      this._router.navigate(['../' + APP_ROUTES.dashboardHome], {
        queryParams: {
          configure: true,
        },
      });

    this._router.url.includes('home') && this.getRefresh();

    /**
     * for configurator page
     */
    // this.fetchRequiredIDForGetRooms();
    // let queryParams = {
    //   tId: this.reqIds?.tId,
    //   pId: this.reqIds?.pId,
    // };
    // this._router.navigate(['/configurator'], {
    //   queryParams,
    // });

  }
  getRefresh() {
    this._commonService.isProjectFocused.next(true);
  }

  /**
   * get required id's
   */
  fetchRequiredIDForGetRooms(): void {
    if (localStorage.getItem(this.constants.requiredId)) {
      this.reqIds = JSON.parse(localStorage.getItem(this.constants.requiredId));
    }
  }

  getvariantpricee(data): number {
    return (
      (+data?.product_price > +data?.feature_price
        ? data?.product_price - +data?.feature_price
        : +data?.feature_price - +data?.product_price) || 0
    );
    // return +data?.product_price || 0;
  }

  deleteProduct(obj: any) {
    let payload = {
      id: obj.id,
    };
    this.spinner.show()
    this.service.deleteProduct(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.getAllClientProjectList();
          // this.getwishlistitembytempId();
        }
      },
      (err) => {
        this.toastr.error(err.error.message, '');
      }
    );
    setTimeout(() => {
      this.spinner.hide()
    }, 1000);
  }

  showroomItems(data) {
    console.log(data,'show room');
    
    this.product_id = data.product_id;
    this.templateTypeId = data.TemplateType.id;
    this.currentSelectedModalProduct = data;
    const wishlistData = {
      project_id: this.activeProject?.project?.id || this.reqIds?.pId,
      template_id: this.activeProject?.details?.template_id || this.reqIds?.tId,
      product_id: data.product_id,
      // template_type_id: this.reqIds?.roomId,
    };
    this.temptypeId = data.TemplateType.id;

    this.service.showroomItems(wishlistData).subscribe((data: any) => {
      this.templates = data?.body?.productWishlistRec;
      console.log(this.templates,'temp');
      
      for (let i = 0; i < this.templates?.length; i++) {
        this.templates[i].checked = false;
        if(!this.templates[i].checkProductforRoom){
          this.templates[i].checkProductforRoom = [1];
        }
        // this.templates[i].isMaxLimitReached = this.templates[i]?.catList?.length && this.templates[i]?.catList[0]?.configuredQty == this.templates[i]?.catList[0]?.totalQty;
        let index = 0;
        let ids = [];
        if(this.templates[i]?.catList?.length > 0){
          ids = this.templates[i]?.catList?.map(item=> +item?.catid)
          this.templates[i].isCatExists = ids.includes(+this.currentSelectedModalProduct?.category_id);
          index = ids.findIndex((item=> item === +this.currentSelectedModalProduct?.category_id))
        }else{
          this.templates[i].isCatExists = false;
        }
        if(index >= 0){
          this.templates[i].isMaxLimitReached = this.templates[i]?.catList?.length && this.templates[i]?.catList[index]?.added_cat == this.templates[i]?.catList[index]?.total_cat;
          console.log( this.templates[i].isMaxLimitReached ,'max limit if--');
        }else{
          this.templates[i].isMaxLimitReached = false;
          console.log( this.templates[i].isMaxLimitReached ,'max limit else');
        }
        if((this.templates[i]?.checkProductforRoom?.length==0) || (this.templates[i]?.checkProductforRoom?.length==0 && this.templates[i]?.product?.length == 0)){
          this.templates[i].isDisabledCat = ((this.templates[i]?.id === this.temptypeId || this.templates[i]?.isMaxLimitReached || this.templates[i]?.product?.length > 0) || !this.templates[i]?.isCatExists)
        }
        if((this.templates[i]?.checkProductforRoom?.length && !(this.templates[i]?.checkProductforRoom?.length==0 && this.templates[i]?.product?.length == 0))){
          this.templates[i].isDisabledCat = ((this.templates[i]?.id === this.temptypeId || this.templates[i]?.isMaxLimitReached || this.templates[i]?.product?.length > 0) || !this.templates[i]?.isCatExists)
        }
      
        
      }
    });
  }

  toggleCheckbox(template: any) {
    if(!template?.isCatExists){
      template.checked = false
      console.log('Product category is not added in room');
      return
    }else{
      for (let i = 0; i < this.templates.length; i++) {
        if (this.templates[i].id === template?.id) {
          if (this.templates[i].checked) {
            this.templates[i].checked = false;
          } else {
            this.templates[i].checked = true;
          }
        }
      }
    }
  }

  selectAllFeatureToggle() {
    this.checkedTemplate = !this.checkedTemplate;
    if (this.checkedTemplate) {
      for (let i = 0; i < this.templates.length; i++) {
        this.templates[i].checked = true;
      }
    } else {
      for (let i = 0; i < this.templates.length; i++) {
        this.templates[i].checked = false;
      }
    }
    // obj.isSelected = !obj.isSelected;
    // let selectedItems = this.templates.filter((x) => x?.isSelected).length;
    // let unSelectedItems = this.templates.filter((x) => !x?.isSelected).length;
    // if (this.templates?.length === selectedItems) {
    //   this.selectall = true;
    // }
    // if (this.featureList?.length >= unSelectedItems && unSelectedItems > 0) {
    //   this.selectall = false;
    // }
  }
  selectedApply() {
    let allData = [];
    for (let i = 0; i < this.templates.length; i++) {
      if (this.templates[i].checked) {
        allData.push(this.templates[i].id);
      }
    }
    let wishlistData = {
      project_id: this.activeProject?.project?.id || this.reqIds?.pId,
      template_id: this.activeProject?.details?.template_id || this.reqIds?.tId,
      product_id: this.product_id,
      template_type_id: this.templateTypeId,
      template_type_ids: allData,
      // template_type_id: this.reqIds?.roomId,
    };

    this.service.additemsinromms(wishlistData).subscribe((data) => {
      if (data?.body?.status) {
        this.modalService.dismissAll();
        this._toastr.success(data?.body?.message);
        this.getwishlistitembytempId(true);
        this.getAllClientProjectList();
      } else {
        this._toastr.error(data?.body?.message);
      }
    });
  }
  getprice(product): number {
    return (
      +product?.templateAreaPrice ||
      +product?.runningMeterAreaPrice ||
      +product?.templateAreaWallPrice
    );
  }
  getTotalQTY(allProds: any, product: any): number {
    let newarr =
      allProds?.length &&
      allProds
        ?.filter((prod) => prod?.TemplateType?.id === product?.TemplateType?.id)
        .filter((prod: any) => prod?.category_id === product?.category_id);
    if (newarr?.length === 1) return +newarr[0]?.quantity;
    return (
      newarr?.length > 1 &&
      newarr.reduce((a, b) => {
        return +a?.quantity + +b?.quantity;
      })
    );
  }
  showProductPrice(obj: any): number {
    return this._configuratorService.showProductPriceForDropdown(obj);
  }

  featurePriceForDropdownTypeCalculation(featureArray:any[],product:any):Array<any>{
    console.log(featureArray, 'feature arru dropp[[ww');
    console.log(product, 'product ee drop[[ww');
    if(product?.type === 'variant'){
      featureArray?.forEach((Ofeat=>{
        Ofeat?.feat_list?.forEach(feat=>{
          if(feat?.control_type === 'dropDown'){
            feat.price = +feat?.price * (this.totVariantPrice(product) || 0)
           }
        })
      }))
    }
    return featureArray
  }

  featurePriceForInputTypeCalculation(featureArray:any[],product:any):Array<any>{
    console.log(featureArray, 'feature arru');
    console.log(product, 'product ee');
    if(product?.type === 'variant'){
      featureArray?.forEach((feat=>{
       if(feat?.control_type === 'input'){
        feat.price = +feat?.price * (this.totVariantPrice(product) || 0)
       }
      }))
    }
    return featureArray
  }

  totVariantPrice(obj){
    if(+obj?.Product?.templateAreaPrice || obj?.Product.floor){
      return +obj?.Product?.template_area
    }
    if(+obj?.Product?.runningMeterAreaPrice || obj?.Product.running_meter){
      return +obj?.Product?.running_meter_area
    }
    if(+obj?.Product?.templateAreaWallPrice || obj?.Product.wall){
      return +obj?.Product?.template_area_wall
    }
  }

  getwishlistitembytempId(isCountSet:boolean) {
    const wishlistData = {
      project_id: this.activeProject?.project?.id || this.reqIds?.pId,
      template_id: this.activeProject?.details?.template_id || this.reqIds?.tId,
      template_type_id: this.reqIds?.roomId,
      client_id:this._configuratorService.client_id || 0
    };
    this._configuratorService
      .getwishlistitembytempId(wishlistData)
      .subscribe((data: any) => {
        console.log(data,'resp data 351');
        
        this.wishlistdata = [];
        let prodLength: any = 0;
        this.wishlistdata = data.productWishlistRec;
        if (data.status === true) {
          if (this.wishlistdata.length)
          this.isContentLoaded =true;
          this.selectedFeatureType = '';
            this.wishlistdata.forEach((data) => {
              this.roomId = data?.id;
              data.isConfigured =
                +data?.configuredcat === +data?.totalcat ? true : false;
              data.roomCost = this.calcTotal(data?.products) || 0;
              data?.products?.length &&
                data?.products?.forEach((elementp) => {
                  elementp['selectedFeatureType'] = '';
                  elementp['isOutOfStock']= 
                  elementp?.type !== 'variant'
                      ? +elementp?.quantity <= 0
                        ? true
                        : false
                      : false;
                      elementp['isSurfaceType']= elementp?.type === 'variant' ? true : false;
                      // elementp['is_featrue_optional'] = !elementp?.is_extra,
                  this.isProductExist = true;
                  if (elementp?.type === 'flat') {
                    elementp.productOriginalPrice = elementp?.Product?.price;
                  } else {
                    elementp.productOriginalPrice =
                      this.getprice(elementp?.Product) || 0;
                  }
                  this.featurePriceForInputTypeCalculation(elementp?.Product?.ProductFeatures,elementp)
                  let fullStr: number = 0;
                  if (+elementp?.cat_qty > +this.getTotalQTY(data?.products, elementp)) {
                    // if (+elementp?.cat_qty > +elementp?.quantity) {
                    elementp.isDisabled = false;
                  } else {
                    elementp.isDisabled = true;
                  }
                  elementp?.Product?.ProductFeatures?.length &&
                    elementp?.Product?.ProductFeatures?.every(
                      (element: any, index) => {
                        fullStr += +(
                          String(element?.CatalogueFeature?.label_name).length +
                          (String(element?.price).length + 2)
                        );
                        if (fullStr > this.constants.show_feature_limit) {
                          element.show_limit = index - 1;
                          element.show_more = true;
                          return false;
                        } else {
                          element.initial = true;
                          element.show_more = false;
                          return true;
                        }
                      }
                    );

                    let drop_arr = [];
                    elementp.Product.prod_drop_feature = [];
                let dropFeatArr = [];
                let all_drop_items = elementp.Product?.ProductFeatures?.filter(item=> item?.control_type === 'dropDown');
                all_drop_items = all_drop_items.filter(item=> ((+item?.CatalogueFeature?.catalogue_id)===(+elementp?.category_id)))
                console.log(all_drop_items,'all drop item line 414');
             
                drop_arr = all_drop_items?.reduce((prev, t, index, arr) => {
                  if (typeof prev[t.feature_id] === 'undefined') {
                    prev[t.feature_id] = [];
                  }
                  arr['is_feature_selected'] = false;
                  prev[t.feature_id].push(t);
                  return prev;
                }, {});
                Object.keys(drop_arr)?.forEach(i => {
                  let obj = {
                    selected_drop_feature: '',
                    is_outer_selected:false,
                    feat_list:drop_arr[i]
                  }
                  dropFeatArr.push(obj);
                });
                // dropFeatArr = dropFeatArr.filter(item=> ((+item?.CatalogueFeature?.catalogue_id)===(+elementp?.category_id)))
                elementp.Product.prod_drop_feature = dropFeatArr;
                this.featurePriceForDropdownTypeCalculation(elementp.Product.prod_drop_feature,elementp)
                for (var feat of elementp.Product.prod_drop_feature) {
                  for (var feature of feat?.feat_list) {
                    if (feature?.id === elementp?.product_feature_id) {
                      this.selectedFeatureType = feature?.control_type;
                      elementp['selectedFeatureType'] = feature?.control_type;
                      feat.is_outer_selected = true;
                      feature.is_feature_selected = true;
                      feat.selected_drop_feature = `${feature?.control_value}  +${this.formatPrice(feature?.price)}€`
                      elementp.feature_id =
                        +feature?.feature_id
                        elementp.product_feature_id=
                        +feature?.id
                        elementp.feature_price=
                        +this.formatPrice(feature?.price) || 0
                    }
                  }
                }
                elementp.Product.prod_drop_feature?.sort((x,y)=>{
                  return Number(y.is_outer_selected) - Number(x.is_outer_selected);
                 })
                });
               
              data?.products?.length &&
                data?.products?.forEach((element) => {
                  element?.Product?.ProductFeatures?.length &&
                    element?.Product?.ProductFeatures?.forEach((feat: any) => {
                      if (feat?.id === element?.ProductFeature?.id) {
                        feat.isSelected = true;
                        this.selectedFeatureType = feat?.control_type;
                        element['selectedFeatureType'] = feat?.control_type;
                      } else {
                        feat.isSelected = false;
                      }
                    });
                  element?.Product?.ProductFeatures?.sort((x,y)=>{
                      return Number(y.isSelected) - Number(x.isSelected);
                  })
                  prodLength += element?.quantity || 0;
                  if (this.isConfigured) {
                    console.log(prodLength,'-------prodlengthh set');
                    var projectCompleted = JSON.parse(localStorage.getItem('projectCompleted'));
                    console.log(projectCompleted,'--proj completed -----');
                    let rqId = JSON.parse(localStorage.getItem('reqId'))
                    let projectDetails = JSON.parse(localStorage.getItem('projectDetails'))
                    const isProjectCompleted = projectDetails.some(project => project.project_id === rqId.pId&& project.is_completed === 1);
                    if (isProjectCompleted == true) {
                      prodLength=0;
                    }
                   localStorage.setItem('configuredProjectListCount',JSON.stringify(prodLength))
                  }
                  element.Product.ProductFeatures = element?.Product?.ProductFeatures.filter(item=> ((+item?.CatalogueFeature?.catalogue_id)===(+element?.category_id)))
                });
              
                // data.products.filter(item => {
                //   if (item?.Product?.ProductCategories) {
                //       return item.Product.ProductCategories.some(element => {
                //         const catalogueId = +element.catalogue_id;
                //         const categoryId = +item.category_id;
                //         return catalogueId == categoryId;
                //       });
                //     }
                //   });
              // prodLength += data?.products?.length || 0;

            });
          if (this.wishlistdata.length)
            this.isCompleted = this.wishlistdata.filter(
              (data) => !data?.isConfigured
            ).length
              ? false
              : true;

          this._commonService.cartTotalLengthEvent$.next(prodLength);
          this.fetchRequiredIDForGetRooms();
          if (this.reqIds?.tId && !isCountSet) {
             if(+this.activeProject?.details?.template_id === +this.reqIds?.tId){
              // if (this.isConfigured) {
              //   console.log(prodLength,'-------prodlengthh set');
              //   var projectCompleted = JSON.parse(localStorage.getItem('projectCompleted'));
              //   console.log(projectCompleted,'--proj completed -----');
              //   if (projectCompleted == true) {
              //     prodLength=0;
              //   }
              //  localStorage.setItem('configuredProjectListCount',JSON.stringify(prodLength))
              // }
             }
           }
          this._configuratorService.costodAllRooms = data?.costofAllRooms || 0;
        }
      });
  }

  visibleDropFeature(prod:any):number{
    if(prod?.Product?.ProductFeatures?.length === 0){
          return 1
    }
    if(prod?.Product?.ProductFeatures?.length === 1){
      return 0
}
if(prod?.Product?.ProductFeatures?.length === 2){
  return -1
}
return 0
  }

  get isConfigured(): boolean {
    return JSON.parse(localStorage.getItem('ispConf'))
  }

  calcTotal(prodList: any[]) {
    return (
      prodList?.length &&
      prodList?.reduce(
        (previous, current) => previous + +current?.product_total_price,
        0
      )
    );
  }

  viewNotes(product:any){
    if(!product) return
    this.selectedProductNote = null;
    console.log(product);
    
    this.selectedProductNote = product;
  }

  /**
   * Implementing new api
   */
  getAllClientProjectList(isUpdated?: boolean) {
    this._dashboardService.getAllClientProjects().subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.newProjectList = [];
          this.newProjectList = data?.body?.projectTemplateRec;
          this.fetchRequiredIDForGetRooms();
          // let activeProject: any[] = this.newProjectList.filter(
          //   (p: any) => p?.id === this.reqIds?.pId
          // );
          // this.activeProject = activeProject[0]?.id;
          // this.templateList = activeProject[0]?.projectdetail;
          // let activeTemplate: any[] = this.templateList.filter(
          //   (t: any) => t?.template_id === this.reqIds?.tId
          // );
          // this.activeTemplate = activeTemplate[0]?.template_id;
          this.updatedProjectList = [];
          this.newProjectList.filter((list: any) => {
            return list?.projectdetail.map((details: any) => {
              this.updatedProjectList.push({
                progressPercentage:
                  (+details.roomAddedWhist / +details.totalRoom) * 100,
                details,
                project: list,
              });
            });
          });
          this.updatedProjectList.forEach(
            (n) => delete n?.project?.projectdetail
          );
          setTimeout(() => {
            let activeProject: any[] =
              this.reqIds?.pId && this.reqIds?.tId
                ? this.getActiveProject()
                : [this.updatedProjectList[0]];
            this.activeProject = activeProject[0];
            this.selectedData = this.activeProject?.project?.name;

            this.selectedData2 = ` | ${this.activeProject?.details?.Template?.name} | ${this.activeProject?.details?.apartment_name}`;

            this.activeTemplate = this.activeProject?.details?.template_id;
            this.getwishlistitembytempId(false);
                this.fetchRequiredIDForGetRooms()
          }, 200);
        }
      },
      (err) => {
        this.toastr.error(err.error.message, '');
      }
    );
  }

  getActiveProject() {
    if (!this.activeProject?.project?.id) {
      return this.updatedProjectList.filter(
        (p: any) =>
          p?.project?.id === this.reqIds?.pId &&
          p?.details?.template_id === this.reqIds?.tId
      );
    } else {
      return this.updatedProjectList.filter(
        (p: any) =>
          p?.project?.id === this.activeProject?.project?.id &&
          p?.details?.template_id === this.activeProject?.details?.template_id
      );
    }
  }

  chooseActiveProject(event: any, tempData: any): void {
    this.templateList =
      event.target?.selectedOptions[0]['project-data']?.projectdetail;
    this.activeTemplate = this.templateList[0]?.template_id;
  }
  chooseActiveTemplate(event: any, tempData: any): void {
    this.activeTemplate =
      event.target?.selectedOptions[0]['template-data']?.template_id;
  }

  OnClickupdatedata(p: any, pi: number) {
    // if(p?.details?.template_id === this.activeProject?.details?.template_id){
    //   return 
    // }
    this.isContentLoaded = false;
    this.isProductExist = false;
    this.selectedData = p?.project?.name;
    this.selectedData2 = ` | ${p?.details?.Template?.name} | ${p?.details?.apartment_name}`;
    this.activeProject = p;
    let queryParams = {
      ...this.reqIds,
      completed_date: new Date(
        this.activeProject?.project?.complete_project_date?.replace(/-/g, "/")
      ),
      // is_project_completed:this.activeProject?.details?.is_completed,
    };
    localStorage.setItem(
      this.constants.requiredId,
      JSON.stringify(queryParams)
    );
    this.fetchRequiredIDForGetRooms();
    this.getAllClientProjectList(true);
  }

  chooseSingleRoom(room: any, roomIndex: number): void {
    let queryParams = {
      tId: this.activeProject?.details?.template_id,
      pId: this.activeProject?.project?.id,
      apartment_name: this.activeProject?.details?.apartment_name,
      template_name: this.activeProject?.details?.Template?.name,
      completed_date: new Date(
        this.activeProject?.project?.complete_project_date?.replace(/-/g, "/")
      ),
      is_project_completed:this.activeProject?.details?.is_completed,
      roomId: room?.id,
    };
    localStorage.setItem(
      this.constants.requiredId,
      JSON.stringify(queryParams)
    );
    // let queryParams = { ...this.reqIds, roomId: room?.id };
    // this._configuratorService.activeRoomId$.next(room?.id);
    this._router.navigate(['configurator/main/item'], {
      queryParams: {
        rId: room?.id,
      },
    });
  }

  setApartmentName(data?: string): string {
    return `${this.activeProject?.details?.Template?.name} | ${this.activeProject?.details?.apartment_name}`;
  }

  addQunatity(parentCat: any, product: any) {
    console.log(product,'----product----');
    
    let maxQty = +product.Product?.quantity;
    if (product?.type === 'flat' ? +product?.quantity < maxQty : true) {
      let value = +product?.quantity + 1;
      product.quantity = value;
      // this.mapCategoryCurrentQuantity(parentCat, product.value);
      this.addedToCart(product, false);
    } else {
      this._toastr.error(
        this._translate.instant(
          maxQty > 1
            ? 'toastMessages.productquantityexceededs'
            : 'toastMessages.productquantityexceeded',
          {
            value: maxQty,
          }
        ),
        ''
      );
    }
    // this.addedToCart();
  }

  minusQunatity(parentCat: any, product: any) {
    console.log(product,'---product----');
    
    let value = +product?.quantity;
    if (value > 0) {
      // product.quantity + 1;
      product.quantity = +value - 1;
      // product.controls['quantity'].patchValue(value);
      // this.mapCategoryCurrentQuantity(parentCat, product.value);
      this.addedToCart(product, false);
    }
  }

  removeFromCartSurfaceProduct(parentCat: any, product: any) {
    product.controls['quantity'].patchValue(0);
    this._configuratorService.removeCartItems$.next(product);
    this.mapCategoryCurrentQuantity(parentCat, product.value);
  }
  /**
   * Patch value to current category quantity
   * @param parentCat Current single selected category with its productList
   * @param prod Current single product
   * @param isAdded Boolean if first time product added then its true.
   * @returns  @void
   */
  mapCategoryCurrentQuantity(
    parentCat: any,
    prod?: any,
    isAdded?: boolean
  ): void {
    parentCat.controls['cat_total_qty'].patchValue(
      this._configuratorService.getTotalSelectedQtyForSingleCategory(
        parentCat.value
      )
    );
    let categoryLimit: number = parentCat.controls['cat_qty'].value;
    let currentCatQty: number = parentCat.controls['cat_total_qty'].value;
    if (categoryLimit <= currentCatQty) {
      this._configuratorService.disabledCartOperation(parentCat, true);
    } else {
      this._configuratorService.disabledCartOperation(parentCat, false);
    }
    this.addedToCart(prod, isAdded);
  }

  quantityType(quantity: any): any {
    return typeof quantity;
  }
  
  // isInputFeatureExists(data:any):number{
  //   return data?.Product?.ProductFeatures?.filter(feat=> feat?.control_type === 'input')?.length;
  // }

  // isDropdownFeatureExists(data:any):number{
  //   return data?.Product?.ProductFeatures?.filter(feat=> feat?.control_type === 'dropDown').length;
  // }

  // getInputFeatureList(data:any):Array<any>{
  //   let featList:any[] = data?.Product?.ProductFeatures?.filter(feat=> feat?.control_type === 'input');
  //   if(featList?.length > 2){
  //     return featList.slice(0,2) as Array<any>
  //   }else{
  //     return featList || [] as Array<any>
  //   }
  // }

  getId(feature:any,index:any,iouter, j, i){
    console.log(feature);
    
   
    this.dropInnerIndex = index;
    console.log('spanCLicked',  this.dropInnerIndex);
    console.log(`extraImagee1${index}${iouter}${j}${i}`,'ffkfkf');
    this.feature = feature;
   
    // let ance:HTMLAnchorElement = document.getElementById(`extraImagee1${index}${iouter}${j}${i}`) as HTMLAnchorElement
    // ance.setAttribute('data-bs-toggle', 'modal');
    // ance.setAttribute('data-target', `extraImagee1${index}${iouter}${j}${i}`);
    // // // ance.href = '#extraImagee1'+index+iouter+j+i;
    // // ance.click()
  }
  
  handleClick(event: { stopPropagation: () => void }) {
    event.stopPropagation();
    return;
  }


  chooseDropFeaure(feature:any, parent:any, prod: any, cat: any) {
    prod.Product.ProductFeatures.length &&
    prod.Product.ProductFeatures?.forEach((element) => {
        element.isSelected = false;
    })
 
    let isOptional = false;
    prod.Product.prod_drop_feature.length &&
    prod.Product.prod_drop_feature?.forEach((el) => {
        el?.feat_list.every((element)=>{
          if (element.id === feature?.id) {
            if(prod?.Product?.is_extra && prod?.quantity>0){
              if(prod?.feature_id && element.is_feature_selected){
                this._toastr.error(
                  this._translate.instant(
                    'toastMessages.extraReq',
                  ),
                  ''
                );
                isOptional = true;
                return false
              }
            }
            el.selected_drop_feature = `${feature?.control_value}  +${this.formatPrice(feature?.price)}€`;
            el.is_outer_selected = !el.is_outer_selected;
            element.is_feature_selected = !element.is_feature_selected;
            if(!prod?.Product?.is_extra && !element.is_feature_selected){
              el.selected_drop_feature = '';
              el.is_outer_selected = false;
              element.is_feature_selected = false;
              }
            return false;
          } else {
            if (!(!element.is_feature_selected && prod?.Product?.is_extra)) {
              el.selected_drop_feature = ``;
              el.is_outer_selected = false;
              element.is_feature_selected = false;
              }
          }
          return true
        })
      });

      if(!isOptional){

      
        if (feature.is_feature_selected) {
          prod.feature_id = +feature?.feature_id;
          prod.product_feature_id = +feature?.id;
          prod.feature_price = +this.formatPrice(feature?.price) || 0;
        } else {
          prod.feature_id = '';
          prod.product_feature_id = '';
          prod.feature_price = 0;
        }
        prod.quantity > 0 && this.addedToCart(prod);
  }

  }

  chooseFeatrue(cat: any, prod: any, feature: any) {
    console.log(prod?.Product?.is_extra, 'extra');
    console.log(prod.Product.ProductFeatures, 'extra');
    prod.Product.prod_drop_feature.length &&
      prod.Product.prod_drop_feature?.forEach((el) => {
        el?.feat_list.every((element)=>{
          el.selected_drop_feature = '';
          el.is_outer_selected = false;
          element.is_feature_selected = false;
        })
      })
    let isOptional = false;
    prod.Product.ProductFeatures.length &&
      prod.Product.ProductFeatures?.every((element) => {
        console.log(element.id === feature?.id);
        
        if (element.id === feature?.id) {
          if(prod?.Product?.is_extra && prod?.Product?.quantity>0){
            if(prod.product_feature_id && element.isSelected){
              this._toastr.error(
                this._translate.instant(
                  'toastMessages.extraReq',
                ),
                ''
              );
              isOptional = true;
              return false
            }
          }
          element.isSelected = !element.isSelected;
        } else {
          element.isSelected = false;
        }
        return true
      });
      if(!isOptional){
    if (feature.isSelected) {
      prod.feature_id = +feature?.feature_id;
      prod.product_feature_id = +feature?.id;
      prod.feature_price = +this.formatPrice(feature?.price) || 0;
    } else {
      prod.feature_id = '';
      prod.product_feature_id = '';
      prod.feature_price = 0;
    }
    prod.quantity > 0 && this.addedToCart(prod);
      }
  }

  addedToCart(product: any, isAddedToCart?: boolean) {
    let wishlist_rec: any[] = [];
    let prod = { ...product };

    let varient = this._configuratorService.getVarientProductCost(product);
    let flat = this._configuratorService.getFlatProductCost(product);
    prod.product_price_wall = prod.templateAreaWallPrice
      ? prod.templateAreaWallPrice
      : 0;
    // prod.product_price =
    //   prod.type === 'variant'
    //     ? this.getFlatProductCost(product)
    //     : this.getVarientProductCost(product);
    // prod.product_price =
    //   prod.type === 'variant'
    //     ? this.getFlatProductCost(product)
    //     : this.getVarientProductCost(product);
    prod.product_price = prod.productOriginalPrice;
    prod.product_total_price =
      prod.type === 'variant'
        ? this.getFlatProductCost(product)
        : this.getVarientProductCost(product);
    prod.product_temp_price =
      prod.type === 'variant'
        ? this.getVarientCost(product)
        : this.getFlatCost(product);
    // prod.product_price_running_meter = prod.runningMeterAreaPrice
    //   ? prod.runningMeterAreaPrice
    //   : 0;
    // prod.template_area_wall = prod?.template_area_wall
    //   ? Number(prod?.template_area_wall)
    //   : 0;
    // prod.feature_price =
    //   prod.type === 'variant'
    //     ? this._configuratorService.calulateFeaturePrice(
    //         prod.feature_price,
    //         this.dimensions
    //       )
    //     : prod.feature_price;
    prod.feature_price = prod.feature_price;
    delete prod?.Product;
    delete prod?.Project;
    delete prod?.Template;
    delete prod?.TemplateType;
    delete prod?.TemplateTypeArea;
    wishlist_rec.push(prod);
    let payload = {
      project_id: this.reqIds?.pId,
      wishlist_rec,
    };
    this._configuratorService
      .addProductToWishlist(payload)
      .subscribe((data: any) => {
        // this.getwishlistitembytempId();
        this.getAllClientProjectList(false);
        if (data.status === 200) {
          // this._configuratorService.showcartitems$.next(true);
          isAddedToCart &&
            this._toastr.success(
              this._translate.instant('toastMessages.addedtocart'),
              ''
            );
          !isAddedToCart &&
            this._toastr.success(
              this._translate.instant('toastMessages.prodUpdated'),
              ''
            );
          setTimeout(() => {
            // this._configuratorService.showcartitems$.next(project);

            // latest comment 
            // this._configuratorService.showcartitems$.next(true);
          }, 300);
          // // cart update by using subject
          this._configuratorService.isShowingPercentageProgressObserver$.next(true);
        }
      });
  }

  redirectToProductDetail(cat: any, product: any): void {
    delete this.reqIds?.roomId;
    delete this.reqIds?.pId;
    delete this.reqIds?.tId;
    delete this.reqIds?.is_project_completed
    let queryParams = {
      ...this.reqIds,
      pId: product?.Project?.id,
      tId: product?.Template?.id,
      roomId: product?.TemplateType?.id,
      product_id: product?.product_id,
      cat_name: product?.cat_name,
      cat_id: product?.category_id,
      cat_qty: product?.cat_qty,
      is_project_completed:this.activeProject?.details.is_completed
    };
    localStorage.setItem(
      this.constants.requiredId,
      JSON.stringify(queryParams)
    );
    this._router.navigate([
      '/configurator/main/item-details/' + product?.product_id,
    ]);
  }

  getvarientprce(product) {
    let price =
      +product?.templateAreaPrice ||
      +product?.runningMeterAreaPrice ||
      +product?.templateAreaWallPrice;
    return +price || 0;
  }
  getFlatProductCost(product) {
    if (product?.type === 'variant') {
      // if (product?.product_price) {
      if (product?.productOriginalPrice) {
        // let price =
        //   +product?.templateAreaPrice ||
        //   +product?.runningMeterAreaPrice ||
        //   +product?.templateAreaWallPrice;
        // let price = +product?.product_price;
        let price = +product?.productOriginalPrice;

        if (product?.feature_price) {
          price = price + +product?.feature_price;
        }
        return price || 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  getVarientCost(product) {
    if (product?.type === 'variant') {
      if (product?.product_price) {
        let price =
          +product?.templateAreaPrice ||
          +product?.runningMeterAreaPrice ||
          +product?.templateAreaWallPrice;

        return price || 0;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }
  getVarientProductCost(product: any): number {
    if (product?.type === 'flat') {
      let price;
      if (+product?.product_price) {
        price = +product.quantity * +product?.Product?.price;
      } else {
        price = 0;
      }
      if (product?.feature_price) {
        price = price + Number(product?.feature_price) * +product.quantity;
      }
      return price || 0;
    } else {
      return 0;
    }
  }

  getFlatCost(product: any): number {
    if (product?.type === 'flat') {
      let price;
      if (+product?.product_price) {
        price = +product.quantity * +product?.Product?.price;
      } else {
        price = 0;
      }
      return price || 0;
    } else {
      return 0;
    }
  }
  /*
  public downloadAsPDF() {
    var data = this.pdfTable.nativeElement;
    html2canvas(data, { useCORS: true }).then((canvas) => {
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png');
      let pdf = new jspdf.jsPDF('p', 'mm', 'a4'); 
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      pdf.save('Vilgertshofer_Wishlist.pdf'); 
    });
  }*/

  onExport() {
    this._toastr.success(
      this._translate.instant(
        'toastMessages.Exportstart'
      ),
      ''
      
    );

    let downloadType = +this.exportType;
    this.fetchRequiredIDForGetRooms();
    let payload = {
      "project_id": this.activeProject?.project?.id || this.reqIds?.pId,
      "template_id": this.activeProject?.details?.template_id || this.reqIds?.tId,
      "template_type_id": this.roomId || this.reqIds?.roomId,
      "apartment_id": +this.activeProject?.details?.apartment_id || 0,
      "download_type": downloadType
    }
    this._configuratorService.isPdfDownloading = true;
    this.service.exportPdf(payload).subscribe((res: any) => {
      if (res?.status === 200) {
        let exportArray: any[] = [];
        if (downloadType === 1) {
          
          exportArray.push({ name: res?.body?.pdfWithPrice })
        } else if (downloadType === 2) {
          exportArray.push({ name: res?.body?.pdfWithoutPrice })
        } else {
          exportArray.push({ name: res?.body?.pdfWithPrice })
          exportArray.push({ name: res?.body?.pdfWithoutPrice })
        }
        for (let index = 0; index < exportArray.length; index++) {
          let item = exportArray[index];
          const byteCharacters = atob(item?.name.split(',')[1]);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement('a');
          let currentDate = new Date()
          link.setAttribute('download', `Vilgertshofer_articles_${currentDate}.pdf`);
          link.setAttribute('href', blobUrl);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
        this._configuratorService.isPdfDownloading = false;
        this._toastr.success(
          this._translate.instant('toastMessages.filedownloaded', {value:'PDF'}),
          ''
        );
        this.exportType = '';
      }
     
    }, (err) => {
      this._toastr.error(
        this._translate.instant('login.genricErrorTsFile'),
        ''
      );
      if(err){
        this._configuratorService.isPdfDownloading = false;
      }
    })
  }
  public onExport2() {
    var width = this.content.nativeElement.offsetWidth;
    this.height = this.content.nativeElement.offsetHeight;
    // console.log('Width:' + width);
    //console.log('Height: ' + this.height);
    const doc = new jspdf.jsPDF('p', 'px', 'a4');
    //alert(this.height + 50);
    const source = document.getElementById('content');
    //doc.text("Test", 40, 20);
    //doc.setFontSize(12)
    doc.html(source, {
      //margin: [49, 0, 57, 0],
      callback: function (pdf) {
        //console.warn(this.height);
        //alert(this.height);
        doc.save('Vilgertshofer_Wishlist'); // preview pdf file when exported
      },
    });
    // autotable(doc, {
    //   html: '#content',
    //   useCss: true
    // })
  }

  configurationOrderComplete(){
    let isValid = (!this._configuratorService.isProjectDateExpired
      ? !this.isCompleted
      : true) || this.activeProject?.details?.is_completed;
    if(isValid) return
    const modalRef = this.modalService.open(ConfigurationConfirmationComponent, {
      backdrop:'static',
      keyboard:false,
      centered:true,
    });
    modalRef.componentInstance.activeProject = this.activeProject;
    modalRef.result.then(
      (result) => {
        // Handle modal close event
        if (result === ModalDismissReasons.BACKDROP_CLICK) {
          // Do something when the modal is closed by clicking on the backdrop
        } else if (result === ModalDismissReasons.ESC) {
          // Do something when the modal is closed by pressing the ESC key
        } else {
          // Do something when the modal is closed by other means
        }
      },
      (reason) => {
        // Handle modal dismissal (e.g., when the user clicks outside the modal or presses ESC)
        console.log(`Modal dismissed with reason: ${reason}`);
      }
    );
  }
}

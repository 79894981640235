<main class="page-wrapper">
  <section class="py-md-5 pb-5 pt-3">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-12">
          <a class="backbutton mb-2" (click)="goBack()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10.771"
              height="20"
              viewBox="0 0 10.771 20"
              style="fill: #4a9bff; height: 12px; margin-top: -1px"
            >
              <path
                id="chevron-left_blue"
                data-name="chevron-left blue"
                d="M15.044,1.725a.769.769,0,0,1,0,1.089L6.357,11.5l8.687,8.685a.77.77,0,1,1-1.089,1.089l-9.23-9.23a.769.769,0,0,1,0-1.089l9.23-9.23a.769.769,0,0,1,1.089,0Z"
                transform="translate(-4.499 -1.499)"
                fill-rule="evenodd"
              />
            </svg>
            {{ "documentspage.back" | translate }}</a
          >
        </div>
        <div class="col-12 col-lg-9">
          <div class="cart-items-wrapper me-0 me-lg-4">
            <div
              class="cart-title d-block d-md-flex justify-content-between align-items-center"
            >
              <h2>{{ "documentspage.documents" | translate }}</h2>

              <div class="dropdown">
                <button
                  class="dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span class="droptext">
                    <span>{{ selectedData }}</span> {{ selectedData2 }}
                  </span>
                  <svg
                    class="select-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="7.54"
                    viewBox="0 0 14 7.54"
                  >
                    <path
                      id="chevron-down-dropdown"
                      d="M4.657,1.657a.538.538,0,0,1,.762,0L11.88,8.118a.538.538,0,0,1,0,.762L5.42,15.341a.539.539,0,1,1-.762-.762L10.738,8.5,4.657,2.42a.538.538,0,0,1,0-.762Z"
                      transform="translate(15.499 -4.499) rotate(90)"
                      fill="#4c89d3"
                      fill-rule="evenodd"
                    />
                  </svg>
                </button>
                <ul class="dropdown-menu">
                  <li *ngFor="let p of updatedProjectList; let pi = index">
                    <a
                      class="dropdown-item onhover"
                      (click)="OnClickupdatedata(p, pi)"
                    >
                      <span>{{ p?.project?.name }}</span> |
                      {{ p?.details?.Template?.name }} |
                      {{ p?.details?.apartment_name }}
                    </a>
                  </li>

                  <!-- <li>
                    <a class="dropdown-item onhover">
                      <span>Building Project 1</span> | Semi-detached house | 5
                    </a>
                  </li>

                  <li>
                    <a class="dropdown-item onhover">
                      <span>Building Project 1</span> | Semi-detached house | 5
                    </a>
                  </li> -->
                </ul>
              </div>

              <!-- <div class="select-property mt-4 mt-md-0">
                <select class="select-project">
                  <option>Building Project 1</option>
                  <option>Building Project 2</option>
                </select>
                <span>|</span>
                <select class="projects-assets">
                  <option>Semi-detached house | 5</option>
                  <option>Semi-detached house | 4</option>
                  <option>Semi-detached house | 6</option>
                </select>
                <svg
                  class="select-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="7.54"
                  viewBox="0 0 14 7.54"
                >
                  <path
                    id="chevron-down-dropdown"
                    d="M4.657,1.657a.538.538,0,0,1,.762,0L11.88,8.118a.538.538,0,0,1,0,.762L5.42,15.341a.539.539,0,1,1-.762-.762L10.738,8.5,4.657,2.42a.538.538,0,0,1,0-.762Z"
                    transform="translate(15.499 -4.499) rotate(90)"
                    fill="#4c89d3"
                    fill-rule="evenodd"
                  />
                </svg>
              </div> -->
            </div>
            <ng-container *ngIf="isDocumentExist">
            <div class="doc-items mt-4" *ngFor="let p of ProjectDocList">
              <h3 class="ctg-title">
                {{ p?.title }}<span class="titlebar"></span>
              </h3>

              <div
                class="doc-item-wrapper row mx-0 mt-3"
                *ngFor="let data of p.ClientDocuments"
              >
                <div class="col-12 col-md-6 col-lg-6 px-0">
                  <div class="pdf">
                  <svg xmlns="http://www.w3.org/2000/svg"  viewBox="10 20 70 50" width="50" height="50"><path d="M 25 11 C 20.029 11 16 15.029 16 20 L 16 52 C 16 56.971 20.029 61 25 61 L 47 61 C 51.971 61 56 56.971 56 52 L 56 31 L 42 31 C 38.686 31 36 28.314 36 25 L 36 11 L 25 11 z M 40 11.34375 L 40 25 C 40 26.105 40.896 27 42 27 L 55.65625 27 L 40 11.34375 z M 29 38 L 43 38 C 44.104 38 45 38.895 45 40 C 45 41.105 44.104 42 43 42 L 29 42 C 27.896 42 27 41.105 27 40 C 27 38.895 27.896 38 29 38 z M 29 47 L 43 47 C 44.104 47 45 47.895 45 49 C 45 50.105 44.104 51 43 51 L 29 51 C 27.896 51 27 50.105 27 49 C 27 47.895 27.896 47 29 47 z"/></svg>

                    <div class="doc-name">
                      {{ data.name }}
                      <span title="{{ data.document | documentmask }}">
                      {{
                       data.document | documentmask
                      }}
                    </span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6 col-lg-6 px-0">
                  <div class="doc-actions-wrapper">
                    <!-- <span class="date-time"
                      >{{
                        (data?.updatedAt ? data?.updatedAt : data?.createdAt)
                          | date : "dd-MM-yyyy"
                      }}
                      {{
                        (data?.updatedAt ? data?.updatedAt : data?.createdAt)
                          | date : "H:mm:ss"
                      }}</span
                    > -->
                    <span class="date-time"
                      >{{ data?.createdAt | date : "dd-MM-yyyy" }}
                      {{ data?.createdAt | date : "H:mm:ss" }}</span
                    >
                    <button
                      type="button"
                      class="btn btn-main"
                      (click)="shareDoc(data)"
                      [title]="'documentspage.actiontooltip1' | translate"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.911"
                        height="16.909"
                        viewBox="0 0 16.911 16.909"
                      >
                        <path
                          id="send"
                          d="M17.049.157a.538.538,0,0,1,.118.581L10.9,16.4a.808.808,0,0,1-1.431.134L6.048,11.158.67,7.736A.808.808,0,0,1,.8,6.3L16.467.04a.538.538,0,0,1,.581.118ZM7.123,10.843,10.1,15.514l5.1-12.741Zm7.308-8.831L1.691,7.109l4.672,2.972,8.069-8.068Z"
                          transform="translate(-0.295 -0.001)"
                          fill="#fff"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      class="btn btn-main"
                      (click)="downloadDoc(data)"
                      [title]="'documentspage.actiontooltip2' | translate"
                    >
                      <svg
                        id="download"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.384"
                        height="17.708"
                        viewBox="0 0 20.384 17.708"
                      >
                        <path
                          id="Path_39"
                          data-name="Path 39"
                          d="M.637,9.9a.637.637,0,0,1,.637.637v3.185A1.274,1.274,0,0,0,2.548,15H17.836a1.274,1.274,0,0,0,1.274-1.274V10.537a.637.637,0,0,1,1.274,0v3.185a2.548,2.548,0,0,1-2.548,2.548H2.548A2.548,2.548,0,0,1,0,13.722V10.537A.637.637,0,0,1,.637,9.9Z"
                          transform="translate(0 1.438)"
                          fill="#fff"
                        />
                        <path
                          id="Path_40"
                          data-name="Path 40"
                          d="M8.508,14.828a.637.637,0,0,0,.9,0l3.822-3.822a.638.638,0,0,0-.9-.9L9.6,12.839V1.637a.637.637,0,0,0-1.274,0v11.2L5.588,10.1a.638.638,0,1,0-.9.9Z"
                          transform="translate(1.233 -1)"
                          fill="#fff"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      class="btn btn-main btn-white"
                      data-bs-toggle="modal"
                      data-bs-target="#editDoc"
                      (click)="getDoclistById(data)"
                      [title]="'documentspage.actiontooltip3' | translate"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.719"
                        height="16.55"
                        viewBox="0 0 16.719 16.55"
                      >
                        <g id="pencil-square" transform="translate(-1 -0.5)">
                          <path
                            (click)="getDoclistById(data)"
                            id="Path_44"
                            data-name="Path 44"
                            d="M17.128,2.144a.571.571,0,0,1,0,.806l-1.19,1.192L13.655,1.858,14.845.667a.571.571,0,0,1,.807,0l1.476,1.476Zm-2,2.8L12.848,2.664,5.072,10.441a.571.571,0,0,0-.138.224L4.015,13.42a.285.285,0,0,0,.361.361l2.755-.919a.571.571,0,0,0,.224-.137l7.776-7.777Z"
                            transform="translate(0.424 0)"
                            fill="#070d15"
                          />
                          <path
                            id="Path_45"
                            data-name="Path 45"
                            d="M1,15.267a1.712,1.712,0,0,0,1.712,1.712H15.267a1.712,1.712,0,0,0,1.712-1.712V8.419a.571.571,0,0,0-1.141,0v6.848a.571.571,0,0,1-.571.571H2.712a.571.571,0,0,1-.571-.571V2.712a.571.571,0,0,1,.571-.571h7.419a.571.571,0,0,0,0-1.141H2.712A1.712,1.712,0,0,0,1,2.712Z"
                            transform="translate(0 0.071)"
                            fill="#070d15"
                            fill-rule="evenodd"
                          />
                        </g>
                      </svg>
                    </button>
                    <button
                      type="button"
                      class="btn btn-main btn-red"
                      (click)="DeleteDoc(data)"
                      [title]="'documentspage.actiontooltip4' | translate"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.208"
                        height="18.523"
                        viewBox="0 0 16.208 18.523"
                      >
                        <path
                          id="trash3"
                          d="M7.367,1.158h3.473a.579.579,0,0,1,.579.579V2.894H6.789V1.737A.579.579,0,0,1,7.367,1.158Zm5.21,1.737V1.737A1.737,1.737,0,0,0,10.841,0H7.367A1.737,1.737,0,0,0,5.631,1.737V2.894H1.579a.579.579,0,0,0,0,1.158H2.2l.988,12.341A2.315,2.315,0,0,0,5.5,18.523H12.71a2.316,2.316,0,0,0,2.309-2.131l.987-12.341h.623a.579.579,0,0,0,0-1.158H12.577Zm2.267,1.158L13.865,16.3a1.158,1.158,0,0,1-1.154,1.065H5.5A1.158,1.158,0,0,1,4.343,16.3L3.364,4.052ZM6.176,5.21a.579.579,0,0,1,.611.544l.579,9.841a.579.579,0,1,1-1.156.069l-.58-9.841a.579.579,0,0,1,.544-.614Zm5.856,0a.579.579,0,0,1,.544.614L12,15.664a.579.579,0,1,1-1.156-.069l.579-9.841a.579.579,0,0,1,.611-.544ZM9.1,5.21a.579.579,0,0,1,.579.579v9.841a.579.579,0,0,1-1.158,0V5.789A.579.579,0,0,1,9.1,5.21Z"
                          transform="translate(-1)"
                          fill="#fff"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <!-- <div class="doc-item-wrapper row mx-0 mt-3">
                <div class="col-12 col-md-6 col-lg-6 px-0">
                  <div class="pdf">
                    <svg
                      id="file-earmark-pdf_red"
                      data-name="file-earmark-pdf red"
                      xmlns="http://www.w3.org/2000/svg"
                      width="26.722"
                      height="35.629"
                      viewBox="0 0 26.722 35.629"
                    >
                      <path
                        id="Path_37"
                        data-name="Path 37"
                        d="M28.722,31.175V10.021L18.7,0H6.454A4.454,4.454,0,0,0,2,4.454V31.175a4.454,4.454,0,0,0,4.454,4.454H24.268A4.454,4.454,0,0,0,28.722,31.175ZM18.7,6.68a3.34,3.34,0,0,0,3.34,3.34h4.454V31.175A2.227,2.227,0,0,1,24.268,33.4H6.454a2.227,2.227,0,0,1-2.227-2.227V4.454A2.227,2.227,0,0,1,6.454,2.227H18.7Z"
                        transform="translate(-2)"
                        fill="#c23d31"
                      />
                      <path
                        id="Path_38"
                        data-name="Path 38"
                        d="M5.3,23.564a1.779,1.779,0,0,1-.987-.887,2.144,2.144,0,0,1,.18-2.326,5.894,5.894,0,0,1,2.022-1.661,17.939,17.939,0,0,1,3.34-1.362,40.474,40.474,0,0,0,2.394-4.7,14.677,14.677,0,0,1-.969-2.734,5.5,5.5,0,0,1-.1-2.4A2.313,2.313,0,0,1,12.64,5.756,2.5,2.5,0,0,1,14,5.594a1.568,1.568,0,0,1,1.075.771A2.489,2.489,0,0,1,15.358,7.5a6.327,6.327,0,0,1-.106,1.3,19.788,19.788,0,0,1-1.172,3.787,22.821,22.821,0,0,0,2.209,3.559,13.836,13.836,0,0,1,3.007.106,3.708,3.708,0,0,1,2.164.982,1.724,1.724,0,0,1,.451,1.094,2.332,2.332,0,0,1-.311,1.189,2.241,2.241,0,0,1-.8.878,2.029,2.029,0,0,1-1.15.291,3.947,3.947,0,0,1-2.1-.88A12.449,12.449,0,0,1,15.5,17.8a27.828,27.828,0,0,0-4.5.857,23.846,23.846,0,0,1-2.3,3.188A6.011,6.011,0,0,1,6.606,23.5,1.9,1.9,0,0,1,5.3,23.564Zm3.108-4.013c-.374.16-.721.329-1.035.5a4.338,4.338,0,0,0-1.458,1.155.655.655,0,0,0-.09.762.475.475,0,0,0,.059.093.627.627,0,0,0,.079-.025A4.51,4.51,0,0,0,7.393,20.83q.538-.617,1.014-1.279Zm3.7-2.808q1.129-.247,2.277-.407-.616-.882-1.15-1.811-.53,1.123-1.127,2.217Zm5.513.95a8.439,8.439,0,0,0,.981.866,2.309,2.309,0,0,0,1.123.54.255.255,0,0,0,.158-.032.654.654,0,0,0,.212-.264.878.878,0,0,0,.133-.422.194.194,0,0,0-.059-.133A2.131,2.131,0,0,0,19,17.807a9.314,9.314,0,0,0-1.379-.112Zm-4.486-7.4a13.4,13.4,0,0,0,.451-1.748,4.665,4.665,0,0,0,.086-.982,1.22,1.22,0,0,0-.072-.418,1.228,1.228,0,0,0-.327.084.721.721,0,0,0-.442.6,4.134,4.134,0,0,0,.1,1.735c.054.234.122.479.2.73Z"
                        transform="translate(0.78 7.685)"
                        fill="#c23d31"
                      />
                    </svg>
                    <div class="doc-name">
                      Description of construction
                      <span>1663599216915dlrw0uecal88w0r2t.pdf</span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6 col-lg-6 px-0">
                  <div class="doc-actions-wrapper">
                    <span class="date-time">19-09-2022 14:53:42</span>
                    <button type="button" class="btn btn-main">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.911"
                        height="16.909"
                        viewBox="0 0 16.911 16.909"
                      >
                        <path
                          id="send"
                          d="M17.049.157a.538.538,0,0,1,.118.581L10.9,16.4a.808.808,0,0,1-1.431.134L6.048,11.158.67,7.736A.808.808,0,0,1,.8,6.3L16.467.04a.538.538,0,0,1,.581.118ZM7.123,10.843,10.1,15.514l5.1-12.741Zm7.308-8.831L1.691,7.109l4.672,2.972,8.069-8.068Z"
                          transform="translate(-0.295 -0.001)"
                          fill="#fff"
                        />
                      </svg>
                    </button>
                    <button type="button" class="btn btn-main">
                      <svg
                        id="download"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.384"
                        height="17.708"
                        viewBox="0 0 20.384 17.708"
                      >
                        <path
                          id="Path_39"
                          data-name="Path 39"
                          d="M.637,9.9a.637.637,0,0,1,.637.637v3.185A1.274,1.274,0,0,0,2.548,15H17.836a1.274,1.274,0,0,0,1.274-1.274V10.537a.637.637,0,0,1,1.274,0v3.185a2.548,2.548,0,0,1-2.548,2.548H2.548A2.548,2.548,0,0,1,0,13.722V10.537A.637.637,0,0,1,.637,9.9Z"
                          transform="translate(0 1.438)"
                          fill="#fff"
                        />
                        <path
                          id="Path_40"
                          data-name="Path 40"
                          d="M8.508,14.828a.637.637,0,0,0,.9,0l3.822-3.822a.638.638,0,0,0-.9-.9L9.6,12.839V1.637a.637.637,0,0,0-1.274,0v11.2L5.588,10.1a.638.638,0,1,0-.9.9Z"
                          transform="translate(1.233 -1)"
                          fill="#fff"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      class="btn btn-main btn-white"
                      data-bs-toggle="modal"
                      data-bs-target="#editDoc"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.719"
                        height="16.55"
                        viewBox="0 0 16.719 16.55"
                      >
                        <g id="pencil-square" transform="translate(-1 -0.5)">
                          <path
                            id="Path_44"
                            data-name="Path 44"
                            d="M17.128,2.144a.571.571,0,0,1,0,.806l-1.19,1.192L13.655,1.858,14.845.667a.571.571,0,0,1,.807,0l1.476,1.476Zm-2,2.8L12.848,2.664,5.072,10.441a.571.571,0,0,0-.138.224L4.015,13.42a.285.285,0,0,0,.361.361l2.755-.919a.571.571,0,0,0,.224-.137l7.776-7.777Z"
                            transform="translate(0.424 0)"
                            fill="#070d15"
                          />
                          <path
                            id="Path_45"
                            data-name="Path 45"
                            d="M1,15.267a1.712,1.712,0,0,0,1.712,1.712H15.267a1.712,1.712,0,0,0,1.712-1.712V8.419a.571.571,0,0,0-1.141,0v6.848a.571.571,0,0,1-.571.571H2.712a.571.571,0,0,1-.571-.571V2.712a.571.571,0,0,1,.571-.571h7.419a.571.571,0,0,0,0-1.141H2.712A1.712,1.712,0,0,0,1,2.712Z"
                            transform="translate(0 0.071)"
                            fill="#070d15"
                            fill-rule="evenodd"
                          />
                        </g>
                      </svg>
                    </button>
                    <button type="button" class="btn btn-main btn-red">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.208"
                        height="18.523"
                        viewBox="0 0 16.208 18.523"
                      >
                        <path
                          id="trash3"
                          d="M7.367,1.158h3.473a.579.579,0,0,1,.579.579V2.894H6.789V1.737A.579.579,0,0,1,7.367,1.158Zm5.21,1.737V1.737A1.737,1.737,0,0,0,10.841,0H7.367A1.737,1.737,0,0,0,5.631,1.737V2.894H1.579a.579.579,0,0,0,0,1.158H2.2l.988,12.341A2.315,2.315,0,0,0,5.5,18.523H12.71a2.316,2.316,0,0,0,2.309-2.131l.987-12.341h.623a.579.579,0,0,0,0-1.158H12.577Zm2.267,1.158L13.865,16.3a1.158,1.158,0,0,1-1.154,1.065H5.5A1.158,1.158,0,0,1,4.343,16.3L3.364,4.052ZM6.176,5.21a.579.579,0,0,1,.611.544l.579,9.841a.579.579,0,1,1-1.156.069l-.58-9.841a.579.579,0,0,1,.544-.614Zm5.856,0a.579.579,0,0,1,.544.614L12,15.664a.579.579,0,1,1-1.156-.069l.579-9.841a.579.579,0,0,1,.611-.544ZM9.1,5.21a.579.579,0,0,1,.579.579v9.841a.579.579,0,0,1-1.158,0V5.789A.579.579,0,0,1,9.1,5.21Z"
                          transform="translate(-1)"
                          fill="#fff"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div class="doc-item-wrapper row mx-0 mt-3">
                <div class="col-12 col-md-6 col-lg-6 px-0">
                  <div class="pdf">
                    <svg
                      id="file-earmark-pdf_red"
                      data-name="file-earmark-pdf red"
                      xmlns="http://www.w3.org/2000/svg"
                      width="26.722"
                      height="35.629"
                      viewBox="0 0 26.722 35.629"
                    >
                      <path
                        id="Path_37"
                        data-name="Path 37"
                        d="M28.722,31.175V10.021L18.7,0H6.454A4.454,4.454,0,0,0,2,4.454V31.175a4.454,4.454,0,0,0,4.454,4.454H24.268A4.454,4.454,0,0,0,28.722,31.175ZM18.7,6.68a3.34,3.34,0,0,0,3.34,3.34h4.454V31.175A2.227,2.227,0,0,1,24.268,33.4H6.454a2.227,2.227,0,0,1-2.227-2.227V4.454A2.227,2.227,0,0,1,6.454,2.227H18.7Z"
                        transform="translate(-2)"
                        fill="#c23d31"
                      />
                      <path
                        id="Path_38"
                        data-name="Path 38"
                        d="M5.3,23.564a1.779,1.779,0,0,1-.987-.887,2.144,2.144,0,0,1,.18-2.326,5.894,5.894,0,0,1,2.022-1.661,17.939,17.939,0,0,1,3.34-1.362,40.474,40.474,0,0,0,2.394-4.7,14.677,14.677,0,0,1-.969-2.734,5.5,5.5,0,0,1-.1-2.4A2.313,2.313,0,0,1,12.64,5.756,2.5,2.5,0,0,1,14,5.594a1.568,1.568,0,0,1,1.075.771A2.489,2.489,0,0,1,15.358,7.5a6.327,6.327,0,0,1-.106,1.3,19.788,19.788,0,0,1-1.172,3.787,22.821,22.821,0,0,0,2.209,3.559,13.836,13.836,0,0,1,3.007.106,3.708,3.708,0,0,1,2.164.982,1.724,1.724,0,0,1,.451,1.094,2.332,2.332,0,0,1-.311,1.189,2.241,2.241,0,0,1-.8.878,2.029,2.029,0,0,1-1.15.291,3.947,3.947,0,0,1-2.1-.88A12.449,12.449,0,0,1,15.5,17.8a27.828,27.828,0,0,0-4.5.857,23.846,23.846,0,0,1-2.3,3.188A6.011,6.011,0,0,1,6.606,23.5,1.9,1.9,0,0,1,5.3,23.564Zm3.108-4.013c-.374.16-.721.329-1.035.5a4.338,4.338,0,0,0-1.458,1.155.655.655,0,0,0-.09.762.475.475,0,0,0,.059.093.627.627,0,0,0,.079-.025A4.51,4.51,0,0,0,7.393,20.83q.538-.617,1.014-1.279Zm3.7-2.808q1.129-.247,2.277-.407-.616-.882-1.15-1.811-.53,1.123-1.127,2.217Zm5.513.95a8.439,8.439,0,0,0,.981.866,2.309,2.309,0,0,0,1.123.54.255.255,0,0,0,.158-.032.654.654,0,0,0,.212-.264.878.878,0,0,0,.133-.422.194.194,0,0,0-.059-.133A2.131,2.131,0,0,0,19,17.807a9.314,9.314,0,0,0-1.379-.112Zm-4.486-7.4a13.4,13.4,0,0,0,.451-1.748,4.665,4.665,0,0,0,.086-.982,1.22,1.22,0,0,0-.072-.418,1.228,1.228,0,0,0-.327.084.721.721,0,0,0-.442.6,4.134,4.134,0,0,0,.1,1.735c.054.234.122.479.2.73Z"
                        transform="translate(0.78 7.685)"
                        fill="#c23d31"
                      />
                    </svg>
                    <div class="doc-name">
                      Energy pass
                      <span>1663599216915dlrw0uecal88w0r2t.pdf</span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6 col-lg-6 px-0">
                  <div class="doc-actions-wrapper">
                    <span class="date-time">19-09-2022 14:53:42</span>
                    <button type="button" class="btn btn-main">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.911"
                        height="16.909"
                        viewBox="0 0 16.911 16.909"
                      >
                        <path
                          id="send"
                          d="M17.049.157a.538.538,0,0,1,.118.581L10.9,16.4a.808.808,0,0,1-1.431.134L6.048,11.158.67,7.736A.808.808,0,0,1,.8,6.3L16.467.04a.538.538,0,0,1,.581.118ZM7.123,10.843,10.1,15.514l5.1-12.741Zm7.308-8.831L1.691,7.109l4.672,2.972,8.069-8.068Z"
                          transform="translate(-0.295 -0.001)"
                          fill="#fff"
                        />
                      </svg>
                    </button>
                    <button type="button" class="btn btn-main">
                      <svg
                        id="download"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.384"
                        height="17.708"
                        viewBox="0 0 20.384 17.708"
                      >
                        <path
                          id="Path_39"
                          data-name="Path 39"
                          d="M.637,9.9a.637.637,0,0,1,.637.637v3.185A1.274,1.274,0,0,0,2.548,15H17.836a1.274,1.274,0,0,0,1.274-1.274V10.537a.637.637,0,0,1,1.274,0v3.185a2.548,2.548,0,0,1-2.548,2.548H2.548A2.548,2.548,0,0,1,0,13.722V10.537A.637.637,0,0,1,.637,9.9Z"
                          transform="translate(0 1.438)"
                          fill="#fff"
                        />
                        <path
                          id="Path_40"
                          data-name="Path 40"
                          d="M8.508,14.828a.637.637,0,0,0,.9,0l3.822-3.822a.638.638,0,0,0-.9-.9L9.6,12.839V1.637a.637.637,0,0,0-1.274,0v11.2L5.588,10.1a.638.638,0,1,0-.9.9Z"
                          transform="translate(1.233 -1)"
                          fill="#fff"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      class="btn btn-main btn-white"
                      data-bs-toggle="modal"
                      data-bs-target="#editDoc"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.719"
                        height="16.55"
                        viewBox="0 0 16.719 16.55"
                      >
                        <g id="pencil-square" transform="translate(-1 -0.5)">
                          <path
                            id="Path_44"
                            data-name="Path 44"
                            d="M17.128,2.144a.571.571,0,0,1,0,.806l-1.19,1.192L13.655,1.858,14.845.667a.571.571,0,0,1,.807,0l1.476,1.476Zm-2,2.8L12.848,2.664,5.072,10.441a.571.571,0,0,0-.138.224L4.015,13.42a.285.285,0,0,0,.361.361l2.755-.919a.571.571,0,0,0,.224-.137l7.776-7.777Z"
                            transform="translate(0.424 0)"
                            fill="#070d15"
                          />
                          <path
                            id="Path_45"
                            data-name="Path 45"
                            d="M1,15.267a1.712,1.712,0,0,0,1.712,1.712H15.267a1.712,1.712,0,0,0,1.712-1.712V8.419a.571.571,0,0,0-1.141,0v6.848a.571.571,0,0,1-.571.571H2.712a.571.571,0,0,1-.571-.571V2.712a.571.571,0,0,1,.571-.571h7.419a.571.571,0,0,0,0-1.141H2.712A1.712,1.712,0,0,0,1,2.712Z"
                            transform="translate(0 0.071)"
                            fill="#070d15"
                            fill-rule="evenodd"
                          />
                        </g>
                      </svg>
                    </button>
                    <button type="button" class="btn btn-main btn-red">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.208"
                        height="18.523"
                        viewBox="0 0 16.208 18.523"
                      >
                        <path
                          id="trash3"
                          d="M7.367,1.158h3.473a.579.579,0,0,1,.579.579V2.894H6.789V1.737A.579.579,0,0,1,7.367,1.158Zm5.21,1.737V1.737A1.737,1.737,0,0,0,10.841,0H7.367A1.737,1.737,0,0,0,5.631,1.737V2.894H1.579a.579.579,0,0,0,0,1.158H2.2l.988,12.341A2.315,2.315,0,0,0,5.5,18.523H12.71a2.316,2.316,0,0,0,2.309-2.131l.987-12.341h.623a.579.579,0,0,0,0-1.158H12.577Zm2.267,1.158L13.865,16.3a1.158,1.158,0,0,1-1.154,1.065H5.5A1.158,1.158,0,0,1,4.343,16.3L3.364,4.052ZM6.176,5.21a.579.579,0,0,1,.611.544l.579,9.841a.579.579,0,1,1-1.156.069l-.58-9.841a.579.579,0,0,1,.544-.614Zm5.856,0a.579.579,0,0,1,.544.614L12,15.664a.579.579,0,1,1-1.156-.069l.579-9.841a.579.579,0,0,1,.611-.544ZM9.1,5.21a.579.579,0,0,1,.579.579v9.841a.579.579,0,0,1-1.158,0V5.789A.579.579,0,0,1,9.1,5.21Z"
                          transform="translate(-1)"
                          fill="#fff"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div> -->
            </div>
            </ng-container>
            <ng-container *ngIf="!isDocumentExist && isContentLoaded">
              <div class="documents-not-found">
                <h1>{{'notFound.notfoundtitle' | translate}}</h1>
                <small>{{'notFound.docnotfounddesc' | translate}}</small>
              </div>
            </ng-container>

            <!-- <div class="doc-items mt-5">
              <h3 class="ctg-title">
                Purchase documents <span class="titlebar"></span>
              </h3>

              <div class="doc-item-wrapper row mx-0 mt-3">
                <div class="col-12 col-md-6 col-lg-6 px-0">
                  <div class="pdf">
                    <svg
                      id="file-earmark-pdf_red"
                      data-name="file-earmark-pdf red"
                      xmlns="http://www.w3.org/2000/svg"
                      width="26.722"
                      height="35.629"
                      viewBox="0 0 26.722 35.629"
                    >
                      <path
                        id="Path_37"
                        data-name="Path 37"
                        d="M28.722,31.175V10.021L18.7,0H6.454A4.454,4.454,0,0,0,2,4.454V31.175a4.454,4.454,0,0,0,4.454,4.454H24.268A4.454,4.454,0,0,0,28.722,31.175ZM18.7,6.68a3.34,3.34,0,0,0,3.34,3.34h4.454V31.175A2.227,2.227,0,0,1,24.268,33.4H6.454a2.227,2.227,0,0,1-2.227-2.227V4.454A2.227,2.227,0,0,1,6.454,2.227H18.7Z"
                        transform="translate(-2)"
                        fill="#c23d31"
                      />
                      <path
                        id="Path_38"
                        data-name="Path 38"
                        d="M5.3,23.564a1.779,1.779,0,0,1-.987-.887,2.144,2.144,0,0,1,.18-2.326,5.894,5.894,0,0,1,2.022-1.661,17.939,17.939,0,0,1,3.34-1.362,40.474,40.474,0,0,0,2.394-4.7,14.677,14.677,0,0,1-.969-2.734,5.5,5.5,0,0,1-.1-2.4A2.313,2.313,0,0,1,12.64,5.756,2.5,2.5,0,0,1,14,5.594a1.568,1.568,0,0,1,1.075.771A2.489,2.489,0,0,1,15.358,7.5a6.327,6.327,0,0,1-.106,1.3,19.788,19.788,0,0,1-1.172,3.787,22.821,22.821,0,0,0,2.209,3.559,13.836,13.836,0,0,1,3.007.106,3.708,3.708,0,0,1,2.164.982,1.724,1.724,0,0,1,.451,1.094,2.332,2.332,0,0,1-.311,1.189,2.241,2.241,0,0,1-.8.878,2.029,2.029,0,0,1-1.15.291,3.947,3.947,0,0,1-2.1-.88A12.449,12.449,0,0,1,15.5,17.8a27.828,27.828,0,0,0-4.5.857,23.846,23.846,0,0,1-2.3,3.188A6.011,6.011,0,0,1,6.606,23.5,1.9,1.9,0,0,1,5.3,23.564Zm3.108-4.013c-.374.16-.721.329-1.035.5a4.338,4.338,0,0,0-1.458,1.155.655.655,0,0,0-.09.762.475.475,0,0,0,.059.093.627.627,0,0,0,.079-.025A4.51,4.51,0,0,0,7.393,20.83q.538-.617,1.014-1.279Zm3.7-2.808q1.129-.247,2.277-.407-.616-.882-1.15-1.811-.53,1.123-1.127,2.217Zm5.513.95a8.439,8.439,0,0,0,.981.866,2.309,2.309,0,0,0,1.123.54.255.255,0,0,0,.158-.032.654.654,0,0,0,.212-.264.878.878,0,0,0,.133-.422.194.194,0,0,0-.059-.133A2.131,2.131,0,0,0,19,17.807a9.314,9.314,0,0,0-1.379-.112Zm-4.486-7.4a13.4,13.4,0,0,0,.451-1.748,4.665,4.665,0,0,0,.086-.982,1.22,1.22,0,0,0-.072-.418,1.228,1.228,0,0,0-.327.084.721.721,0,0,0-.442.6,4.134,4.134,0,0,0,.1,1.735c.054.234.122.479.2.73Z"
                        transform="translate(0.78 7.685)"
                        fill="#c23d31"
                      />
                    </svg>
                    <div class="doc-name">
                      Notarial agreement
                      <span>1663599216915dlrw0uecal88w0r2t.pdf</span>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-6 col-lg-6 px-0">
                  <div class="doc-actions-wrapper">
                    <span class="date-time">19-09-2022 14:53:42</span>
                    <button type="button" class="btn btn-main">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.911"
                        height="16.909"
                        viewBox="0 0 16.911 16.909"
                      >
                        <path
                          id="send"
                          d="M17.049.157a.538.538,0,0,1,.118.581L10.9,16.4a.808.808,0,0,1-1.431.134L6.048,11.158.67,7.736A.808.808,0,0,1,.8,6.3L16.467.04a.538.538,0,0,1,.581.118ZM7.123,10.843,10.1,15.514l5.1-12.741Zm7.308-8.831L1.691,7.109l4.672,2.972,8.069-8.068Z"
                          transform="translate(-0.295 -0.001)"
                          fill="#fff"
                        />
                      </svg>
                    </button>
                    <button type="button" class="btn btn-main">
                      <svg
                        id="download"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.384"
                        height="17.708"
                        viewBox="0 0 20.384 17.708"
                      >
                        <path
                          id="Path_39"
                          data-name="Path 39"
                          d="M.637,9.9a.637.637,0,0,1,.637.637v3.185A1.274,1.274,0,0,0,2.548,15H17.836a1.274,1.274,0,0,0,1.274-1.274V10.537a.637.637,0,0,1,1.274,0v3.185a2.548,2.548,0,0,1-2.548,2.548H2.548A2.548,2.548,0,0,1,0,13.722V10.537A.637.637,0,0,1,.637,9.9Z"
                          transform="translate(0 1.438)"
                          fill="#fff"
                        />
                        <path
                          id="Path_40"
                          data-name="Path 40"
                          d="M8.508,14.828a.637.637,0,0,0,.9,0l3.822-3.822a.638.638,0,0,0-.9-.9L9.6,12.839V1.637a.637.637,0,0,0-1.274,0v11.2L5.588,10.1a.638.638,0,1,0-.9.9Z"
                          transform="translate(1.233 -1)"
                          fill="#fff"
                        />
                      </svg>
                    </button>
                    <button
                      type="button"
                      class="btn btn-main btn-white"
                      data-bs-toggle="modal"
                      data-bs-target="#editDoc"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.719"
                        height="16.55"
                        viewBox="0 0 16.719 16.55"
                      >
                        <g id="pencil-square" transform="translate(-1 -0.5)">
                          <path
                            id="Path_44"
                            data-name="Path 44"
                            d="M17.128,2.144a.571.571,0,0,1,0,.806l-1.19,1.192L13.655,1.858,14.845.667a.571.571,0,0,1,.807,0l1.476,1.476Zm-2,2.8L12.848,2.664,5.072,10.441a.571.571,0,0,0-.138.224L4.015,13.42a.285.285,0,0,0,.361.361l2.755-.919a.571.571,0,0,0,.224-.137l7.776-7.777Z"
                            transform="translate(0.424 0)"
                            fill="#070d15"
                          />
                          <path
                            id="Path_45"
                            data-name="Path 45"
                            d="M1,15.267a1.712,1.712,0,0,0,1.712,1.712H15.267a1.712,1.712,0,0,0,1.712-1.712V8.419a.571.571,0,0,0-1.141,0v6.848a.571.571,0,0,1-.571.571H2.712a.571.571,0,0,1-.571-.571V2.712a.571.571,0,0,1,.571-.571h7.419a.571.571,0,0,0,0-1.141H2.712A1.712,1.712,0,0,0,1,2.712Z"
                            transform="translate(0 0.071)"
                            fill="#070d15"
                            fill-rule="evenodd"
                          />
                        </g>
                      </svg>
                    </button>
                    <button type="button" class="btn btn-main btn-red">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.208"
                        height="18.523"
                        viewBox="0 0 16.208 18.523"
                      >
                        <path
                          id="trash3"
                          d="M7.367,1.158h3.473a.579.579,0,0,1,.579.579V2.894H6.789V1.737A.579.579,0,0,1,7.367,1.158Zm5.21,1.737V1.737A1.737,1.737,0,0,0,10.841,0H7.367A1.737,1.737,0,0,0,5.631,1.737V2.894H1.579a.579.579,0,0,0,0,1.158H2.2l.988,12.341A2.315,2.315,0,0,0,5.5,18.523H12.71a2.316,2.316,0,0,0,2.309-2.131l.987-12.341h.623a.579.579,0,0,0,0-1.158H12.577Zm2.267,1.158L13.865,16.3a1.158,1.158,0,0,1-1.154,1.065H5.5A1.158,1.158,0,0,1,4.343,16.3L3.364,4.052ZM6.176,5.21a.579.579,0,0,1,.611.544l.579,9.841a.579.579,0,1,1-1.156.069l-.58-9.841a.579.579,0,0,1,.544-.614Zm5.856,0a.579.579,0,0,1,.544.614L12,15.664a.579.579,0,1,1-1.156-.069l.579-9.841a.579.579,0,0,1,.611-.544ZM9.1,5.21a.579.579,0,0,1,.579.579v9.841a.579.579,0,0,1-1.158,0V5.789A.579.579,0,0,1,9.1,5.21Z"
                          transform="translate(-1)"
                          fill="#fff"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>

        <div class="col-12 col-lg-3 mt-4 mt-lg-0">
          <div class="sidebar">
            <button
              type="button"
              class="btn btn-main w-100"
              data-bs-toggle="modal"
              data-bs-target="#addDoc"
              (click)="addNewDoc()"
            >
              <svg
                id="file-earmark-plus"
                class="me-3"
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="16"
                viewBox="0 0 12 16"
              >
                <path
                  id="Path_35"
                  data-name="Path 35"
                  d="M8,6.5a.5.5,0,0,1,.5.5V8.5H10a.5.5,0,0,1,0,1H8.5V11a.5.5,0,0,1-1,0V9.5H6a.5.5,0,0,1,0-1H7.5V7A.5.5,0,0,1,8,6.5Z"
                  transform="translate(-2)"
                  fill="#fff"
                />
                <path
                  id="Path_36"
                  data-name="Path 36"
                  d="M14,4.5V14a2,2,0,0,1-2,2H4a2,2,0,0,1-2-2V2A2,2,0,0,1,4,0H9.5Zm-3,0A1.5,1.5,0,0,1,9.5,3V1H4A1,1,0,0,0,3,2V14a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V4.5Z"
                  transform="translate(-2)"
                  fill="#fff"
                />
              </svg>

              {{ "documentspage.addNewDocument" | translate }}
            </button>

            <div class="sidebar-items-box">
              <h3
                class="d-flex align-items-center justify-content-between flex-wrap"
              >
                <span class="">
                  {{ "documentspage.overview" | translate }}
                </span>
              </h3>
              <ul>
                <li *ngFor="let p of ProjectDocList">
                  <span class="items-name">{{ p?.title }}</span>
                  <span class="items-price">{{
                    p.ClientDocuments?.length
                  }}</span>
                </li>

                <!-- <li>
                  <span class="items-name">Purchase documents</span>
                  <span class="items-price">1 </span>
                </li>

                <li>
                  <span class="items-name">Delivery documents</span>
                  <span class="items-price">-</span>
                </li> -->
              </ul>
              <p *ngIf="ProjectDocList.length==0" class="text-center mt-1">
                {{ "documentspage.notFound" | translate }}  
              </p>
            </div>

            <div class="sidebar-items-box">
              <h3
                class="d-flex align-items-center justify-content-between flex-wrap"
              >
                <span class="">
                  {{ "documentspage.lastadded" | translate }}</span
                >
              </h3>
              <ul *ngIf="allFilteredDocs">
                <li *ngFor="let la of allFilteredDocs; let lai = index">
                  <span class="items-name"
                    >{{ la.name }}
                    <span class="lpname" title="{{ la?.document | documentmask }}">{{
                      la?.document | documentmask
                    }}</span>
                  </span>
                  <span class="date-time"
                    >{{ la?.createdAt | date : "dd-MM-yyyy" }}
                    <br />
                    {{ la?.createdAt | date : "H:mm:ss" }}</span
                  >
                </li>

                <!-- <li>
                  <span class="items-name"
                    >Purchase documents
                    <span class="lpname"
                      >1663599216915dlrw0uecal88w0r2t.pdf</span
                    ></span
                  >
                  <span class="date-time"
                    >19-09-2022 <br />
                    14:53:42</span
                  >
                </li>

                <li>
                  <span class="items-name"
                    >Delivery documents
                    <span class="lpname"
                      >1663599216915dlrw0uecal88w0r2t.pdf</span
                    ></span
                  >
                  <span class="date-time"
                    >19-09-2022 <br />
                    14:53:42</span
                  >
                </li> -->
              </ul>
              <p *ngIf="ProjectDocList.length==0" class="text-center">
                {{ "documentspage.notFound" | translate }}  
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Edit Doc Modal -->
  <div
    class="modal fade"
    id="editDoc"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="editDoc"
    aria-hidden="true"
    #editDoc
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          id="closeModaledit"
        ></button>

        <div class="modal-body">
          <h3 class="text-center">
            {{ "documentspage.editdocument" | translate
            }}<span class="titlebar mx-auto d-block"></span>
          </h3>
          <button
            type="button"
            class="btn btn-main mt-3 w-100 position-relative pointer"
          >
            <svg
              class="me-3"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="13.901"
              viewBox="0 0 16 13.901"
            >
              <g id="upload" transform="translate(0 -0.999)">
                <path
                  id="Path_42"
                  data-name="Path 42"
                  d="M.5,9.9a.5.5,0,0,1,.5.5v2.5a1,1,0,0,0,1,1H14a1,1,0,0,0,1-1V10.4a.5.5,0,0,1,1,0v2.5a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V10.4a.5.5,0,0,1,.5-.5Z"
                  fill="#fff"
                />
                <path
                  id="Path_43"
                  data-name="Path 43"
                  d="M7.646,1.146a.5.5,0,0,1,.708,0l3,3a.5.5,0,1,1-.708.708L8.5,2.707V11.5a.5.5,0,0,1-1,0V2.707L5.354,4.854a.5.5,0,0,1-.708-.708l3-3Z"
                  fill="#fff"
                />
              </g>
            </svg>
            {{ "documentspage.uploaddocument" | translate }}
            <input
              type="file"
              class="upload-input pointer"
              (change)="uploadDoc($event)"
              id="file-upload"
            />
          </button>
          <span class="fs-12 w-100 text-left">(  {{ "documentspage.supportDoc" | translate:{value:'pdf, png, jpeg, jpg'} }} )</span>
          <form [formGroup]="form" class="mt-2">
            <div class="doc-form">
              <div class="input-group have-icon">
                <input
                  type="text"
                  appDocMask
                  formControlName="pdf"
                  class="disabled"
                  disabled
                />
                <svg xmlns="http://www.w3.org/2000/svg" class="input-icon"  viewBox="0 0 72 72" width="25" height="56"><path d="M 25 11 C 20.029 11 16 15.029 16 20 L 16 52 C 16 56.971 20.029 61 25 61 L 47 61 C 51.971 61 56 56.971 56 52 L 56 31 L 42 31 C 38.686 31 36 28.314 36 25 L 36 11 L 25 11 z M 40 11.34375 L 40 25 C 40 26.105 40.896 27 42 27 L 55.65625 27 L 40 11.34375 z M 29 38 L 43 38 C 44.104 38 45 38.895 45 40 C 45 41.105 44.104 42 43 42 L 29 42 C 27.896 42 27 41.105 27 40 C 27 38.895 27.896 38 29 38 z M 29 47 L 43 47 C 44.104 47 45 47.895 45 49 C 45 50.105 44.104 51 43 51 L 29 51 C 27.896 51 27 50.105 27 49 C 27 47.895 27.896 47 29 47 z"/></svg>

                <!-- <svg
                  id="file-earmark-pdf_red"
                  class="input-icon"
                  data-name="file-earmark-pdf red"
                  xmlns="http://www.w3.org/2000/svg"
                  width="26.722"
                  height="35.629"
                  viewBox="0 0 26.722 35.629"
                >
                  <path
                    id="Path_37"
                    data-name="Path 37"
                    d="M28.722,31.175V10.021L18.7,0H6.454A4.454,4.454,0,0,0,2,4.454V31.175a4.454,4.454,0,0,0,4.454,4.454H24.268A4.454,4.454,0,0,0,28.722,31.175ZM18.7,6.68a3.34,3.34,0,0,0,3.34,3.34h4.454V31.175A2.227,2.227,0,0,1,24.268,33.4H6.454a2.227,2.227,0,0,1-2.227-2.227V4.454A2.227,2.227,0,0,1,6.454,2.227H18.7Z"
                    transform="translate(-2)"
                    fill="#c23d31"
                  />
                  <path
                    id="Path_38"
                    data-name="Path 38"
                    d="M5.3,23.564a1.779,1.779,0,0,1-.987-.887,2.144,2.144,0,0,1,.18-2.326,5.894,5.894,0,0,1,2.022-1.661,17.939,17.939,0,0,1,3.34-1.362,40.474,40.474,0,0,0,2.394-4.7,14.677,14.677,0,0,1-.969-2.734,5.5,5.5,0,0,1-.1-2.4A2.313,2.313,0,0,1,12.64,5.756,2.5,2.5,0,0,1,14,5.594a1.568,1.568,0,0,1,1.075.771A2.489,2.489,0,0,1,15.358,7.5a6.327,6.327,0,0,1-.106,1.3,19.788,19.788,0,0,1-1.172,3.787,22.821,22.821,0,0,0,2.209,3.559,13.836,13.836,0,0,1,3.007.106,3.708,3.708,0,0,1,2.164.982,1.724,1.724,0,0,1,.451,1.094,2.332,2.332,0,0,1-.311,1.189,2.241,2.241,0,0,1-.8.878,2.029,2.029,0,0,1-1.15.291,3.947,3.947,0,0,1-2.1-.88A12.449,12.449,0,0,1,15.5,17.8a27.828,27.828,0,0,0-4.5.857,23.846,23.846,0,0,1-2.3,3.188A6.011,6.011,0,0,1,6.606,23.5,1.9,1.9,0,0,1,5.3,23.564Zm3.108-4.013c-.374.16-.721.329-1.035.5a4.338,4.338,0,0,0-1.458,1.155.655.655,0,0,0-.09.762.475.475,0,0,0,.059.093.627.627,0,0,0,.079-.025A4.51,4.51,0,0,0,7.393,20.83q.538-.617,1.014-1.279Zm3.7-2.808q1.129-.247,2.277-.407-.616-.882-1.15-1.811-.53,1.123-1.127,2.217Zm5.513.95a8.439,8.439,0,0,0,.981.866,2.309,2.309,0,0,0,1.123.54.255.255,0,0,0,.158-.032.654.654,0,0,0,.212-.264.878.878,0,0,0,.133-.422.194.194,0,0,0-.059-.133A2.131,2.131,0,0,0,19,17.807a9.314,9.314,0,0,0-1.379-.112Zm-4.486-7.4a13.4,13.4,0,0,0,.451-1.748,4.665,4.665,0,0,0,.086-.982,1.22,1.22,0,0,0-.072-.418,1.228,1.228,0,0,0-.327.084.721.721,0,0,0-.442.6,4.134,4.134,0,0,0,.1,1.735c.054.234.122.479.2.73Z"
                    transform="translate(0.78 7.685)"
                    fill="#c23d31"
                  />
                </svg> -->
              </div>

              <div class="input-group">
                <input
                  type="text"
                  placeholder=" {{ 'documentspage.name' | translate }}"
                  formControlName="doc_name"
                />
                <ng-container
                  *ngIf="
                    form.controls['doc_name'].errors &&
                    (form.get('doc_name').dirty || form.get('doc_name').touched)
                  "
                >
                  <small
                    class="text-danger error-msg w-100 d-inline-block"
                    *ngIf="form.controls['doc_name'].hasError('required')"
                  >
                    *{{
                      "documentspage.documentnameisrequired" | translate
                    }}.</small
                  >
                  <small
                    class="text-danger error-msg w-100 d-inline-block"
                    *ngIf="form.controls['doc_name'].hasError('maxlength')"
                    >*{{
                      "documentspage.Maximumof30characters" | translate
                    }}.</small
                  >
                  <small
                    class="text-danger error-msg w-100 d-inline-block"
                    *ngIf="form.controls['doc_name'].hasError('pattern')"
                    >*{{
                      "documentspage.Nospecialcharacterallowed" | translate
                    }}.</small
                  >
                  <small
                    class="text-danger error-msg w-100 d-inline-block"
                    *ngIf="form.controls['doc_name'].hasError('whitespace')"
                    >*{{
                      "documentspage.Whitespacenotallowed" | translate
                    }}.</small
                  >
                </ng-container>
              </div>

              <div class="input-group mb-0">
                <select class="mb-0" formControlName="cat_id">
                  <option value="" [hidden]="form.controls?.cat_id?.value">
                    {{ "documentspage.selectdocumentcategory" | translate }}
                  </option>
                  <option
                    *ngFor="let dc of docCategoryList; let dci = index"
                    [value]="dc?.id"
                  >
                    {{ dc?.title }}
                  </option>
                  <!-- <option value="1">Property documents</option>
                  <option value="2">Purchase documents</option> -->
                </select>
                <span class="dn-arrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="7.54"
                    viewBox="0 0 14 7.54"
                  >
                    <path
                      id="chevron-right"
                      d="M.158.158a.538.538,0,0,1,.762,0L7.381,6.619a.538.538,0,0,1,0,.762L.921,13.842a.539.539,0,1,1-.762-.762L6.239,7,.158.921a.538.538,0,0,1,0-.762Z"
                      transform="translate(14) rotate(90)"
                      fill="#070d15"
                      fill-rule="evenodd"
                    />
                  </svg>
                </span>
                <ng-container
                  *ngIf="
                    form.controls['cat_id'].errors &&
                    (form.get('cat_id').dirty || form.get('cat_id').touched)
                  "
                >
                  <small
                    class="text-danger error-msg w-100 d-inline-block"
                    *ngIf="form.controls['cat_id'].hasError('required')"
                  >
                    *
                    {{
                      "documentspage.documentcategoryisrequired" | translate
                    }}.</small
                  >
                </ng-container>
              </div>
            </div>
            <div
              class="button-row d-flex align-items-center justify-content-between"
            >
              <button
                type="button"
                class="btn btn-main btn-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                {{ "documentspage.cancel" | translate }}
              </button>

              <button type="button" class="btn btn-main" (click)="updatedoc()">
                {{ "documentspage.save" | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Add Doc Modal -->
  <div
    class="modal fade"
    id="addDoc"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="addDoc"
    aria-hidden="true"
    #addDoc
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          id="closeModal"
        ></button>

        <div class="modal-body">
          <h3 class="text-center">
            {{ "documentspage.adddocument" | translate }}
            <span class="titlebar mx-auto d-block"></span>
          </h3>
          <button
            type="button"
            class="btn btn-main mt-3 w-100 position-relative pointer"
          >
            <svg
              class="me-3"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="13.901"
              viewBox="0 0 16 13.901"
            >
              <g id="upload" transform="translate(0 -0.999)">
                <path
                  id="Path_42"
                  data-name="Path 42"
                  d="M.5,9.9a.5.5,0,0,1,.5.5v2.5a1,1,0,0,0,1,1H14a1,1,0,0,0,1-1V10.4a.5.5,0,0,1,1,0v2.5a2,2,0,0,1-2,2H2a2,2,0,0,1-2-2V10.4a.5.5,0,0,1,.5-.5Z"
                  fill="#fff"
                />
                <path
                  id="Path_43"
                  data-name="Path 43"
                  d="M7.646,1.146a.5.5,0,0,1,.708,0l3,3a.5.5,0,1,1-.708.708L8.5,2.707V11.5a.5.5,0,0,1-1,0V2.707L5.354,4.854a.5.5,0,0,1-.708-.708l3-3Z"
                  fill="#fff"
                />
              </g>
            </svg>
            {{ "documentspage.uploaddocument" | translate }}
            <input
              type="file"
              class="upload-input pointer"
              (change)="uploadDoc($event)"
              id="file-upload"
            />
          </button>
          <span class="fs-12 w-100 text-left">(  {{ "documentspage.supportDoc" | translate:{value:'pdf, png, jpeg, jpg'} }} )</span>
          <form [formGroup]="form" class="mt-2">
            <div class="added-doc-wrapper">
              <div class="doc-form my-3">
                <div *ngIf="showHide" class="input-group have-icon">
                  <input
                    [hidden]="!pdfFormControl"
                    type="text"
                    appDocMask
                    class="disabled"
                    disabled
                    formControlName="pdf"
                  />
                  <svg xmlns="http://www.w3.org/2000/svg" class="input-icon"  viewBox="0 0 72 72" width="25" height="56"><path d="M 25 11 C 20.029 11 16 15.029 16 20 L 16 52 C 16 56.971 20.029 61 25 61 L 47 61 C 51.971 61 56 56.971 56 52 L 56 31 L 42 31 C 38.686 31 36 28.314 36 25 L 36 11 L 25 11 z M 40 11.34375 L 40 25 C 40 26.105 40.896 27 42 27 L 55.65625 27 L 40 11.34375 z M 29 38 L 43 38 C 44.104 38 45 38.895 45 40 C 45 41.105 44.104 42 43 42 L 29 42 C 27.896 42 27 41.105 27 40 C 27 38.895 27.896 38 29 38 z M 29 47 L 43 47 C 44.104 47 45 47.895 45 49 C 45 50.105 44.104 51 43 51 L 29 51 C 27.896 51 27 50.105 27 49 C 27 47.895 27.896 47 29 47 z"/></svg>

                  <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="input-icon input-icon-right"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      id="x-circle-fill"
                      d="M16,8A8,8,0,1,1,8,0a8,8,0,0,1,8,8ZM5.354,4.646a.5.5,0,0,0-.708.708L7.293,8,4.646,10.646a.5.5,0,0,0,.708.708L8,8.707l2.646,2.647a.5.5,0,0,0,.708-.708L8.707,8l2.647-2.646a.5.5,0,1,0-.708-.708L8,7.293Z"
                      (click)="pdfClose()"
                    />
                  </svg> -->
                </div>

                <div class="input-group">
                  <input
                    type="text"
                    placeholder="{{ 'documentspage.name' | translate }}"
                    formControlName="doc_name"
                  />
                  <ng-container
                    *ngIf="
                      form.controls['doc_name'].errors &&
                      (form.get('doc_name').dirty ||
                        form.get('doc_name').touched)
                    "
                  >
                    <small
                      class="text-danger error-msg w-100 d-inline-block"
                      *ngIf="
                        form.controls['doc_name'].hasError('required') &&
                        submitted
                      "
                    >
                      *
                      {{
                        "documentspage.documentnameisrequired" | translate
                      }}.</small
                    >
                    <small
                      class="text-danger error-msg w-100 d-inline-block"
                      *ngIf="form.controls['doc_name'].hasError('maxlength')"
                      >*{{
                        "documentspage.Maximumof30characters" | translate
                      }}.</small
                    >
                    <small
                      class="text-danger error-msg w-100 d-inline-block"
                      *ngIf="form.controls['doc_name'].hasError('pattern')"
                      >*
                      {{
                        "documentspage.Nospecialcharacterallowed" | translate
                      }}.</small
                    >
                    <small
                      class="text-danger error-msg w-100 d-inline-block"
                      *ngIf="
                        !form.controls['doc_name'].hasError('required') &&
                        form.controls['doc_name'].hasError('whitespace')
                      "
                      >*
                      {{
                        "documentspage.Whitespacenotallowed" | translate
                      }}.</small
                    >
                  </ng-container>
                </div>
                <!-- <div class="input-group mb-0">
                  <select
                    class="mb-0"
                    formControlName="cat_id"
                    (change)="changeCity($event)"
                  >
                    <option>Select Category</option>
                    <option *ngFor="let cat of CategoryList">
                      {{ cat }}
                    </option>
                  </select>
                </div> -->
                <div class="input-group mb-0">
                  <select
                    class="mb-0 form-select"
                    formControlName="cat_id"
                    style="padding-right: 28px"
                  >
                    <option value="" [hidden]="form.controls?.cat_id?.value">
                      {{ "documentspage.selectdocumentcategory" | translate }}
                    </option>
                    <option
                      *ngFor="let dc of docCategoryList; let dci = index"
                      [value]="dc?.id"
                    >
                      {{ dc?.title }}
                    </option>
                  </select>
                  <span class="dn-arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="7.54"
                      viewBox="0 0 14 7.54"
                    >
                      <path
                        id="chevron-right"
                        d="M.158.158a.538.538,0,0,1,.762,0L7.381,6.619a.538.538,0,0,1,0,.762L.921,13.842a.539.539,0,1,1-.762-.762L6.239,7,.158.921a.538.538,0,0,1,0-.762Z"
                        transform="translate(14) rotate(90)"
                        fill="#070d15"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </span>
                  <ng-container
                    *ngIf="
                      form.controls['cat_id'].errors &&
                      (form.get('cat_id').dirty || form.get('cat_id').touched)
                    "
                  >
                    <small
                      class="text-danger error-msg w-100 d-inline-block"
                      *ngIf="
                        form.controls['cat_id'].hasError('required') &&
                        submitted
                      "
                    >
                      *
                      {{
                        "documentspage.documentcategoryisrequired" | translate
                      }}.</small
                    >
                  </ng-container>
                </div>
              </div>
              <!-- <div class="doc-form my-3">
                <div class="input-group have-icon">
                  <input
                    type="text"
                    value="1663599216915dlrw0uecal88w0r2t.pdf"
                    class="disabled"
                    disabled
                  />
                  <svg
                    id="file-earmark-pdf_red"
                    class="input-icon"
                    data-name="file-earmark-pdf red"
                    xmlns="http://www.w3.org/2000/svg"
                    width="26.722"
                    height="35.629"
                    viewBox="0 0 26.722 35.629"
                  >
                    <path
                      id="Path_37"
                      data-name="Path 37"
                      d="M28.722,31.175V10.021L18.7,0H6.454A4.454,4.454,0,0,0,2,4.454V31.175a4.454,4.454,0,0,0,4.454,4.454H24.268A4.454,4.454,0,0,0,28.722,31.175ZM18.7,6.68a3.34,3.34,0,0,0,3.34,3.34h4.454V31.175A2.227,2.227,0,0,1,24.268,33.4H6.454a2.227,2.227,0,0,1-2.227-2.227V4.454A2.227,2.227,0,0,1,6.454,2.227H18.7Z"
                      transform="translate(-2)"
                      fill="#c23d31"
                    />
                    <path
                      id="Path_38"
                      data-name="Path 38"
                      d="M5.3,23.564a1.779,1.779,0,0,1-.987-.887,2.144,2.144,0,0,1,.18-2.326,5.894,5.894,0,0,1,2.022-1.661,17.939,17.939,0,0,1,3.34-1.362,40.474,40.474,0,0,0,2.394-4.7,14.677,14.677,0,0,1-.969-2.734,5.5,5.5,0,0,1-.1-2.4A2.313,2.313,0,0,1,12.64,5.756,2.5,2.5,0,0,1,14,5.594a1.568,1.568,0,0,1,1.075.771A2.489,2.489,0,0,1,15.358,7.5a6.327,6.327,0,0,1-.106,1.3,19.788,19.788,0,0,1-1.172,3.787,22.821,22.821,0,0,0,2.209,3.559,13.836,13.836,0,0,1,3.007.106,3.708,3.708,0,0,1,2.164.982,1.724,1.724,0,0,1,.451,1.094,2.332,2.332,0,0,1-.311,1.189,2.241,2.241,0,0,1-.8.878,2.029,2.029,0,0,1-1.15.291,3.947,3.947,0,0,1-2.1-.88A12.449,12.449,0,0,1,15.5,17.8a27.828,27.828,0,0,0-4.5.857,23.846,23.846,0,0,1-2.3,3.188A6.011,6.011,0,0,1,6.606,23.5,1.9,1.9,0,0,1,5.3,23.564Zm3.108-4.013c-.374.16-.721.329-1.035.5a4.338,4.338,0,0,0-1.458,1.155.655.655,0,0,0-.09.762.475.475,0,0,0,.059.093.627.627,0,0,0,.079-.025A4.51,4.51,0,0,0,7.393,20.83q.538-.617,1.014-1.279Zm3.7-2.808q1.129-.247,2.277-.407-.616-.882-1.15-1.811-.53,1.123-1.127,2.217Zm5.513.95a8.439,8.439,0,0,0,.981.866,2.309,2.309,0,0,0,1.123.54.255.255,0,0,0,.158-.032.654.654,0,0,0,.212-.264.878.878,0,0,0,.133-.422.194.194,0,0,0-.059-.133A2.131,2.131,0,0,0,19,17.807a9.314,9.314,0,0,0-1.379-.112Zm-4.486-7.4a13.4,13.4,0,0,0,.451-1.748,4.665,4.665,0,0,0,.086-.982,1.22,1.22,0,0,0-.072-.418,1.228,1.228,0,0,0-.327.084.721.721,0,0,0-.442.6,4.134,4.134,0,0,0,.1,1.735c.054.234.122.479.2.73Z"
                      transform="translate(0.78 7.685)"
                      fill="#c23d31"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="input-icon input-icon-right"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <path
                      id="x-circle-fill"
                      d="M16,8A8,8,0,1,1,8,0a8,8,0,0,1,8,8ZM5.354,4.646a.5.5,0,0,0-.708.708L7.293,8,4.646,10.646a.5.5,0,0,0,.708.708L8,8.707l2.646,2.647a.5.5,0,0,0,.708-.708L8.707,8l2.647-2.646a.5.5,0,1,0-.708-.708L8,7.293Z"
                    />
                  </svg>
                </div>

                <div class="input-group">
                  <input type="text" value="Expose" placeholder="name" />
                </div>

                <div class="input-group mb-0">
                  <select class="mb-0">
                    <option>Property documents</option>
                    <option>Purchase documents</option>
                  </select>
                </div>
              </div> -->
            </div>

            <div
              class="button-row d-flex align-items-center justify-content-between"
            >
              <button
                type="button"
                class="btn btn-main w-100"
                (click)="uploadProjectDoc()"
              >
                {{ "documentspage.save" | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</main>

<div class="deletepopup" *ngIf="deletePopup">
  <div class="popup-box">
    <h5>{{ "myprofile.areyousureyouwanttodelete" | translate }}</h5>
    <div class="mt-4 d-flex align-items-center flex-wrap">
      <button
        class="btn btn-main btn-white"
        style="box-shadow: none"
        (click)="hideDeletepopup()"
      >
        {{ "myprofile.cancel" | translate }}
      </button>
      <button
        class="mx-auto btn btn-main btn-red ms-md-3 mt-2 mt-md-0 mx-auto me-md-0"
        style="box-shadow: none"
        (click)="deleteDocs()"
      >
        {{ "documentspage.doc" | translate }}
      </button>
    </div>
    <button
      type="button"
      data-bs-dismiss="modal"
      aria-label="Close"
      class="btn-close"
      (click)="hideDeletepopup()"
    ></button>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  public getClientProjectList(): Observable<any> {
    const url: string = ApiRoutes.getClientProjectList;
    return this.http.get(url, { observe: 'response' });
  }

  public getTemplateAndUnitsList(payload: any): Observable<any> {
    const url: string = ApiRoutes.getTemplateAndUnitsList;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getRooms(payload: any): Observable<any> {
    const url: string = ApiRoutes.getRooms;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getProductList(payload: any): Observable<any> {
    const url: string = ApiRoutes.getProductList;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getProductDetails(payload: any): Observable<any> {
    const url: string = ApiRoutes.getProductDetails;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public addProductToWishlist(payload: any): Observable<any> {
    const url: string = ApiRoutes.addProductToWishlist;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getTemplateDetails(payload: any): Observable<any> {
    const url: string = ApiRoutes.templateDetails;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getAllClientProjects(): Observable<any> {
    const url: string = ApiRoutes.getAllClientProjects;
    return this.http.get(url, { observe: 'response' });
  }

  public getUnReadMessages(): Observable<any> {
    const url: string = ApiRoutes.getUnReadMessages;
    return this.http.get(url, { observe: 'response' });
  }
}

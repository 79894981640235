import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';
import { FileSaverService } from 'ngx-filesaver';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {

  messages:any=0;
  constructor(private http: HttpClient,
    private _FileSaverService: FileSaverService) { }


  onDown(type: string, fromRemote: boolean) {
    const fileName = type;
    if (fromRemote) {
      this.http.get(environment.imgBaseUrl + type, {
        observe: 'response',
        responseType: 'blob'
      }).subscribe(res => {
        this._FileSaverService.save(res.body, fileName);
      });
      return;
    }
  }

  public sendMessage(payload: any): Observable<any> {
    const url: string = ApiRoutes.sendMessage;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public isMessageRead(payload: any): Observable<any> {
    const url: string = ApiRoutes.isMessageRead;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getMessageHistory(payload: any): Observable<any> {
    const url: string = ApiRoutes.getUploadedDocList;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public uploadDoc(payload: any): Observable<any> {
    const url: string = ApiRoutes.uploadDoc;
    return this.http.post(url, payload, { observe: 'response' });
  }

  public getUploadedDocList(payload: any): Observable<any> {
    const url: string = ApiRoutes.getUploadedDocList;
    return this.http.post(url, payload, { observe: 'response' });
  }

  get client_id(): any {
    if(localStorage.currentUser){
      return JSON.parse(localStorage.getItem('currentUser'))?.id || null;
    }
    if(sessionStorage.currentUser){
      return JSON.parse(sessionStorage.getItem('currentUser'))?.id || null;
    }
  }
}

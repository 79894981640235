<main class="page-wrapper">
  <section class="pt-5 pb-5">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-12">
          <div class="titlebox">
            <h2>
              {{ "timeline.messages" | translate }}
              <span class="titlebar"></span>
            </h2>
          </div>

          <section class="msger mx-auto">
            <!-- <header class="msger-header">
                            <div class="msger-header-title">
                                <i class="fas fa-comment-alt"></i> {{'timeline.messages' | translate}}
                            </div>
                            
                        </header> -->

            <main class="msger-chat">
              <ng-container
                *ngFor="let msg of msgHistory; index as i; let last = last"
              >
                <div
                  [id]="last ? 'islast' : ''"
                  class="msg left-msg"
                  *ngIf="msg.sender_type === 'client'"
                >
                  <div
                    *ngIf="!clientInfo.image"
                    class="msg-img"
                    style="
                      background-image: url(https://image.flaticon.com/icons/svg/327/327779.svg);
                    "
                  ></div>
                  <div
                    *ngIf="clientInfo.image"
                    class="msg-img"
                    style="background-image: url({{
                      imgBaseUrl + clientInfo?.image
                    }})"
                  ></div>

                  <div class="msg-bubble">
                    <div class="msg-info">
                      <div class="msg-info-name">
                        {{ msg?.senderInfo?.first_name | titlecase }}
                        {{ msg?.senderInfo?.last_name | titlecase }}
                      </div>
                      <div class="msg-info-time">
                        {{
                          msg?.createdAt
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join("-")
                        }}
                        {{ msg?.createdAt.split("T")[1].split(".")[0].split("+")[0] }}
                      </div>
                    </div>

                    <div class="body-text pt-2 wd-wrap" *ngIf="!msg?.document">
                      {{ msg?.message }}
                    </div>
                    <div class="body-text pt-2 wd-wrap" *ngIf="msg?.document">
                      <span>
                        <!-- <svg id="file-earmark-pdf_red" class="file-pos" data-name="file-earmark-pdf red"
                          xmlns="http://www.w3.org/2000/svg" width="16.722" height="25.629" viewBox="0 0 26.722 35.629">
                          <path id="Path_37" data-name="Path 37"
                            d="M28.722,31.175V10.021L18.7,0H6.454A4.454,4.454,0,0,0,2,4.454V31.175a4.454,4.454,0,0,0,4.454,4.454H24.268A4.454,4.454,0,0,0,28.722,31.175ZM18.7,6.68a3.34,3.34,0,0,0,3.34,3.34h4.454V31.175A2.227,2.227,0,0,1,24.268,33.4H6.454a2.227,2.227,0,0,1-2.227-2.227V4.454A2.227,2.227,0,0,1,6.454,2.227H18.7Z"
                            transform="translate(-2)" fill="#c23d31" />
                          <path id="Path_38" data-name="Path 38"
                            d="M5.3,23.564a1.779,1.779,0,0,1-.987-.887,2.144,2.144,0,0,1,.18-2.326,5.894,5.894,0,0,1,2.022-1.661,17.939,17.939,0,0,1,3.34-1.362,40.474,40.474,0,0,0,2.394-4.7,14.677,14.677,0,0,1-.969-2.734,5.5,5.5,0,0,1-.1-2.4A2.313,2.313,0,0,1,12.64,5.756,2.5,2.5,0,0,1,14,5.594a1.568,1.568,0,0,1,1.075.771A2.489,2.489,0,0,1,15.358,7.5a6.327,6.327,0,0,1-.106,1.3,19.788,19.788,0,0,1-1.172,3.787,22.821,22.821,0,0,0,2.209,3.559,13.836,13.836,0,0,1,3.007.106,3.708,3.708,0,0,1,2.164.982,1.724,1.724,0,0,1,.451,1.094,2.332,2.332,0,0,1-.311,1.189,2.241,2.241,0,0,1-.8.878,2.029,2.029,0,0,1-1.15.291,3.947,3.947,0,0,1-2.1-.88A12.449,12.449,0,0,1,15.5,17.8a27.828,27.828,0,0,0-4.5.857,23.846,23.846,0,0,1-2.3,3.188A6.011,6.011,0,0,1,6.606,23.5,1.9,1.9,0,0,1,5.3,23.564Zm3.108-4.013c-.374.16-.721.329-1.035.5a4.338,4.338,0,0,0-1.458,1.155.655.655,0,0,0-.09.762.475.475,0,0,0,.059.093.627.627,0,0,0,.079-.025A4.51,4.51,0,0,0,7.393,20.83q.538-.617,1.014-1.279Zm3.7-2.808q1.129-.247,2.277-.407-.616-.882-1.15-1.811-.53,1.123-1.127,2.217Zm5.513.95a8.439,8.439,0,0,0,.981.866,2.309,2.309,0,0,0,1.123.54.255.255,0,0,0,.158-.032.654.654,0,0,0,.212-.264.878.878,0,0,0,.133-.422.194.194,0,0,0-.059-.133A2.131,2.131,0,0,0,19,17.807a9.314,9.314,0,0,0-1.379-.112Zm-4.486-7.4a13.4,13.4,0,0,0,.451-1.748,4.665,4.665,0,0,0,.086-.982,1.22,1.22,0,0,0-.072-.418,1.228,1.228,0,0,0-.327.084.721.721,0,0,0-.442.6,4.134,4.134,0,0,0,.1,1.735c.054.234.122.479.2.73Z"
                            transform="translate(0.78 7.685)" fill="#c23d31" />
                        </svg> -->
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="file-pos"
                          viewBox="0 0 100 50"
                          width="50"
                          height="50"
                        >
                          <path
                            d="M 25 11 C 20.029 11 16 15.029 16 20 L 16 52 C 16 56.971 20.029 61 25 61 L 47 61 C 51.971 61 56 56.971 56 52 L 56 31 L 42 31 C 38.686 31 36 28.314 36 25 L 36 11 L 25 11 z M 40 11.34375 L 40 25 C 40 26.105 40.896 27 42 27 L 55.65625 27 L 40 11.34375 z M 29 38 L 43 38 C 44.104 38 45 38.895 45 40 C 45 41.105 44.104 42 43 42 L 29 42 C 27.896 42 27 41.105 27 40 C 27 38.895 27.896 38 29 38 z M 29 47 L 43 47 C 44.104 47 45 47.895 45 49 C 45 50.105 44.104 51 43 51 L 29 51 C 27.896 51 27 50.105 27 49 C 27 47.895 27.896 47 29 47 z"
                          />
                        </svg>

                        <span class="ml-1"
                          >{{ msg?.document | chatMask }}
                        </span></span
                      >
                    </div>
                  </div>
                </div>

                <div
                  [id]="last ? 'islast' : ''"
                  class="msg right-msg"
                  *ngIf="
                    msg.sender_type === 'admin' && msg.receiver_id === senderId
                  "
                >
                  <div
                    class="msg-img"
                    style="
                      background-image: url(https://image.flaticon.com/icons/svg/145/145867.svg);
                    "
                  ></div>

                  <div class="msg-bubble">
                    <div class="msg-info">
                      <div class="msg-info-name">
                        {{ msg?.senderInfo?.firstname | titlecase }}
                        {{ msg?.senderInfo?.lastname | titlecase }}
                      </div>
                      <div class="msg-info-time">
                        {{
                          msg?.createdAt
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join("-")
                        }}
                       {{ msg?.createdAt.split("T")[1].split(".")[0].split("+")[0] }}
                      </div>
                    </div>

                    <!-- <div class="body-text pt-2">
                      {{ msg?.message }}
                    </div> -->
                    <div class="body-text pt-2 wd-wrap" *ngIf="!msg?.document">
                      {{ msg?.message }}
                    </div>
                    <div
                      class="body-text pt-2 wd-wrap d-flex align-items-center justify-content-between"
                      *ngIf="msg?.document"
                    >
                      <span class="doc_name92">
                        <svg
                          id="file-earmark-pdf_red"
                          class="file-pos"
                          data-name="file-earmark-pdf red"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.722"
                          height="25.629"
                          viewBox="0 0 26.722 35.629"
                        >
                          <path
                            id="Path_37"
                            data-name="Path 37"
                            d="M28.722,31.175V10.021L18.7,0H6.454A4.454,4.454,0,0,0,2,4.454V31.175a4.454,4.454,0,0,0,4.454,4.454H24.268A4.454,4.454,0,0,0,28.722,31.175ZM18.7,6.68a3.34,3.34,0,0,0,3.34,3.34h4.454V31.175A2.227,2.227,0,0,1,24.268,33.4H6.454a2.227,2.227,0,0,1-2.227-2.227V4.454A2.227,2.227,0,0,1,6.454,2.227H18.7Z"
                            transform="translate(-2)"
                            fill="#c23d31"
                          />
                          <path
                            id="Path_38"
                            data-name="Path 38"
                            d="M5.3,23.564a1.779,1.779,0,0,1-.987-.887,2.144,2.144,0,0,1,.18-2.326,5.894,5.894,0,0,1,2.022-1.661,17.939,17.939,0,0,1,3.34-1.362,40.474,40.474,0,0,0,2.394-4.7,14.677,14.677,0,0,1-.969-2.734,5.5,5.5,0,0,1-.1-2.4A2.313,2.313,0,0,1,12.64,5.756,2.5,2.5,0,0,1,14,5.594a1.568,1.568,0,0,1,1.075.771A2.489,2.489,0,0,1,15.358,7.5a6.327,6.327,0,0,1-.106,1.3,19.788,19.788,0,0,1-1.172,3.787,22.821,22.821,0,0,0,2.209,3.559,13.836,13.836,0,0,1,3.007.106,3.708,3.708,0,0,1,2.164.982,1.724,1.724,0,0,1,.451,1.094,2.332,2.332,0,0,1-.311,1.189,2.241,2.241,0,0,1-.8.878,2.029,2.029,0,0,1-1.15.291,3.947,3.947,0,0,1-2.1-.88A12.449,12.449,0,0,1,15.5,17.8a27.828,27.828,0,0,0-4.5.857,23.846,23.846,0,0,1-2.3,3.188A6.011,6.011,0,0,1,6.606,23.5,1.9,1.9,0,0,1,5.3,23.564Zm3.108-4.013c-.374.16-.721.329-1.035.5a4.338,4.338,0,0,0-1.458,1.155.655.655,0,0,0-.09.762.475.475,0,0,0,.059.093.627.627,0,0,0,.079-.025A4.51,4.51,0,0,0,7.393,20.83q.538-.617,1.014-1.279Zm3.7-2.808q1.129-.247,2.277-.407-.616-.882-1.15-1.811-.53,1.123-1.127,2.217Zm5.513.95a8.439,8.439,0,0,0,.981.866,2.309,2.309,0,0,0,1.123.54.255.255,0,0,0,.158-.032.654.654,0,0,0,.212-.264.878.878,0,0,0,.133-.422.194.194,0,0,0-.059-.133A2.131,2.131,0,0,0,19,17.807a9.314,9.314,0,0,0-1.379-.112Zm-4.486-7.4a13.4,13.4,0,0,0,.451-1.748,4.665,4.665,0,0,0,.086-.982,1.22,1.22,0,0,0-.072-.418,1.228,1.228,0,0,0-.327.084.721.721,0,0,0-.442.6,4.134,4.134,0,0,0,.1,1.735c.054.234.122.479.2.73Z"
                            transform="translate(0.78 7.685)"
                            fill="#c23d31"
                          />
                        </svg>
                        <span class="ml-1" [title]="msg?.document | chatMask"
                          >{{ msg?.document | chatMask }}
                        </span></span
                      >
                      <span>
                        <button
                          type="button"
                          class="btn btn-main"
                          (click)="downloadDoc(msg)"
                        >
                          <svg
                            id="download"
                            xmlns="http://www.w3.org/2000/svg"
                            width="20.384"
                            height="17.708"
                            viewBox="0 0 20.384 17.708"
                          >
                            <path
                              id="Path_39"
                              data-name="Path 39"
                              d="M.637,9.9a.637.637,0,0,1,.637.637v3.185A1.274,1.274,0,0,0,2.548,15H17.836a1.274,1.274,0,0,0,1.274-1.274V10.537a.637.637,0,0,1,1.274,0v3.185a2.548,2.548,0,0,1-2.548,2.548H2.548A2.548,2.548,0,0,1,0,13.722V10.537A.637.637,0,0,1,.637,9.9Z"
                              transform="translate(0 1.438)"
                              fill="#fff"
                            />
                            <path
                              id="Path_40"
                              data-name="Path 40"
                              d="M8.508,14.828a.637.637,0,0,0,.9,0l3.822-3.822a.638.638,0,0,0-.9-.9L9.6,12.839V1.637a.637.637,0,0,0-1.274,0v11.2L5.588,10.1a.638.638,0,1,0-.9.9Z"
                              transform="translate(1.233 -1)"
                              fill="#fff"
                            />
                          </svg>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </main>

            <form class="msger-inputarea">
              <input
                type="text"
                class="msger-input"
                #msgEl
                [(ngModel)]="msgInput"
                [ngModelOptions]="{ standalone: true }"
                placeholder=" {{ 'timeline.enteryourmessage' | translate }} ..."
              />
              <button
                [disabled]="!msgInput"
                type="submit"
                class="btn btn-main btn-red"
                (click)="sendMessageOrDoc('msg', msgEl)"
              >
                {{ "timeline.send" | translate }}
              </button>
            </form>
          </section>
        </div>
      </div>
    </div>
  </section>
</main>

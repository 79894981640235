import { FaqService } from './faq.service';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(private faqService: FaqService,
    private toastr: ToastrService,) { }

  faqList: any;

  ngOnInit(): void {
    this.getFaq();
  }

  getFaq() {
    this.faqService.getFaq().subscribe((data: any) => {
      if (data.status === 200) {
        this.faqList = data.body.faqRec;
      }
    }, err => {
      this.toastr.error(err.error.message, '');
    });
  }

}

import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-image-zoom',
  templateUrl: './image-zoom.component.html',
  styleUrls: ['./image-zoom.component.scss']
})
export class ImageZoomComponent implements OnInit {

  imgName: any;
  @Output() action = new EventEmitter();
  imgBaseUrl = environment.imgBaseUrl;

  constructor(public bsModalRef: BsModalRef,) { }

  ngOnInit(): void {
  }

  saveToList() {
    this.triggerEvent('');
    this.bsModalRef.hide();
  }

  triggerEvent(item: any) {
    this.action.emit({ data: item });
  }

}

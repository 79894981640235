<main class="page-wrapper">

    <section class="pt-5 pb-5">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h1 class="page-title text-center">{{'faq.heading1' | translate}} <span
                            class="titlebar mx-auto"></span></h1>
                </div>
                <div class="col-12 mt-5 faq-wrapper">
                    <accordion [isAnimated]="true">
                        <accordion-group *ngFor="let obj of faqList; index as i " heading="{{i+1}} . {{obj.question}}"
                            class="mb-lg-4 mb-3 panel-heading">
                            {{obj.answer}}
                        </accordion-group>

                    </accordion>
                </div>
            </div>
        </div>
    </section>
</main>
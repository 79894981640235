<li class="nav-item dropdown" [class.active-cart]="_router.url.startsWith('/cart-page')" *ngIf="_commonService?.isActiveProductlistView">
  <a class="nav-link white-color btn-items dropdown-toggle cart-drop"
  [ngStyle]="{background: _router.url.startsWith('/cart-page') ? 'transparent':'#213E62 0% 0% no-repeat padding-box'}" id="navbarDropdown" role="button"
    data-bs-toggle="dropdown" aria-expanded="false">
    {{ "header.cart" | translate }}
    <div class="cart-count" *ngIf="totalCartLength>0">
      {{ _commonService?.cartTotalLengthEvent$ | async }}
    </div>
    <!-- <div class="cart-count" *ngIf="cartLengthQty">
      {{ cartLengthQty }}
    </div> -->
  </a>

  <ul (click)="handleClick($event)" class="dropdown-menu cart-dropdown scrollable-menu"
    aria-labelledby="navbarDropdown">
    <h4 class="text-center page-title">
      {{ "header.cart" | translate }}
      <span class="titlebar mx-auto mt-2 mb-3"></span>
    </h4>
    <div class="cart-items-wrapper">
      <div class="cart-items" *ngFor="let data of wishlistdata; let firstElement = first">
        <h5 *ngIf="data?.products?.length > 0">
          {{ data.name }}
          <span class="titlebar"></span>
        </h5>
        <!-- <div *ngIf="data.length>0"> -->

        <!-- <h5 *ngIf="firstElement">{{ data?.TemplateType?.name }}</h5> -->
        <div *ngFor="let data of data?.products">
          <div class="cart-item-wrapper d-flex">
            <div class="cart-img">
              <img [src]="
                  data?.Product?.image
                    ? imgBaseUrl + data?.Product?.image.split(',')[0]
                    : 'assets/img/placeholder.png'
                " class="" height="64" width="64" alt="" />
            </div>
            <div class="cart-item-info">
              <h3>{{ data?.Product?.product_name }}</h3>
              <p>{{ data?.Product?.description }}</p>
              <div class="extra">
                <!-- <span *ngIf="data?.ProductFeature">{{
                data?.ProductFeature?.CatalogueFeature.label_name
              }}</span> -->
                <span *ngIf="data?.ProductFeature">
                  {{
                  (data?.ProductFeature?.CatalogueFeature?.label_name
                  ? data?.ProductFeature?.CatalogueFeature?.label_name
                  : data?.ProductFeature?.control_value
                  ) + (+data?.feature_price ? ('  (+'+formatPrice(data?.feature_price)+ ' €)'): '')}}

                  <!-- display only feature price  -->



                  <!-- ({{ data?.ProductFeature?.control_value }})
                <span
                  class="additional-price"
                  *ngIf="data?.ProductFeature?.price"
                  >+
                  {{
                    +data?.ProductFeature?.price | number : "1.2-2" : "de-DE"
                  }}
                  €</span
                > -->
                </span>
              </div>
            </div>
            <div class="price">
              <!-- <div class="price" *ngIf="!data?.Product?.price">
                Included in standard
              </div>

              <div *ngIf="data?.Product?.price">

                {{ data.product_price | number : "1.0-0" : "de-DE" }} €
              </div> -->


              <div
              class="price"
              *ngIf="!(+data?.product_price) && !data?.isSurfaceType"
            >
              {{ "configpage.includedinstandard" | translate }}
            </div>
            <div
                            class="price"
                            *ngIf="data?.isSurfaceType && _configuratorService?.isSurfaceProductIncludedInStandard(data)"
                          >
                            {{ "configpage.includedinstandard" | translate }}
                          </div>
              <div
                              class="price"
                              *ngIf="+data?.product_price && !data?.isSurfaceType"
                            >
                              <!-- {{ data?.product_price }}€ -->
                              {{
                                showProductPrice(data)
                                  | number : "1.0-0" : "de-DE"
                              }}
                              €
                            </div>

                            <div
                              class="price"
                              *ngIf="data?.isSurfaceType && !_configuratorService?.isSurfaceProductIncludedInStandard(data)"
                            >
                              <!-- {{ data?.product_price }}€ -->
                              {{
                                showProductPrice(data)
                                  | number : "1.0-0" : "de-DE"
                              }}
                              €
                            </div>


              <span *ngIf="!data?.quantity == 0">{{ "header.quantity" | translate }}: {{ data?.quantity }}</span>
            </div>
          </div>
          <h6 [class.hdisabled]="_configuratorService?.isProjectDateExpired" [class.project_completed]="reqIds?.is_project_completed">
            <a (click)="deleteProduct(data)">{{
              "header.removeitem" | translate
              }}</a>
            |
            <a (click)="openModal(data)">
              {{ "header.applyitemforothersroom" | translate }}</a>
          </h6>

          <!-- <div class="cart-item-wrapper d-flex">
                <div class="cart-img">
                    <img src="assets/img/b1.jpg" class="" height="64" width="64" alt="">
                </div>
                <div class="cart-item-info">
                    <h3>Herholt Esprit | W …</h3>
                    <p>Interior doors</p>
                    <div class="extra">
                        <span>Painted</span>
                    </div>
                </div>
                <div class="price">
                    1.200 €
                    <span>Quantity: 2</span>
                </div>

            </div>
            <h6><a>Remove item</a> | <a href="#" data-bs-toggle="modal" data-bs-target="#applyItemsDrop">Apply item for other rooms</a></h6> -->
          <!-- </div> -->
        </div>
      </div>
    </div>

    <div class="cart-items-wrapper d-flex justify-content-center align-content-center align-items-center"
      *ngIf="totalCartLength==0 && isConfigCompleted==false">
      {{ "header.noitem" | translate }}
    </div>
    <div class="cart-total">
      <p class="coast">
        <span class="coastname">
          {{ "header.costof" | translate }}
          <span>
            {{ _configuratorService?.roomName }}
          </span>
        </span>

        <span class="coast-amount">{{
          _configuratorService?.totalCostRoom | number : "1.0-0" : "de-DE"
          }}
          €</span>
      </p>
      <!-- <h3>Costs of bedrooms <span class="ps-2">1.200 €</span></h3> -->
      <p>
        <span class="tcoastname"> {{ "header.costallroom" | translate }}</span>
        <span class="tcoast-amount ps-2">{{ _configuratorService?.costodAllRooms
                            || 0 | number : "1.0-0" : "de-DE" }} €</span>
      </p>
      <button (click)="disable()" [routerLink]="['../cart-page']" type="button" class="btn btn-main w-100">
        {{ "header.gotocart" | translate }}
      </button>
    </div>
  </ul>
</li>
<li class="nav-item dropdown" [class.active-cart]="_router.url.startsWith('/cart-page')" *ngIf="!_commonService?.isActiveProductlistView">
  <a class="nav-link white-color btn-items cart-drop"
  [ngStyle]="{background: _router.url.startsWith('/cart-page') ? 'transparent':'#FFFFFF66 0% 0% no-repeat padding-box'}"
   role="button" aria-expanded="false" (click)="goToCart()">
    {{ "header.cart" | translate }}
    <!-- <div class="cart-count" *ngIf="!_commonService?.isConfigured">
      {{ 0 }}
    </div> -->
    <div class="cart-count" *ngIf="totalCartLength>0">
      {{ _commonService?.cartTotalLengthEvent$ | async }}
    </div>
  </a>
</li>
<!-- <span style="color: white">{{ _commonService?.isActiveProductlistView }}</span> -->
<!-- Apply Items Modal -->
<div class="modal fade {{ modalclass }}" id="applyItemsDrop" data-bs-backdrop="static" data-bs-keyboard="false"
  tabindex="-1" aria-labelledby="applyItems" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
        (click)="closeModal()"></button>

      <div class="modal-body">
        <h3 class="text-center">
          {{ "header.applyitem" | translate }}
          <span class="titlebar mx-auto d-block"></span>
        </h3>
        <p class="body-text text-center m-0">
          {{ "header.pleaseselecttheroomyouwnat" | translate }}
        </p>
        <h4>{{ currentSelectedModalProduct?.Product?.product_name }}</h4>
        <a class="selectall" (click)="selectAllFeatureToggle()">{{
          "header.selectall" | translate
          }}</a>
        <form>
          <div class="doc-form">
            <div class="input-group" *ngFor="let data of templates;let i=index">
              <input type="checkbox"
                *ngIf="(data?.checkProductforRoom?.length==0) || (data?.checkProductforRoom?.length==0 && data?.product?.length == 0)"
                class="select-input" [checked]="((data?.checked || data?.id === temptypeId ||
              data?.product?.length > 0) || (data?.checkProductforRoom?.length==0 && data?.product?.length == 0))&& data?.isCatExists"
                [disabled]="
              (data?.id === temptypeId || data?.product?.length > 0 || data?.checkProductforRoom?.length ==0) || !data?.isCatExists
            " (click)="toggleCheckbox(data)" />

              <!-- " [disabled]="
            data.id === temptypeId || data?.product?.length > 0 || data.checkProductforRoom.length ==0
          "  -->

              <input type="checkbox" class="select-input"
                *ngIf="!data?.checkProductforRoom?.length==0 && !(data?.checkProductforRoom?.length==0 && data?.product?.length == 0)"
                [checked]="
            (data?.checked ||
            data?.id === temptypeId ||
            data?.product?.length > 0) && (data?.isCatExists)
          " [disabled]="
            (data?.id === temptypeId || data?.isMaxLimitReached || data?.product?.length > 0) || !data?.isCatExists
          " (click)="toggleCheckbox(data)" />

              <span class="checkbox">
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8.5" viewBox="0 0 11 8.5">
                  <path id="check-lg_white" data-name="check-lg white"
                    d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                    transform="translate(-3.001 -3.75)" fill="#fff" />
                </svg>
              </span>
              <div class="chekedoverlay"></div>
              <span>{{ data.name }}</span>
              <p class="alreadytext" *ngIf="((data?.id === temptypeId || data?.product?.length > 0)
                  || (data?.checkProductforRoom?.length==0 && data?.product?.length == 0))||((!data?.isCatExists))||data?.isMaxLimitReached ">
                <span  *ngIf="(data?.id === temptypeId || data?.product?.length > 0)
                  || (data?.checkProductforRoom?.length==0 && data?.product?.length == 0)"> {{"shoppingcart.itemhasalreadybeenset" | translate}}</span>
                  <span  *ngIf="(!data?.isCatExists)"> {{"shoppingcart.catNotAdded" | translate}}</span>
                  <span  *ngIf="data?.isMaxLimitReached">{{"shoppingcart.maxlimitreached" | translate}}</span>
              </p>

                <!-- <p class="alreadytext" *ngIf=" data.checkProductforRoom ==0"
                [checked]="!data.checked || data.checkProductforRoom==0">
                {{"shoppingcart.productnotavailableinthisroom" |
                translate}}
              </p> -->
            </div>

            <!-- <div class="input-group">
              <input type="checkbox" class="disabled" disabled />
              <div class="chekedoverlay"></div>
              <span class="checkbox">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="8.5"
                  viewBox="0 0 11 8.5"
                >
                  <path
                    id="check-lg_white"
                    data-name="check-lg white"
                    d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                    transform="translate(-3.001 -3.75)"
                    fill="#fff"
                  />
                </svg>
              </span>

              <span>Bedroom</span>
              <p class="alreadytext">Item has already been set.</p>
            </div>

            <div class="input-group">
              <input type="checkbox" />
              <span class="checkbox">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="8.5"
                  viewBox="0 0 11 8.5"
                >
                  <path
                    id="check-lg_white"
                    data-name="check-lg white"
                    d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                    transform="translate(-3.001 -3.75)"
                    fill="#fff"
                  />
                </svg>
              </span>
              <div class="chekedoverlay"></div>
              <span>Living room</span>
            </div>

            <div class="input-group">
              <input type="checkbox" />
              <span class="checkbox">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="8.5"
                  viewBox="0 0 11 8.5"
                >
                  <path
                    id="check-lg_white"
                    data-name="check-lg white"
                    d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                    transform="translate(-3.001 -3.75)"
                    fill="#fff"
                  />
                </svg>
              </span>
              <div class="chekedoverlay"></div>
              <span>Nursery 1</span>
            </div>

            <div class="input-group">
              <input type="checkbox" />
              <span class="checkbox">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="8.5"
                  viewBox="0 0 11 8.5"
                >
                  <path
                    id="check-lg_white"
                    data-name="check-lg white"
                    d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                    transform="translate(-3.001 -3.75)"
                    fill="#fff"
                  />
                </svg>
              </span>
              <div class="chekedoverlay"></div>
              <span>Nursery 2</span>
            </div>

            <div class="input-group">
              <input type="checkbox" />
              <span class="checkbox">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="8.5"
                  viewBox="0 0 11 8.5"
                >
                  <path
                    id="check-lg_white"
                    data-name="check-lg white"
                    d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                    transform="translate(-3.001 -3.75)"
                    fill="#fff"
                  />
                </svg>
              </span>
              <div class="chekedoverlay"></div>
              <span>Hallway</span>
            </div>

            <div class="input-group">
              <input type="checkbox" />
              <span class="checkbox">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="8.5"
                  viewBox="0 0 11 8.5"
                >
                  <path
                    id="check-lg_white"
                    data-name="check-lg white"
                    d="M12.736,3.97a.733.733,0,0,1,1.047,0,.755.755,0,0,1,.01,1.05L7.88,12.01a.733.733,0,0,1-1.065.02l-3.6-3.646a.757.757,0,0,1,0-1.06.733.733,0,0,1,1.047,0l3.052,3.093,5.4-6.425.02-.022Z"
                    transform="translate(-3.001 -3.75)"
                    fill="#fff"
                  />
                </svg>
              </span>
              <div class="chekedoverlay"></div>
              <span>Bathroom</span>
            </div> -->
          </div>
          <div class="button-row d-flex align-items-center justify-content-between">
            <button type="button" class="btn btn-main btn-white" data-bs-dismiss="modal" aria-label="Close"
              (click)="closeModal()">
              {{ "header.cancel" | translate }}
            </button>

            <button type="button" class="btn btn-main" (click)="selectedApply()">
              {{ "header.apply" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
import { AdminLoginLoaderPageService } from './admin-login-loader-page.service';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from '@angular/router';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'src/app/shared/storage/local-storage.service';


@Component({
  selector: 'app-admin-login-loader-page',
  templateUrl: './admin-login-loader-page.component.html',
  styleUrls: ['./admin-login-loader-page.component.scss']
})
export class AdminLoginLoaderPageComponent implements OnInit {

  key: any;
  adminId: any;
  clientId: any;

  constructor(private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private service: AdminLoginLoaderPageService,
    private router: Router,
    private toastr: ToastrService,
    private storageService: LocalStorageService) { }

  ngOnInit(): void {
    this.spinner.show();

    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.spinner.hide();
    }, 100000);

    this.route.queryParams.subscribe(params => {
      this.key = decodeURIComponent(params['key']);
    });
    this.doLogin();
  }


  public doLogin(): void {

    let payload = {
      encode_id: this.key
    };

    this.service.validateKey(payload).subscribe((data: any) => {
      if (data.status === 200) {
        this.storageService.setItem('token', data.body.user.token, false);
        this.storageService.setItem('adminSpecialLogin', 'true', false)

        this.adminId = data.body.admin_id;
        this.storageService.setItem('adminId', this.adminId, false);

        this.clientId = data.body.user.id;
        this.storageService.setItem('clientId', this.clientId, false);

        this.storageService.setItem('currentUser', JSON.stringify(data.body.user), false);

        // setTimeout(() => {
        // 	this.toastr.success('Logged in successfully.', '');
        // }, 1000);
        // this.spinner.hide();
        this.router.navigate([APP_ROUTES.dashboardHome]).then(() => {
        });
      }
      else if (data.status === 400) {
        this.toastr.error('Your account is in blocked state, contact your administrator', 'Account Blocked');
      }
      else {
        this.toastr.error(data.body.message, '');
      }
    }, err => {
      console.log('error', err)
      if (err.status === 400) {
        this.toastr.error('Your account is in blocked state, contact your administrator', 'Account Blocked');
      }
      else if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        this.toastr.error('Something went wrong. Please try again later', '');
      }
    });
  }

}

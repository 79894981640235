import { CommonService } from './../../service/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { constants } from 'src/app/shared/helpers/common/global-constants';
import { ConfiguratorService } from 'src/app/components/post-auth-pages/configurator/configurator.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { WishlistService } from 'src/app/components/post-auth-pages/wishlist/wishlist.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cart-dropdown',
  templateUrl: './cart-dropdown.component.html',
  styleUrls: ['./cart-dropdown.component.scss'],
})
export class CartDropdownComponent implements OnInit {
  imgBaseUrl: string = '';
  wishListProducts: any[] = [];
  data: any = {};
  private constants = constants;
  wishlistdata: any;
  reqIds: any = {};
  product_id: any;
  templateTypeId: any;
  activeProject: any = null;
  temptypeId: number;
  templates: any;
  checkedTemplate: boolean = false;
  form: FormGroup = this.fb.group({
    categories: this.fb.array([]),
  });
  roomsList: any[] = [];
  currentRoom: any;
  isConfigured:any = false

  totalCartLength: number = 0;
  currentSelectedModalProduct: any;
  cartLengthQty: number = 0;
  currentDate = new Date();
  constructor(
    private toastr: ToastrService,
    private service: WishlistService,
    public _commonService: CommonService,
    public _configuratorService: ConfiguratorService,
    private fb: FormBuilder,
    public _router: Router,
    private _translateService: TranslateService,
    private modalService: NgbModal,
    @Inject(DOCUMENT) private _document: HTMLDocument
  ) {
    this.imgBaseUrl = this._commonService.imgBaseUrl;
    this.fetchRequiredIDForGetRooms();
    this._commonService?.cartTotalLengthEvent$.subscribe((res) => {
      this.totalCartLength = res || 0;
    });
  }
  // formatPrice(price: any): any {
  //   console.log(price,'---price----');
    
  //   return parseFloat((price ?? 0).toFixed(2));
  // }
  formatPrice(price: any): any {
    // console.log(price, '---price----');
    
    const numericPrice = typeof price === 'number' ? price : 0;
    return parseFloat(numericPrice.toFixed(2));
  }
  goToCart() {
    if (this.totalCartLength > 0 ||this.totalCartLength==0) {
      this._router.navigate(['/cart-page']);
    } else {
      if(localStorage?.ispConf && JSON.parse(localStorage.getItem('ispConf'))){
        this.fetchRequiredIDForGetRooms();
        let queryParams = {
          pId: this.reqIds.pId,
          tId:this.reqIds.tId
        } 
        this._router.navigate(['/configurator'], {
          queryParams,
        });
        return;
      }
      if (!this._router.url.includes('home')) {
        this._router.navigate(['/home']).then(() => {
          setTimeout(() => {
            this.getSmoothScroll('project_list_scroll');
          }, 500);
        });
      }
      this.toastr.error(
        this._translateService.instant('header.configureproject')
      );
      this.getSmoothScroll('project_list_scroll');
    }
  }

  getSmoothScroll(id: string) {
    this._document.getElementById(id)?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  }
  
  isConfigCompleted:any = false
  ngOnInit(): void {
    // this.getWishlistItems();
    // console.log('hii this is cart -====================');
    if(localStorage.getItem('ispConf')){
      this.isConfigCompleted= JSON.parse(localStorage.getItem('isConf'))
    }
    this._configuratorService.showcartitems$.subscribe((data:any) => {
      let count = 0;
      // console.log('show 000');
      
      // this.wishlistdata.push(wishlist);
      let prodLength = 0;
      data.productWishlistRec?.length &&
        data.productWishlistRec?.forEach((el) => {
            prodLength += +el?.quantity || 0;
        });
        if(!prodLength){
          this._configuratorService.totalCostRoom = 0
      } 

      // this._commonService.cartTotalLengthEvent$.next(prodLength);
      // localStorage.setItem('configuredProjectListCount',JSON.stringify(+prodLength))
      this.fetchRequiredIDForGetRooms();
      this.getWishlistItems();
    });

    this._configuratorService.roomListObserver$.subscribe((roomlist: any) => {
      if (roomlist) {
        this.roomsList = roomlist;
        let selectedRoom: any[] = [];
        this.fetchRequiredIDForGetRooms();
        selectedRoom = this.roomsList.filter(
          (room) => room?.id === this.reqIds?.roomId
        );
        this.currentRoom = selectedRoom[0];
      }
    });

    this._configuratorService.activeRoomId$.subscribe((data) => {
      // console.log(data, 'data active');
      
      this.fetchRequiredIDForGetRooms();
      if(data !== null){
      this.wishlistdata = [];
      this.mappDataList(data);
      }
      // this.getWishlistItems();
    });
    // this.getWishlistItems();

    this._configuratorService.isCompletedProjectChanged$.subscribe((data) => {
      if(data?.tId){
        this.reqIds = data;
      }else{
        this.fetchRequiredIDForGetRooms();
      }
    });
  }

  getvariantpricee(data): number {
    return (
      (+data?.product_price > +data?.feature_price
        ? data?.product_price - +data?.feature_price
        : +data?.feature_price - +data?.product_price) || 0
    );
  }
  getvarientprce(product) {
    let price =
      +product?.templateAreaPrice ||
      +product?.runningMeterAreaPrice ||
      +product?.templateAreaWallPrice;
    return +price || 0;
  }

  handleClick(event: { stopPropagation: () => void }) {
    // if (this._commonService.hideCartDiv) {                  // hide first time click
    //   event.stopPropagation();
    // }
    // this._commonService.hideCartDiv = true;

    event.stopPropagation();
    return;
  }

  disable() {
    this._commonService.hideCartDiv = false;
    this._commonService.isHamburgerMenuHide$.next(true);
  }
  fetchRequiredIDForGetRooms(): void {
    if (localStorage.getItem(this.constants.requiredId)) {
      this.reqIds = JSON.parse(localStorage.getItem(this.constants.requiredId));
    }
  }

  minusQunatity(parentCat: any, product: any) {
    let value = +product.controls['quantity'].value;
    if (value > 0) {
      product.controls['quantity'].value + 1;
      value = +value - 1;
      product.controls['quantity'].patchValue(value);
      this.mapCategoryCurrentQuantity(parentCat, product.value);
    }
  }
  mapCategoryCurrentQuantity(
    parentCat: any,
    prod?: any,
    isAdded?: boolean
  ): void {
    parentCat.controls['cat_total_qty'].patchValue(
      this._configuratorService.getTotalSelectedQtyForSingleCategory(
        parentCat.value
      )
    );
    let categoryLimit: number = parentCat.controls['cat_qty'].value;
    let currentCatQty: number = parentCat.controls['cat_total_qty'].value;
    if (categoryLimit <= currentCatQty) {
      this._configuratorService.disabledCartOperation(parentCat, true);
    } else {
      this._configuratorService.disabledCartOperation(parentCat, false);
    }
  }

  deleteProduct(obj: any) {
    let payload = {
      id: obj.id,
    };

    this.service.deleteProduct(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this._configuratorService.isShowingPercentageProgressObserver$.next(true);
          this._configuratorService.ShowItemsOnOtherCartPages$.next(true);
          this.getWishlistItems();
          window.location.reload();
        }
      },
      (err) => {
        this.toastr.error(err.error.message, '');
      }
    );
  }

  toggleCheckbox(template: any) {
    if(!template?.isCatExists){
      template.checked = false
      // console.log('Product category is not added in room');
      return
    }else{
      for (let i = 0; i < this.templates.length; i++) {
        if (this.templates[i].id === template?.id) {
          if (this.templates[i].checked) {
            this.templates[i].checked = false;
          } else {
            this.templates[i].checked = true;
          }
        }
      }
    }
  }

  selectAllFeatureToggle() {
    this.checkedTemplate = !this.checkedTemplate;
    if (this.checkedTemplate) {
      for (let i = 0; i < this.templates.length; i++) {
        this.templates[i].checked = true;
      }
    } else {
      for (let i = 0; i < this.templates.length; i++) {
        this.templates[i].checked = false;
      }
    }
    // obj.isSelected = !obj.isSelected;
    // let selectedItems = this.templates.filter((x) => x?.isSelected).length;
    // let unSelectedItems = this.templates.filter((x) => !x?.isSelected).length;
    // if (this.templates?.length === selectedItems) {
    //   this.selectall = true;
    // }
    // if (this.featureList?.length >= unSelectedItems && unSelectedItems > 0) {
    //   this.selectall = false;
    // }
  }
  selectedApply() {
    // this._router.navigate(['/cart-page']);
    let allData = [];
    for (let i = 0; i < this.templates.length; i++) {
      if (this.templates[i].checked) {
        allData.push(this.templates[i].id);
      }
    }

    let wishlistData = {
      project_id: this.activeProject?.project?.id || this.reqIds?.pId,
      template_id: this.activeProject?.details?.template_id || this.reqIds?.tId,
      product_id: this.product_id,
      template_type_id: this.templateTypeId,
      template_type_ids: allData,
      // template_type_id: this.reqIds?.roomId,
    };

    this.service.additemsinromms(wishlistData).subscribe((data) => {
      if (data?.body?.status) {
        this.toastr.success(data?.body?.message);
        this.getWishlistItems();
        this.closeModal();
        this._configuratorService.applyItemSync$.next(true);
      } else {
        this.toastr.error(data?.body?.message);
      }
    });
  }
  fetchLocalData(): Object {
    if (localStorage.getItem(this.constants.requiredId)) {
      let ids = JSON.parse(localStorage.getItem(this.constants.requiredId));
      return ids;
    } else {
      return {};
    }
  }
  get isProjectDateExpired(): boolean {
    let ids: any = this.fetchLocalData();
    let projectDate = new Date(ids?.completed_date);
    return this.currentDate.getTime() > projectDate.getTime() ? true : false;
  }

  showProductPrice(obj: any): number {
    return this._configuratorService.showProductPriceForDropdown(obj);
  }
  getWishlistItems() {
    const wishlistData = {
      project_id: this.reqIds?.pId,
      template_id: this.reqIds?.tId,
      template_type_id: this.reqIds?.roomId,
      client_id:this._configuratorService.client_id || 0
    };
    if (this.reqIds?.roomId)
      this._configuratorService
        .getwishlistitembytempId(wishlistData)
        .subscribe((data: any) => {
          this.data = data;
          //previous
          this.wishlistdata = [];
          // this.wishlistdata = data.productWishlistRec;

          // this.wishlistdata.forEach((data) => {
          //   data.Product.image = this.imgBaseUrl + data.Product.image;
          // });
         this.mappDataList(data)

        });
        
  }


  mappDataList(data:any){
    // console.log(',,,,,,',data);
    
    let prodLength = 0;
          data.productWishlistRec?.length &&
            data.productWishlistRec?.forEach((el) => {
              el?.products?.forEach((prod) => {
                prod['isProductDisabled'] = this.isProjectDateExpired ? true : false;
            prod['isAddQuantityDisabled'] = this.isProjectDateExpired ? true : false;
            prod['isOutOfStock']= 
              prod?.type !== 'variant'
                ? +prod?.quantity <= 0
                  ? true
                  : false
                : false;
            prod['isSurfaceType']= prod?.type === 'variant' ? true : false;
              prodLength += +prod?.quantity || 0;
              var projectCompleted = JSON.parse(localStorage.getItem('projectCompleted'));
              // console.log(projectCompleted,'--proj completed -----');
              // console.log(el,'element-373');
              let rqId = JSON.parse(localStorage.getItem('reqId'))
              let projectDetails = JSON.parse(localStorage.getItem('projectDetails'))
              const isProjectCompleted = projectDetails.some(project => project.project_id === rqId.pId&& project.is_completed === 1);
              if (isProjectCompleted == true) {
                prodLength=0;
              }
              });
            });
            this.wishlistdata = data.productWishlistRec;
            // console.log(this.wishlistdata,'data,widhfh');
            
          if(!this._router.url.startsWith('/home')) {
          this._commonService.cartTotalLengthEvent$.next(prodLength);
          // console.log(+prodLength,'=======cart count');
          
          localStorage.setItem('configuredProjectListCount',JSON.stringify(+prodLength))
          }
          if (data.status === true) {
            this.wishListProducts = [];
            this.wishListProducts = data?.productWishlistRec;
            if (this.wishListProducts.length)
              this.wishListProducts = this.wishListProducts.filter(
                (prod: any) => +prod?.quantity > 0
              );

            this.wishListProducts.forEach((prod: any) => {
              this.cartLengthQty += +prod?.quantity || 0;
            });

            this._configuratorService.costodAllRooms =
              data?.costofAllRooms || 0;
                  // this._configuratorService.mapProductListFromWishList(
                  //   this.categories(),
                  //   this.wishListProducts
                  // );
          }
  }

  getTotalCostFromWhishlist(wishList: any): number {
    return (
      wishList?.length &&
      wishList?.reduce(
        (previous, current) => previous + +current?.product_price,
        0
      )
    );
  }

  categories(): FormArray {
    return this.form.get('categories') as FormArray;
  }
  modalclass: string = 'hidden-modal';
  openModal(data) {
    // console.log(data, 'data');
    
    this.modalclass = 'show';
    document.body.classList.add('modal-open');
    this.product_id = data.product_id;
    this.templateTypeId = data.TemplateType.id;
    this.currentSelectedModalProduct = data;
    const wishlistData = {
      project_id: this.activeProject?.project?.id || this.reqIds?.pId,
      template_id: this.activeProject?.details?.template_id || this.reqIds?.tId,
      product_id: data.product_id,
      // template_type_id: this.reqIds?.roomId,
    };
    this.temptypeId = data.TemplateType.id;

    this.service.showroomItems(wishlistData).subscribe((data: any) => {
      this.templates = data.body.productWishlistRec;
      for (let i = 0; i < this.templates.length; i++) {
        this.templates[i].checked = false;
        if(!this.templates[i].checkProductforRoom){
          this.templates[i].checkProductforRoom = [1];
        }
        // this.templates[i].isMaxLimitReached = this.templates[i]?.catList?.length && this.templates[i]?.catList[0]?.configuredQty == this.templates[i]?.catList[0]?.totalQty;
        let index = 0;
        let ids = [];
        if(this.templates[i]?.catList?.length > 0){
          ids = this.templates[i]?.catList?.map(item=> +item?.catid)
          this.templates[i].isCatExists = ids.includes(+this.currentSelectedModalProduct?.category_id);
          index = ids.findIndex((item=> item === +this.currentSelectedModalProduct?.category_id))
        }else{
          this.templates[i].isCatExists = false;
        }
        if(index >= 0){
          this.templates[i].isMaxLimitReached = this.templates[i]?.catList?.length && this.templates[i]?.catList[index]?.added_cat == this.templates[i]?.catList[index]?.total_cat;
        }else{
          this.templates[i].isMaxLimitReached = false;
        }
      }
    });
  }
  closeModal() {
    this.modalclass = 'hidden-modal';
    document.body.classList.remove('modal-open');
  }
}

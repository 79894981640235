import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-configuration-success',
  templateUrl: './configuration-success.component.html',
  styleUrls: ['./configuration-success.component.scss']
})
export class ConfigurationSuccessComponent implements OnInit {

  constructor(private modalService:NgbModal, private CommonService: CommonService) { }

  ngOnInit(): void {}
  completeSuccessHome(){
    this.modalService.dismissAll();
    this.getRefresh();
  }
  getRefresh() {
    window.location.reload()
  }
}

import { PreAuthPagesService } from './../../pre-auth-pages.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
  FormArray,
} from '@angular/forms';
import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  public APP_ROUTES = APP_ROUTES;
  public ChangePasswordForm: FormGroup;
  public isSubmitting: boolean = false;
  public isDisabled: boolean = false;
  public formSubmitSuccess: boolean = false;
  resetToken: any;
  password_regex: any =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&_<>.,:;'"+-~#%^)()])(?=.*[a-zA-Z]).{8,}$/;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private fb: FormBuilder,
    private service: PreAuthPagesService
  ) { }

  ngOnInit(): void {
    this.resetToken = this.route.snapshot.queryParamMap.get('token');
    this.checkResetToken(this.resetToken);
    this.initForm();
  }

  checkResetToken(resetToken: any) {
    this.service.checkResetToken(resetToken).subscribe(
      (response: any) => {
        if (response.status === 200) {
        }
      },
      (err) => {
        this.toastr.error(err.error.message, '');
        this.router.navigate([APP_ROUTES.login]).then(() => {
          // for some async task
        });
      }
    );
  }

  private initForm(): void {
    this.ChangePasswordForm = this.fb.group({
      newPassword: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          this.passwordMatcher.bind(this),
          Validators.pattern(this.password_regex),
        ],
      ],
      confirmNewPassword: [
        '',
        [Validators.required, this.passwordMatcher.bind(this)],
      ],
    });
  }

  checkIfMatchingPasswords(
    passwordKey: string,
    passwordConfirmationKey: string
  ) {
    return (group: FormGroup) => {
      let passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      }
      // else {
      //     return passwordConfirmationInput.setErrors(null);
      // }
    };
  }

  private passwordMatcher(control: FormControl): { [s: string]: boolean } {
    if (
      this.ChangePasswordForm &&
      control.value !== this.ChangePasswordForm.controls.newPassword.value
    ) {
      return { passwordNotMatch: true };
    }
    return null;
  }

  change(e: number): void {
    this.formSubmitSuccess = false;
    if (this.ChangePasswordForm.invalid) {
      this.isSubmitting = true;
      return;
    }
    this.isSubmitting = false;
    let payload = {
      token: this.resetToken,
      password: this.ChangePasswordForm.value.newPassword,
      confirmation_password: this.ChangePasswordForm.value.confirmNewPassword,
    };
    this.service.changePassword(payload).subscribe(
      (data: any) => {
        if (data.status === 200) {
          this.formSubmitSuccess = true;
          this.ChangePasswordForm.reset();
          setTimeout(() => {
            this.toastr.success(data.body.message, '');
          }, 1000);
          this.router.navigate([APP_ROUTES.login]).then(() => { });
        }
      },
      (err) => {
        this.toastr.error(err.error.message, '');
      }
    );
  }

  getAllErrors(form: FormGroup | FormArray): { [key: string]: any } | null {
    let hasError = false;
    const result = Object.keys(form.controls).reduce((acc, key) => {
      const control = form.get(key);
      const errors =
        control instanceof FormGroup || control instanceof FormArray
          ? this.getAllErrors(control)
          : control.errors;
      if (errors) {
        acc[key] = errors;
        hasError = true;
      }
      return acc;
    }, {} as { [key: string]: any });
    return hasError ? result : null;
  }
}

<!-- <div class="login-box">
	<div class="login-logo">
		<a href="../../index2.html"><b>Admin </b>Forgot Password</a>
	  </div>
	<div class="card">
	  <div class="card-body login-card-body">
		<p class="login-box-msg">You forgot your password? Here you can easily retrieve a new password.</p>
  
		<form novalidate [formGroup]="forgotForm">
		  <div class="input-group mb-3">
			<input type="email" class="form-control" placeholder="Enter Email"
			formControlName="email" autocomplete="off">

			<div class="col-md-12">
				<ng-container
					*ngIf="forgotForm.controls['email'].errors && (forgotForm.get('email').dirty || forgotForm.get('email').touched || isSubmitting)">
					<small class="text-danger error-msg"
						[hidden]="!forgotForm.controls['email'].errors.required">*Email is
						required.</small>
					<small class="text-danger error-msg"
						[hidden]="!forgotForm.controls['email'].errors.pattern">*Email is
						invalid.</small>
				</ng-container>
			</div>

			<div class="input-group-append">
			  <div class="input-group-text">
				<span class="fas fa-envelope"></span>
			  </div>
			</div>
		  </div>
		  <div class="row">
			<div class="col-12">
			  <button type="submit" class="btn btn-primary btn-block" (click)="doForgot($event)">Request new password</button>
			</div>
		  </div>
		</form>
  
		<p class="mt-3 mb-1">
		  <a href="javascript:;" [routerLink]="['../' + APP_ROUTES.login]">Login</a>
		</p>
	  </div>
	</div>
  </div> -->



  <section class="log-in-bg d-flex align-items-center  justify-content-center">
    <div class="container pt-2 pb-2">
        <div class="row g-0  overflow-hidden d-flex  bg-white box-shadow login-wrapper">
            <div class="col-lg-6 text-center">
        <div class="login-img-wrapper">
          <img src="/assets/img/frgt.svg" class="img-fluid" />
        </div>
      </div>
            <div class="col-lg-6 white-bg-color  ">
                <div class="form-signin">
                    <form novalidate [formGroup]="forgotForm">
                        <h1 class="page-title">{{'forgot-password.heading1' | translate}} <span class="titlebar mb-3"></span></h1>
                        <p class="body-text">{{'forgot-password.heading3' | translate}}</p>


                        <div class=" mb-3 w-100">
                            <div class="form-floating border-bottom">
								<!-- <img src="assets/img/mail.png" alt="icon" class="input-icon"> -->
								<i class="bi bi-envelope icons-style-input icon-color-input fs-5"></i>
                                <input type="email" class="form-control ps-1 pe-4" id="floatingPassword" placeholder="{{'forgot-password.emailPlaceholder' | translate}}" formControlName="email" autocomplete="off">
                                <label for="floatingPassword" class="ps-0">{{'login.email' | translate}}</label>

                            </div>
                            <ng-container *ngIf="forgotForm.controls['email'].errors && (forgotForm.get('email').dirty || forgotForm.get('email').touched || isSubmitting)">
                                <small class="text-danger error-msg" [hidden]="!forgotForm.controls['email'].errors.required">{{'forgot-password.emailRequired' | translate}}</small>
                                <small class="text-danger error-msg" [hidden]="!forgotForm.controls['email'].errors.pattern">{{'forgot-password.emailInvalid' | translate}}</small>
                            </ng-container>
                        </div>
						<div class="row mb-4 forgot-row">
                            <div class="col-md-12 col-12 ">
								<button class="btn btn-main" type="submit" (click)="doForgot($event)">{{'forgot-password.submit' | translate}}</button>
                            </div>
                            <div class="col-12 col-md-12 text-left mt-4">
                                <a href="javascript:;" style="text-decoration: none;" [routerLink]="['../' + APP_ROUTES.login]" class="forgot-text"> <img src="assets/img/back.png" alt="icon">{{'forgot-password.backtologin'|translate}}</a>
                            </div>
                        </div>
                        
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>